import React from "react";
import { Tooltip, Avatar, Tag } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";

export default ({ name, image, code, quantity, boxNo }) => {

  const { s3host } = useSelector(state => state.general);

  return (
    <Container placement="top" title={name}>
      <div className="img"><Avatar size={30} src={`${s3host}/${image}`} /></div>
      <a className="name" href>{code} {boxNo && <Tag style={{ padding: 0, lineHeight: "14px" }}>(BoxNo: { boxNo })</Tag>}</a>
      <div className="qty">x {quantity}</div>
    </Container>
  );
};

const Container = styled(Tooltip)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 2px;
  float: left;
  margin-right: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
  .img {
    margin-right: 5px;
  }
  .name {
    margin-right: 5px;
  }
  .qty {
    font-weight: 500;
    padding-right: 5px;
  }
`;