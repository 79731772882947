import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined, CalendarOutlined, SaveOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Space, Input, Select, Tag, Modal, Form } from "antd";
import { CustomTable, RowAction, DrawerRangePicker } from "../../components";
import { useHistory } from "react-router-dom";
import { inout, inout as inoutApi } from "../../apis";
import { tugrug, formItemLayout, tailFormItemLayout } from "../../utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

const { RangePicker } = DatePicker;

const { Option } = Select;

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const [action, setAction] = React.useState();
  const { archives, isMobile } = useSelector(state => state.general);
  const { user } = useSelector(state => state.auth);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    code: "",
    type: "SUPPLIER"
  });

  const onAction = async (type, item) => {
    switch (type) {
      case "confirm": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Та баталгаажуулахдаа итгэлтэй байна уу!",
          onOk   : async () => {
            await inout.confirm(item.request_id);
            await myTableRef.current.reload();
          }
        });
        break;
      }
      case "cancel": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Та цуцлахдаа итгэлтэй байна уу!",
          okType : "danger",
          onOk   : async () => {
            await inout.cancel(item.request_id);
            await myTableRef.current.reload();
          }
        });
        break;
      }
      case "detail": {
        // history.push(`/archive/inout/detail/${item.id}`);
        history.push(`/product/income/detail/${item.id}`);
        break;
      }
      default:
    }
  };

  const requestStatus = (item) => {
    switch (item.request.request_status) {
      case "CONFIRMED": {
        return <Tag color='green'>Баталгаажсан</Tag>;
      }
      case "CANCELED": {
        return <Tag color='red'>Цуцлагдсан</Tag>;
      }
      default:
        return <Tag>Хүлээгдэж байна</Tag>;
    }
  };

  const checkConfirm = (item) => {
    if (!(item?.request?.request_status === "NEW"))
      return false;

    if (user.role === "ADMIN")
      return true;

    if (item?.request?.receiver_user_id === user.id && item?.request?.request_status === "NEW")
      return true;

    return false;
  };

  const checkCancel = (item) => {
    if (!(item?.request?.request_status === "NEW"))
      return false;

    if (user.role === "ADMIN")
      return true;

    if (item?.request?.sender_user_id === user.id && item?.request?.request_status === "NEW")
      return true;

    return false;
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onCancel = () => {
    setAction();
  };

  const onClear = () => {
    setFilters({ code: "", type: "" });
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.code !== query)
      timeout = setTimeout(() => {
        setFilters({
          ...filters,
          code: query
        });
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Орлогын жагсаалт"
        extra={[
          <Button type="primary" shape={isMobile && "circle"} icon={<PlusOutlined />} onClick={() => history.push("/product/income/new")}>{!isMobile && "Орлогодох"}</Button>
        ]}
      />

      <PageFilter>
        <Space className="wrap">
          <Space className="item">
            <Input className="item" name="code" placeholder="Дугаараар хайх ..." style={{ minWidth: 155 }} prefix={<SearchOutlined />} onChange={e => setQuery(e.target.value)} />
          </Space>
          <Space className="item">
            {user.role === "ADMIN" && (
              <Select className="item" value={filters.archive_id} placeholder="Агуулах" style={{ minWidth: 140 }} onChange={value => setFilters({ ...filters, archive_id: value })} allowClear>
                {archives.map((item, index) => {
                  return <Option key={index} value={item.id}>{item.name}</Option>;
                })}
              </Select>
            )}
          </Space>
        </Space>
        <Space className="margin-top">
          {isMobile ? (
            <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
          ) : (
            <RangePicker className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear={false} />)}
          <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
        </Space>

      </PageFilter>
      <PageContent>
        <InoutTable
          ref={myTableRef}
          filters={filters}
          loadData={inoutApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell">№</th>
                <th className="ant-table-cell">Дугаар</th>
                <th className="ant-table-cell">Тайлбар</th>
                <th className="ant-table-cell">Тоо ширхэг</th>
                <th className="ant-table-cell">Нийт дүн</th>
                <th className="ant-table-cell">Илгээсэн</th>
                <th className="ant-table-cell">Хүлээн авсан</th>
                <th className="ant-table-cell">Төлөв</th>
                <th className="ant-table-cell">Огноо</th>
                <th className="ant-table-cell" width={100}>Үйлдэл</th>
              </tr>
            </thead>
          )} tbody={(row, index) => (
            <tbody key={index} className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                <td className="ant-table-cell" rowSpan={2}><Tag color={row?.request?.type === "IN" ? "blue" : "red"}>{row.code}</Tag></td>
                <td className="ant-table-cell" rowSpan={2}>{row?.request?.note || "-"}</td>
                <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                <td className="ant-table-cell">{row?.supplier?.name || "-"}</td>
                <td className="ant-table-cell">{row?.to_archive?.name || "-"}</td>
                <td className="ant-table-cell text-center" rowSpan={2} width={100}>{requestStatus(row)}</td>
                <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.created_at).format("YYYY.MM.DD HH:mm")}</td>
                <td className="ant-table-cell" rowSpan={2}>
                  <RowAction
                    icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                    actions={{
                      confirm: checkConfirm(row) && "Баталгаажуулах",
                      cancel : checkCancel(row) && "Цуцлах",
                      detail : "Дэлгэрэнгүй"
                    }} onClick={(key) => onAction(key, row)} />
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell"><a>{`${row?.request?.sender_user?.first_name} ${row?.request?.sender_user?.last_name}`}</a></td>
                <td className="ant-table-cell"><a>{`${row?.request?.confirmed_user?.first_name || "-"} ${row?.request?.confirmed_user?.last_name || "-"}`}</a></td>
              </tr>
            </tbody>
          )} />
      </PageContent>

      {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}

      <Modal
        title={"Агуулах сонгох"}
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form {...formItemLayout}>
          <Form.Item label="Агуулах" required>
            <Select placeholder="Агуулах" onChange={value => history.push(`/archive/inout/${action[1]}/${value}`)} allowClear>
              {archives?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary">Болсон</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </Form.Item>
        </Form>
      </Modal>
    </PageContainer>
  );
};

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 

  @media only screen and (max-width: 682px) { 
    .wrap { 
      display: flex;
      flex-direction: column; 
    }
    .ant-space-item {
      width: 100%;
    }
    .item { 
      width: 100% !important; 
    }
  }
  @media only screen and (max-width: 420px) { 
    flex-direction: row; 
    flex-direction: column;  
    .margin-top {
      margin-top: 10px;
    }
    .item { 
      width: 100% !important; 
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
    } 
  }
  
  @media only screen and (max-width: 1162px) {  
    flex-direction: column;   
    .margin-top {
      margin-top: 8px;
    }
  }
`;