import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Avatar, Input, Form, Button } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MyTable } from "../../components";
import { tugrug } from "../../utils";
import { order as orderApi } from "../../apis";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

const { TextArea } = Input;
const FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default () => {
  const history = useHistory();
  const params = useParams();
  const myTableRef = React.useRef();
  const { s3host, isMobile } = useSelector(state => state.general);
  const [products, setProducts] = React.useState([]);
  const [data, setFormData] = React.useState({});

  const reload = React.useCallback(async (signal) => {
    let res = await orderApi.get(params.id, { signal });

    setFormData(res);
    setProducts(res.products);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Борлуулалтын дэлгэрэнгүй" extra={[
        <Button shape={isMobile && "circle"} type="default" onClick={() => history.push("/pos/sales")}>{!isMobile && "Болих"}</Button>,
      ]}/>
      <PageContent>
        {(() => {
          const columns = useHeader({
            history,
            s3host,
          });

          let QUANTITY = data?.products?.reduce((accumulator, iterator) => {
            return accumulator + iterator.quantity || 0;
          }, 0);

          let TOTAL_AMOUNT = data?.products?.reduce((accumulator, iterator) => {
            return accumulator + (iterator.quantity || 0) * (iterator.price || 0);
          }, 0);

          return (
            <Form layout="horizontal">
              <IncomeTable
                ref={myTableRef}
                items={products}
                columns={columns}
                pagination={false}
                rowKey={(record) => record.id} />

              {data.type === "ORDER" ? (
                <IncomeFooter {...FormItemLayout}>
                  <Form.Item label="Хүргэлт үнэ" required>
                    <Input type="text" value={tugrug(data.shipping_amount)} placeholder="Хүргэлт үнэ" />
                  </Form.Item>
                  <Form.Item label="Хүргэлтийн нэмэгдэл (Төлбөр)">
                    <Input type="text" value={tugrug(data.shipping_additional_fee)} placeholder="Хүргэлт нэмэгдэл" />
                  </Form.Item>
                  <Form.Item label="Хүргэлтийн огноо" required>
                    <Input type="text" value={moment(data.shipping_date).format("YYYY-MM-DD HH:mm")} placeholder="Хүргэлтийн огноо" />
                  </Form.Item>
                  <Form.Item label="Холбоо барих утас (1)" required>
                    <Input type="text" value={data.shipping_phone} placeholder="Утас 1" />
                  </Form.Item>
                  <Form.Item label="(2)">
                    <Input type="text" value={data.shipping_phone_second} placeholder="Утас 2" />
                  </Form.Item>
                  <Form.Item label="Хаяг байршил" required>
                    <TextArea type="text" value={data.shipping_address} placeholder="Хаяг байршил" />
                  </Form.Item>
                  <Form.Item label="Барааны тоо">
                    <Input type="text" value={QUANTITY} disabled />
                  </Form.Item>
                  <Form.Item label="Нийт дүн">
                    <Input type="text" value={tugrug(TOTAL_AMOUNT)} disabled />
                  </Form.Item>
                  <Form.Item label="Төлөх дүн">
                    <Input type="text" value={tugrug(TOTAL_AMOUNT + parseInt(data.shipping_amount, 10) + parseInt(data.shipping_additional_fee || 0, 10))} disabled />
                  </Form.Item>
                  <Form.Item label="Төлсөн дүн" required>
                    <Input type="text" value={tugrug(data.paid_amount)} placeholder="Төлсөн дүн" />
                  </Form.Item>
                  <Form.Item label="Нэмэлт мэдээлэл (Тайлбар)" required>
                    <TextArea type="text" value={data.note} placeholder="Нэмэлт мэдээлэл" />
                  </Form.Item>
                </IncomeFooter>
              ) : (
                <IncomeFooter {...FormItemLayout}>
                  <Form.Item label="Барааны тоо">
                    <Input type="text" value={QUANTITY} disabled />
                  </Form.Item>
                  <Form.Item label="Нийт дүн">
                    <Input type="text" value={tugrug(TOTAL_AMOUNT)} disabled />
                  </Form.Item>
                  <Form.Item label="Төлөх дүн">
                    <Input type="text" value={tugrug(TOTAL_AMOUNT + parseInt(data.shipping_amount, 10) + parseInt(data.shipping_additional_fee || 0, 10))} disabled />
                  </Form.Item>
                  <Form.Item label="Төлсөн дүн" required>
                    <Input type="text" value={tugrug(data.paid_amount)} placeholder="Төлсөн дүн" />
                  </Form.Item>
                  <Form.Item label="Нэмэлт мэдээлэл (Тайлбар)" required>
                    <TextArea type="text" value={data.note} placeholder="Нэмэлт мэдээлэл" />
                  </Form.Item>
                </IncomeFooter>
              )}
            </Form>
          );
        })()}
      </PageContent>
    </PageContainer>
  );
};
const IncomeFooter = styled(Form)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;
const IncomeTable = styled(MyTable)`
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
const useHeader = ({ s3host }) => {
  return [{
    title : "№",
    width : 10,
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title : "Нэр",
    render: record => {
      return record.name;
    }
  }, {
    title : "Ангилал",
    render: record => {
      return record?.category?.name || "-";
    }
  }, {
    title : "Дотоод код",
    render: record => {
      return <a>{record.code}</a>;
    }
  }, {
    title : "Баар код",
    render: record => {
      return record.barcode;
    }
  }, {
    title : "Үнэ",
    render: (record) => {
      return tugrug(record.price);
    }
  }, {
    title : "Тоо ширхэг",
    render: (record) => {
      return record.quantity;
    }
  }, {
    title : "Хайрцаг",
    render: (record) => {
      return (<div>{record.box_no}</div>);
    }
  }, {
    title : "Нийт дүн",
    render: (record) => {
      return tugrug(record.price * record.quantity);
    }
  }];
};

