import React from "react";
import { Modal, Row, Col } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./Form";
import { CategoryList } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { category } from "../../apis";
import styled from "styled-components";

export default () => {
  const dispatch = useDispatch();
  const { categoryTrees, categories } = useSelector(state => state.general);
  const [action, setAction] = React.useState(null);
  const [columns, setColumns] = React.useState([{
    parent_id: "rootElement",
    children : categoryTrees
  }]);
  const cascader = ({ cascaders, child }) => {
    if (child?.parent_id) {
      let parent = categories.find(c => c.id === child.parent_id);

      if (parent)
        return cascader({ cascaders: ["rootElement", parent, ...cascaders.filter(c => c !== "rootElement")], child: parent });

      return cascaders;
    }

    return cascaders;
  };

  const onNew = (parents = ["rootElement"]) => {
    setAction(["create", {
      parents: parents
    }]);
  };

  const onEdit = (data) => {
    let child = categories.find(c => c.id === data.parent_id);

    setAction(["update", {
      ...data,
      parents: cascader({ cascaders: ["rootElement", child], child })?.map(c => c?.id || c)
    }]);
  };

  const onRemove = async (item) => {
    await category.remove(item.id);
    await loadData();
  };

  const onSelect = (item, index) => {
    loadColumns(item, index);
  };

  const onSorted = () => {};
  const onCancel = () => {
    setAction(null);
  };

  const loadColumns = async (item, index) => {
    let array = columns.slice(0, index + 1);

    array[index] = { ...array[index], selected: item };

    if (item && item.children && item.children.length > 0) {
      let child = categories.find(c => c.id === item.id);

      array.push({
        parents  : cascader({ cascaders: ["rootElement", child], child })?.map(c => c?.id || c),
        parent_id: item.id,
        children : item.children
      });
    }

    setColumns(array);
  };

  const loadData = async (signal) => {
    let res = await category.list({ signal });

    dispatch({
      type   : "general.category",
      payload: res
    });
  };

  const init = React.useCallback(async (signal) => {
    loadData(signal);
  }, []);

  const onSubmit = async (data) => {
    let parentId = data.parents.pop();

    if (action && action[0] === "update") {
      await category.update({
        ...data,
        parent_id: parentId !== "rootElement" ? parentId : null,
        type     : "PRODUCT"
      });
    } else {
      await category.create({
        ...data,
        parent_id: parentId !== "rootElement" ? parentId : null,
        type     : "PRODUCT"
      });
    }

    onCancel();
    loadData();
  };

  React.useEffect(() => {
    setColumns([{
      parent  : "rootElement",
      children: categoryTrees
    }]);

    const abortController = new AbortController();
    const signal = abortController.signal;

    if (categoryTrees.length === 0)
      init(signal);

    return () => abortController.abort();
  }, [categoryTrees]);

  return (
    <PageContainer>
      <PageHeader title="Ангилал" />
      <PageContent>
        <Row style={{ overflowX: "auto", flexFlow: "row nowrap" }}>
          {columns.map((column, index) => (
            <Column key={index}>
              <CategoryList
                key={index}
                items={column.children}
                selected={column.selected}
                onNew={() => onNew(column.parents)}
                onEdit={onEdit}
                onRemove={item => onRemove(item, index)}
                onSelect={item => onSelect(item, index)}
                onSortEnd={({ oldIndex, newIndex }) => onSorted(oldIndex, newIndex, undefined)}
                useDragHandle
              />
            </Column>
          ))}
        </Row>

        <Modal
          title={action && action[0] === "create" ? "Ангилал нэмэх" : "Ангилал засах"}
          visible={!!action}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>
          <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />
        </Modal>
      </PageContent>
    </PageContainer>
  );
};

const Column = styled(Col) `
@media (min-width: 390px) {
  width: 100%;  
}
@media (min-width: 595px) {  
  width: 50%; 
}
@media (min-width: 860px) {  
  width: 33.333333%;   
}
@media (min-width: 1450px) {   
  width: 25%;  
} 
@media (min-width: 1600px) {   
  width: 20%;  
} 
`;