import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, message, Upload } from "antd";
import { Form, FormItem, Select } from "formik-antd";
import { formItemLayout } from "../../../utils";
import { useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  file         : Yup.string().required("Заавал бөглөнө!"),
  supplier_id  : Yup.string().required("Заавал бөглөнө!"),
  to_archive_id: Yup.string().required("Заавал бөглөнө!"),
});

export default () => {
  const history = useHistory();
  const { token } = useSelector(state => state.auth);
  const { suppliers, archives } = useSelector(state => state.general);
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState({
    supplier_id  : undefined,
    to_archive_id: undefined,
    file         : undefined,
  });

  const onChange = (info) => {

    switch (info.file.status) {
      case "uploading": {
        setLoading(true);
        break;
      }
      case "done": {
        setLoading(false);
        message.success("Таны файлыг амжилттай хууллаа.");
        history.push("/product/income");
        break;
      }
      case "error":{
        setLoading(false);
        message.error("Файл хуулхад алдаа гарлаа.");
        history.push("/product/income");
        break;
      }

      default:
    }

  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}>
      {({ values }) => (
        <Form {...formItemLayout}>
          <FormItem name="supplier_id" label="Нийлүүлэгч" required>
            <Select name="supplier_id" placeholder="Сонгох">
              {suppliers.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </FormItem>
          <FormItem name="to_archive_id" label="Агуулах" required>
            <Select name="to_archive_id" placeholder="Сонгох">
              {archives.filter(archive => archive.is_income === true).map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </FormItem>
          {values.to_archive_id !== undefined && values.to_archive_id !== undefined &&
          <FormItem name="file" label="Excel файл" required>
            <Upload
              key="import"
              name='file'
              listType='text'
              showUploadList={false}
              action={`/api/inout/import/file/${values.to_archive_id}/${values.supplier_id}`}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              onChange={onChange}>
              {!loading ? <Button UploadOutlined icon={<UploadOutlined />}>
                {"Файл хуулах"}
              </Button>:
                <Button loading={true} disabled={true} icon={<UploadOutlined />}>
                  Файл хуулж байна
                </Button>
              }
            </Upload>
          </FormItem>}
        </Form>
      )}
    </Formik>
  );
};