import request from "../utils/request";

export const init = (data) => request.get("/api/counting/init", data);
export const start = (id, data) => request.post(`/api/counting/${id}/start`, data);
export const finish = (id, data) => request.put(`/api/counting/${id}/finish`, data);
export const cancel = (id, data) => request.post(`/api/counting/${id}/cancel`, data);
export const product = (id) => (data) => request.get(`/api/counting/${id}/product`, data);
export const counted = (id, data) => request.put(`/api/counting/${id}/product`, data);
export const get = (id) => request.get(`/api/counting/${id}`);
export const list = (data) => request.get("/api/counting", data);
export const fileExport = (id) => request.get(`/api/counting/${id}/export/file`);
