import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { PlusOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Modal, Avatar, Input, Space, Tag } from "antd";
import { MyTable, RowAction } from "../../components";
import { useHistory, Link } from "react-router-dom";
import { product as productApi } from "../../apis";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import usePermission from "../../hooks/usePermission";

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { checkPermission } = usePermission();
  const { s3host, isMobile } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const columns = useHeader({
    checkPermission,
    history,
    s3host,
    onClick: (key, item) => {
      switch (key) {
        case "edit" : {
          history.push(`/product/item/edit/${item.id}`);
          break;
        }
        case "remove" : {
          Modal.confirm({
            title  : "Баталгаажуулах",
            icon   : <ExclamationCircleOutlined />,
            content: "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
            onOk   : async () => {
              try {
                await productApi.remove(item.id);
                myTableRef.current.reload();
              } catch (error) {
                console.log(error); }
            }
          });
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Барааны жагсаалт"
        extra={[
          checkPermission("PRODUCT") && <Link key={0} to="/product/item/new"><Button type="primary" shape={isMobile && "circle"} icon={<PlusOutlined />}>{!isMobile && "Бараа нэмэх"}</Button></Link>
        ]}
      />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ maxWidth: "250px", width: "100%" }}
            onChange={e => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={productApi.list}
          rowKey={(record) => record.id} />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ checkPermission, s3host, onClick, history }) => {
  return [{
    title : "№",
    width : 10,
    render: (record) => {
      return record.rowIndex;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title : "Нэр",
    render: record => {
      return record.name;
    }
  }, {
    title : "Ангилал",
    render: record => {
      return record?.category?.name || "-";
    }
  }, {
    title : "Дотоод код",
    render: record => {
      return <a onClick={() => history.push(`/product/item/edit/${record.id}`)}>{record.code}</a>;
    }
  }, {
    title : "Баар код",
    render: record => {
      return record.barcode || "-";
    }
  }, {
    title : "Үнэ",
    render: record => {
      return (
        <>
          {tugrug(record?.price)} <Tag>Б.Үнэ: {tugrug(record?.sale_price)}</Tag>
        </>
      );
    }
  }, {
    title : "Үлдэгдэл",
    sorter: true,
    key   : "quantity",
    render: record => {
      return record.quantity || 0;
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record) => {
      return (
        <RowAction
          actions={{
            edit  : checkPermission("PRODUCT") && "Засварлах",
            remove: checkPermission("PRODUCT") && "Устгах",
          }}
          onClick={(key) => onClick(key, record)}
        />
      );
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;