import React from "react";
import { DatePickerView } from "antd-mobile";
import { Space, Button, Drawer } from "antd";

export default ({ onCancel, onChange, filters }) => {
  const now = new Date();
  const [data, setFormData] = React.useState({
    start_date: filters.start_date || now,
    end_date  : filters.end_date || now
  });
  const [next, setNext] = React.useState(false);

  const onNext = () => {
    if (next === true) {
      onChange([data.start_date, data.end_date]);
    } else {
      setNext(true);
    }
  };

  const onValue = (date) => {
    if (next === false) {
      setFormData((state) => ({
        ...state,
        start_date: date
      }));
    } else {
      setFormData((state) => ({
        ...state,
        end_date: date
      }));
    }
  };

  return (
    <Drawer
      title={
        <Space style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
          <div>{!next ? "Эхлэх огноо" : "Дуусах огноо"}</div>
          <Button onClick={onNext}>{!next ? "Дараах" : "Болсон"}</Button>
        </Space>
      }
      placement="bottom"
      closable={false}
      onClose={onCancel}
      visible={true}
      height={355}>
      <DatePickerView
        showCaption={false}
        locale={{
          year  : " он",
          month : " сар",
          day   : " өдөр",
          hour  : " цаг",
          minute: " мин",
        }}
        value={!next ? data.start_date : data.end_date}
        onChange={onValue}
      />
    </Drawer>
  );
};