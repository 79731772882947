import React from "react";
import { ExclamationCircleOutlined, SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import { Space, Radio, Button, DatePicker, Tag, Select, Input } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { CustomTable, RowAction, DrawerRangePicker } from "../../components";
import { counting as countingApi } from "../../apis";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { tugrug } from "../../utils";
import styled from "styled-components";
import moment from "moment";

const { RangePicker } = DatePicker;

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const { isMobile, archives } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);
  const [filters, setFilters] = React.useState({
    type           : "",
    counting_status: "COUNTED"
  });
  const { Option } = Select;
  const onAction = (key, item) => {
    switch (key) {
      case "detail": {
        history.push(`/archive/report/${item?.id}`);
        break;
      }
      default:
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onCancel = () => {
    setAction([]);
  };

  const onClear = () => {
    setFilters({ code: "", type: "" });
  };

  return (
    <PageContainer>
      <PageHeader title="Тооллогын тайлан" />
      <PageFilter>
        <Space>
          <Select className="item" value={filters.archive_id} placeholder="Агуулах" style={{ width: 140 }} onChange={value => setFilters({ ...filters, archive_id: value })} allowClear>
            {archives?.map((item, index) => {
              return <Option key={index} value={item.id}>{item.name}</Option>;
            })}
          </Select>
        </Space>
        <Space className="margin-top wrap" >
          {isMobile ? (
            <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
          ) : (
            <RangePicker className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear={false} />)}
          <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <CalculationTable
          ref={myTableRef}
          filters={filters}
          loadData={countingApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" rowSpan={2}>Төрөл</th>
                <th className="ant-table-cell" rowSpan={2}>Ажилтан</th>
                <th className="ant-table-cell" colSpan={3}>Дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Нийт дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Тоолсон дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Дутсан дүн</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell">{row?.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    {(() => {
                      if (row?.type === "ARCHIVE")
                        return <Tag color='orange'>Агуулах</Tag>;

                      if (row?.type === "SECTION" && row?.section)
                        return <Tag color='blue'>{row?.section?.name}</Tag>;

                      return "-";
                    })()}
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>{`${row?.counting_user?.last_name || "-"} ${row?.counting_user?.first_name || "-"}`}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row ? tugrug(row?.stock_amount) : "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row ? tugrug(row?.total_amount) : "-"}</td>
                  <td className="ant-table-cell" style={{ color: "red" }} rowSpan={2}>{row ? tugrug(row?.debt_amount) : "-"}</td>
                  <td className="ant-table-cell" rowSpan={2} width={140}>{row ? moment(row?.counting_status_date).format("YYYY.MM.DD HH:mm") : "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        detail: "Дэлгэрэнгүй",
                      }} onClick={key => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.staff_user?.first_name || "-"} ${row?.staff_user?.last_name || "-"}`}</a></td>
                </tr>
              </tbody>
            );
          }} />

        {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}
      </PageContent>
    </PageContainer>
  );
};
const CalculationTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;
const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 
  flex-direction: row;  
  
  
  @media only screen and (max-width: 585px) {  
    flex-direction: column;   
    .ant-space-item {
      width: 100% !important;
    }
    .item {  
      width: 100% !important; 
    } 
    .margin-top {
      margin-top: 8px;
    }
  }
  @media only screen and (max-width: 446px) {  
    flex-direction: column;  
    .wrap {
      display: flex;
      flex-direction: column;
    } 
  }
`;
