import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { ExclamationCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { Button, DatePicker, Space, Radio, Select, Tag } from "antd";
import { CustomTable, RowAction, DrawerRangePicker } from "../../components";
import { useHistory } from "react-router-dom";
import { debt as debtApi } from "../../apis";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default () => {
  const myTableRef = React.useRef(null);
  const history = useHistory();
  const { archives, staffs, isMobile } = useSelector(state => state.general);
  const { user } = useSelector(state => state.auth);
  const [action, setAction] = React.useState();
  const [filters, setFilters] = React.useState({
    type       : "",
    debt_status: ""
  });

  const onAction = async (type, item) => {
    switch (type) {
      case "detail": {
        history.push(`/finance/debt/${item.id}`);
        break;
      }
      default:
    }
  };

  const debtStatus = (item) => {
    switch (item.debt_status) {
      case "NEW": {
        return <Tag color='red'>Төлөгдөөгүй</Tag>;
      }
      case "PAID": {
        return <Tag color='green'>Төлөгдсөн</Tag>;
      }
      default:
        return <Tag>Шинэ захиалга</Tag>;
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onClear = () => {
    setFilters({ code: "", type: "" });
    setAction();
  };

  const onCancel = () => {
    setAction();
  };
  let object_all = [];
  return (
    <PageContainer>
      <PageHeader title="Төлбөр тооцоо" />

      <PageFilter>
        <Space className="item wrap">
          <Space className="item">
            {isMobile ? (
              <Select className="item" value={filters.debt_status} style={{ width: 120 }} onChange={e => setFilters({ ...filters, debt_status: e })}>
                <Option className="item" value="">Бүгд</Option>
                <Option className="item" value="NEW">Төлөгдөөгүй</Option>
                <Option className="item" value="PAID">Төлөгдсөн</Option>
              </Select>
            ) : (
              <Radio.Group className="item" value={filters.debt_status} onChange={e => setFilters({ ...filters, debt_status: e.target.value })}>
                <Radio.Button className="item-child" value="">Бүгд</Radio.Button>
                <Radio.Button className="item-child" value="NEW">Төлөгдөөгүй</Radio.Button>
                <Radio.Button className="item-child" value="PAID">Төлөгдсөн</Radio.Button>
              </Radio.Group>
            )}
          </Space>
          <Space className="item wrap-top">
            {user.role === "ADMIN" && (
              <Select className="item" value={filters.archive_id} placeholder="Агуулах" style={{ width: 200 }} onChange={value => setFilters({ ...filters, archive_id: value })} allowClear>
                {archives.map((item, index) => {
                  return <Option className="item" key={index} value={item.id}>{item.name}</Option>;
                })}
              </Select>
            )}
            {user.role === "ADMIN" && (
              <Select className="item" value={filters.staff_id} placeholder="Ажилтан" style={{ width: 200 }} onChange={value => setFilters({ ...filters, staff_id: value })} allowClear>
                {staffs?.map((item, index) => {
                  return <Option className="item" key={index} value={item.id}>{`${item.last_name} ${item.first_name}`}</Option>;
                })}
              </Select>
            )}
          </Space>
        </Space>
        <Space className="margin-top">
          {isMobile ? (
            <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
          ) : (
            <RangePicker className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear={false} />
          )}
          <Button className="item" type="default" onClick={onClear}>Цэвэрлэх</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <InoutTable
          ref={myTableRef}
          filters={filters}
          loadData={debtApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                <th className="ant-table-cell" rowSpan={2}>Тайлбар</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" colSpan={3}>Өр төлбөр</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell">Нийт дүн</th>
                <th className="ant-table-cell">Төлөгдсөн</th>
                <th className="ant-table-cell">Үлдэгдэл</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {

            if (index == 0){
              object_all.splice(index);
            }

            object_all[index] = { total: parseFloat(row.total_amount),
              paid : parseFloat(row.paid_amount),
              ur   : parseFloat(row.total_amount - row.paid_amount) };

            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell" rowSpan={2}><Tag color="red">{row.code}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>{row?.note || "-"}</td>
                  <td className="ant-table-cell">{row?.archive?.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                  <td className="ant-table-cell" style={{ color: "green" }} rowSpan={2}>{tugrug(row.paid_amount)}</td>
                  <td className="ant-table-cell" style={{ color: "red" }} rowSpan={2}>{tugrug(row.pay_amount - row.paid_amount)}</td>
                  <td className="ant-table-cell text-center" rowSpan={2} width={100}>{debtStatus(row)}</td>
                  <td className="ant-table-cell" rowSpan={2} width={100}>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        detail: "Дэлгэрэнгүй"
                      }} onClick={(key) => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.staff_user?.first_name || "-"} ${row?.staff_user?.last_name || "-"}`}</a></td>
                </tr>
              </tbody>
            );
          }} />
      </PageContent>

      {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}
    </PageContainer>
  );
};
const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 

  @media only screen and (max-width: 545px) {  
    flex-direction: column;  
    .margin-top {
      margin-top: 8px;
    }
    .item { 
      width: 100% !important;
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
    } 
  }
  @media only screen and (max-width: 1508px) {  
    flex-direction: column;  
    .margin-top {
      margin-top: 8px;
    } 
  }
  @media only screen and (max-width: 1131px) {  
    .wrap { 
      flex-direction: column;  
    } 
    .ant-space-item {
      width: 100%;
    }
    .item {
      width: 100% !important;
    }
    .ant-space-align-center {
      width: 100% !important;
    }
    .ant-radio-button-wrapper { 
      min-width: max-content !important;  
      max-width: auto !important;  
    }
  }
`;