import request from "../utils/request";

export const dashboard = (data) => request.get("/api/order/dashboard", data);
export const purchase = (data) => request.post("/api/order/create", data);
export const addCart = (data) => request.post("/api/order/cart", data);
export const clearCart = (data) => request.del("/api/order/cart", data);
export const cart = (data) => request.get("/api/order/cart", data);
export const delivery = (id, data) => request.put(`/api/order/${id}/delivery`, data);
export const confirm = (id, data) => request.put(`/api/order/${id}/confirm`, data);
export const create = (data) => request.post("/api/order", data);
export const update = (id, data) => request.put(`/api/order/${id}`, data);
export const upset = (id, data) => request.put(`/api/order/${id}/upset`, data);
export const cancel = (id, data) => request.put(`/api/order/${id}/cancel`, data);
export const get = (id) => request.get(`/api/order/${id}`);
export const remove = (id) => request.del(`/api/order/${id}`);
export const list = (type) => (data) => request.get(`/api/order/${type}`, data);
export const drafts = () => request.get("/api/order/drafts");
export const shipment = (id) => request.get(`/api/order/${id}/shipment`);
export const fileExport = (id) => request.get(`/api/order/${id}/export/file`);

export const lists = (data) => request.get("/api/calculation/orders/list", data);