import React from "react";
import { Formik } from "formik";
import { Form, FormItem, Select as FormikSelect, Radio as FormikRadio, Input } from "formik-antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Button, Tag, Select, Form as AntForm } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { CustomTable, RowAction, DrawerRangePicker } from "../../components";
import { counting as countingApi } from "../../apis";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { tugrug } from "../../utils";
import { tailFormItemLayout, formItemLayout } from "../../utils";
import moment from "moment";
import * as Yup from "yup";

const FormSchema = () => Yup.object().shape({
  type      : Yup.string().required("Заавал бөглөнө!"),
  section_id: Yup.string().when("type", {
    is  : "SECTION",
    then: Yup.string().required("Заавал бөглөнө!"),
  }),
  box_no: Yup.string().when("type", {
    is  : "BOX_NO",
    then: Yup.string().required("Заавал бөглөнө!"),
  })
});

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const { sections } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);
  const [filters, setFilters] = React.useState({
    type      : "",
    section_id: undefined,
    box_no    : undefined
  });
  const { Option } = Select;
  const onAction = (key, item) => {
    switch (key) {
      case "start" : {
        setAction(["start", item]);
        break;
      }
      case "detail": {
        history.push(`/archive/counting/${item?.new?.id}`);
        break;
      }
      case "cancel": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Тооллогыг цуцлахдаа итгэлтэй байна уу?",
          onOk   : async () => {
            try {
              await countingApi.cancel(item?.new?.id);

              myTableRef.current.reload();
            } catch (error) {
              console.log(error);
            }
          }
        });
        break;
      }
      default:
    }
  };

  const onSubmit = async (data) => {
    try {
      let res = await countingApi.start(action[1]?.id, {
        type      : data.type,
        section_id: data.section_id,
        box_no    : data.box_no
      });
      history.push(`/archive/counting/${res.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const checkStart = (item) => {
    if (!item?.new)
      return "Тооллого хийх";

    return false;
  };

  const checkCancel = (item) => {
    if (item?.new?.counting_status === "NEW")
      return "Цуцлах";

    return false;
  };

  const checkDetail = (item) => {
    if (item?.new?.counting_status === "NEW")
      return "Дэлгэрэнгүй";

    return false;
  };

  const countingStatus = (item) => {
    switch (item?.new?.counting_status) {
      case "NEW":
        return <Tag color="red">Тооллого хийгдэж байна</Tag>;
      default:
        return <Tag>Хүлээгдэж байна</Tag>;
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onCancel = () => {
    setAction([]);
  };

  const onClear = () => {
    setFilters({ code: "", type: "" });
  };

  return (
    <PageContainer>
      <PageHeader title="Тооллого хийх" />
      <PageContent>
        <CalculationTable
          ref={myTableRef}
          filters={filters}
          loadData={countingApi.init}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" colSpan={6}>Тооллого хийсэн</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Төрөл</th>
                <th className="ant-table-cell" rowSpan={2}>Ажилтан</th>
                <th className="ant-table-cell" rowSpan={2}>Нийт дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Тоолсон дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Дутсан дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell">{row?.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    {(() => {
                      if (row?.counting?.type === "ARCHIVE")
                        return <Tag color='orange'>Агуулах</Tag>;

                      if (row?.counting?.type === "SECTION" && row?.counting?.section)
                        return <Tag color='blue'>{row?.counting?.section?.name}</Tag>;

                      return "-";
                    })()}
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>{`${row.counting?.counting_user?.last_name || "-"} ${row.counting?.counting_user?.first_name || "-"}`}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.counting ? tugrug(row.counting?.stock_amount) : "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.counting ? tugrug(row.counting?.total_amount) : "-"}</td>
                  <td className="ant-table-cell" style={{ color: "red" }} rowSpan={2}>{row.counting ? tugrug(row.counting?.debt_amount) : "-"}</td>
                  <td className="ant-table-cell" rowSpan={2} width={140}>{row.counting ? moment(row.counting?.counting_status_date).format("YYYY.MM.DD HH:mm") : "-"}</td>
                  <td className="ant-table-cell text-center" rowSpan={2} width={140}>{countingStatus(row)}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        start : checkStart(row),
                        detail: checkDetail(row),
                        cancel: checkCancel(row),
                      }} onClick={key => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.staff_user?.first_name || "-"} ${row?.staff_user?.last_name || "-"}`}</a></td>
                </tr>
              </tbody>
            );
          }} />

        {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}

        <Modal
          title="Тооллого хийх"
          visible={action && action[0] === "start"}
          onCancel={onCancel}
          destroyOnClose
          footer={false}>

          <Formik
            enableReinitialize
            initialValues={{
              type      : "ARCHIVE",
              section_id: undefined
            }}
            validationSchema={FormSchema}
            onSubmit={onSubmit}>
            {({ isSubmitting, values }) => {
              return (
                <Form {...formItemLayout}>
                  <Distribute>
                    <div className="alert">
                      <ExclamationCircleOutlined /> Та тооллого хийхдээ итгэлтэй байна уу?
                    </div>
                    <AntForm.Item {...tailFormItemLayout}>
                      <FormikRadio.Group name="type">
                        <FormikRadio value="ARCHIVE">
                          <span className="all">Бүгд</span>
                        </FormikRadio>
                        <FormikRadio value="BOX_NO">
                          <span className="all">
                            <FormItem name="box_no" required>
                              <Input type="text" name="box_no" placeholder="Хайрцаг дугаар" disabled={!(values.type === "BOX_NO")} />
                            </FormItem>
                          </span>
                        </FormikRadio>
                        <FormikRadio value="SECTION">
                          <span className="all">
                            <FormItem name="section_id" required>
                              <FormikSelect name="section_id" placeholder="Тасаг" disabled={!(values.type === "SECTION")} style={{ width: "200px" }} allowClear>
                                {sections.filter(section => section.archive_id === action[1]?.id)?.map((item, index) => {
                                  return <Option key={index} value={item.id}>{item.name}</Option>;
                                })}
                              </FormikSelect>
                            </FormItem>
                          </span>
                        </FormikRadio>
                      </FormikRadio.Group>
                    </AntForm.Item>
                  </Distribute>
                  <AntForm.Item {...tailFormItemLayout}>
                    <Button className="button" style={{ marginRight: 10 }} loading={isSubmitting} htmlType="submit" type="primary">Болсон</Button>
                    <Button className="button" type="default" onClick={onCancel}>Болих</Button>
                  </AntForm.Item>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </PageContent>
    </PageContainer>
  );
};
const CalculationTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;
const Distribute = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-radio-group {
    display: flex!important;
    flex-direction: column!important;
    .ant-radio-wrapper  {
      margin-bottom: 10px;
    }
  }
  .alert {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    svg {
      display: flex;
      font-size: 22px;
      color: #faad14;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
`;
