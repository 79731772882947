import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Form, FormItem, Input } from "formik-antd";
import { Button, message as notify, Avatar, Select, Form as AntForm, Space, Input as AntInput, Select as AntSelect, Radio as AntRadio, Upload, message } from "antd";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CustomTable, RowAction, FileUpload } from "../../components";
import { counting as countingApi } from "../../apis";
import styled from "styled-components";
import { tugrug } from "../../utils";
import { CloseOutlined, DownloadOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Report from "./Report";
import Detail from "./Detail";
import Init from "./Init";

export const CountingInit = Init;
export const CountingReport = Report;
export const CountingDetail = Detail;

const FormQuantitySchema = () => Yup.object().shape({
  section_id: Yup.string().optional("Заавал бөглөнө!"),
  quantity  : Yup.number().required("Заавал бөглөнө!")
});

const FormSchema = () => Yup.object().shape({
  note: Yup.string().required("Заавал бөглөнө!")
});

const { Option } = Select;
const { AntOption } = AntSelect;
const { TextArea } = Input;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      console.log(errors);

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const submitRef = React.useRef();
  const params = useParams();
  const [percent, setPercent] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { s3host, sections, isMobile } = useSelector(state => state.general);
  const { token } = useSelector(state => state.auth);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    archive_id             : undefined,
    counting_product_status: "",
    query                  : ""
  });
  const [item] = React.useState({
    id        : undefined,
    section_id: undefined,
    quantity  : undefined
  });
  const [data, setFormData] = React.useState({
    note: undefined
  });

  const onExport = async () => {
    try {
      setLoading(true);
      let res = await countingApi.fileExport(data.id);

      window.open(
        s3host + "/files/" + res.url,
        "_blank"
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const onSave = async () => {
    setLoading(true);
    let data = await submitRef.current.submitForm();

    if (data) {
      await countingApi.finish(data.id, {
        note: data.note,
      });

      notify.success("Таны хүсэлт амжилттай!");
      history.push("/archive/counting");
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    if (action && action[0] === "editable") {
      await countingApi.counted(action[1].id, {
        section_id: data.section_id,
        quantity  : data.quantity
      });

      setAction();
      myTableRef.current.reload();
    }
  };

  const reload = React.useCallback(async (signal) => {
    let res = await countingApi.get(params.id, { signal });

    setFilters(state => ({ ...state, archive_id: res.archive_id }));
    setFormData({
      ...res,
      note: res.note || undefined
    });
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Тооллого хийх" extra={[
        <Button type="default" shape={isMobile && "circle"} icon={isMobile && <CloseOutlined />} onClick={() => history.push("/archive/counting")}>{!isMobile && "Болих"}</Button>,
        <Button type="primary" shape={isMobile && "circle"} icon={isMobile && <SaveOutlined />} loading={loading} onClick={() => onSave()} htmlType="submit">{!isMobile && "Хадгалах"}</Button>
      ]} />
      <PageFilter>
        <Space className="wrap">
          <Space className="item">
            <AntInput className="item" name="code" placeholder="Хайлт хийх ..." style={{ minWidth: 155 }} prefix={<SearchOutlined />} onChange={e => setQuery(e.target.value)} />
            {isMobile ? (
              <AntSelect value={filters.counting_product_status} className="item" style={{ width: 120 }} onChange={value => setFilters({ ...filters, counting_product_status: value })}>
                <AntOption className="item" value="">Бүгд</AntOption>
                <AntOption className="item" value="COUNTED">Тоолсон</AntOption>
                <AntOption className="item" value="NEW">Тоолоогүй</AntOption>
              </AntSelect>
            ) : (
              <AntRadio.Group className="item" style={{ minWidth: "220px" }} value={filters.counting_product_status} onChange={e => setFilters({ ...filters, counting_product_status: e.target.value })}>
                <AntRadio.Button className="item" value="">Бүгд</AntRadio.Button>
                <AntRadio.Button className="item" value="COUNTED">Тоолсон</AntRadio.Button>
                <AntRadio.Button className="item" value="NEW">Тоолоогүй</AntRadio.Button>
              </AntRadio.Group>
            )}
          </Space>
          <Space className="item">
            <Select className="item" placeholder="Тасаг" style={{ width: 140 }} onChange={value => setFilters({ ...filters, section_id: value })} allowClear>
              {sections.filter(section => section.archive_id === filters.archive_id)?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Space>
        </Space>
        <Space className="margin-top">
          <Button type="default" shape={isMobile && "circle"} icon={<DownloadOutlined />} onClick={() => onExport()} htmlType="submit" loading={loading}>{!isMobile && "Excel файл"}</Button>
          <Upload
            name='file'
            listType='text'
            showUploadList={false}
            action={`/api/counting/${data.id}/import/file`}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            onChange={(info) => {
              setPercent(info?.file?.percent);

              if (info?.file?.percent === 100 && percent !== 100) {
                setTimeout(() => {
                  setFilters({ ...filters, counting_product_status: "COUNTED" }, () => {
                    myTableRef.current.reload();
                  });
                }, 1000);

                message.success("Файлыг амжилттай хуулж дууслаа!");
              }
            }}>
            <Button type="primary" shape={isMobile && "circle"} icon={<UploadOutlined />} htmlType="submit" loading={loading}>{!isMobile && "Excel файл"}</Button>
          </Upload>
        </Space>
      </PageFilter>
      <PageContent>
        <Formik
          enableReinitialize
          initialValues={item}
          validationSchema={FormQuantitySchema}
          onSubmit={onSubmit}>
          {({ isSubmitting, setFieldValue }) => {
            const onAction = (key, item) => {
              switch (key) {
                case "edit": {
                  setAction(["editable", item]);
                  setFieldValue("quantity", item.quantity);
                  break;
                }
                default:
              }
            };

            return (
              <Form layout="horizontal">
                <CountingTable
                  ref={myTableRef}
                  filters={filters}
                  loadData={countingApi.product(params.id)}
                  thead={() => (
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell" rowSpan={2}>№</th>
                        <th className="ant-table-cell" rowSpan={2}>Зураг</th>
                        <th className="ant-table-cell" rowSpan={2}>Нэр</th>
                        <th className="ant-table-cell" rowSpan={2}>Код / Баар код</th>
                        <th className="ant-table-cell" rowSpan={2}>Хайрцаг / Тасаг</th>
                        <th className="ant-table-cell" colSpan={2}>Үлдэгдэл</th>
                        <th className="ant-table-cell" colSpan={2}>Тоолсон</th>
                        <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
                      </tr>
                      <tr>
                        <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                        <th className="ant-table-cell" rowSpan={2}>Дүн</th>
                        <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                        <th className="ant-table-cell" rowSpan={2}>Дүн</th>
                      </tr>
                    </thead>
                  )} tbody={(row, index) => {
                    return (
                      <tbody key={index} className="ant-table-tbody">
                        <tr>
                          <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                          <td className="ant-table-cell" rowSpan={2}>
                            <Avatar src={`${s3host}/${row.image}`} shape="square" />
                          </td>
                          <td className="ant-table-cell">{row.name || "-"}</td>
                          <td className="ant-table-cell">{row.code}</td>
                          <td className="ant-table-cell">{row.box_no || "-"}</td>
                          <td className="ant-table-cell" rowSpan={2}>{row.stock || "-"}</td>
                          <td className="ant-table-cell" rowSpan={2}>{tugrug(row.stock_amount)}</td>
                          <td className="ant-table-cell" rowSpan={2} width={140}>
                            {action && action[0] === "editable" && action[1].id === row.id ? (
                              <FormItem name="quantity">
                                <Input name="quantity" type="number" placeholder="Тоо ширхэг" />
                              </FormItem>
                            ) : row.quantity}
                          </td>
                          <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                          <td className="ant-table-cell" rowSpan={2} width={action && action[0] === "editable" && action[1].id === row.id ? 180 : 100}>
                            {action && action[0] === "editable" && action[1].id === row.id ? (
                              <>
                                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" loading={isSubmitting} type="primary">Хадгалах1</Button>
                                <Button className="button" shape="circle" onClick={() => setAction()}><CloseOutlined /></Button>
                              </>
                            ) : (
                              <RowAction
                                actions={{
                                  edit: "Засварлах"
                                }} onClick={key => onAction(key, row)} />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="ant-table-cell"><a>{row.category?.name || "-"}</a></td>
                          <td className="ant-table-cell">{row.barcode || "-"}</td>
                          <td className="ant-table-cell">
                            {action && action[0] === "editable" && action[1].id === row.id ? (
                              <FormItem name="section_id">
                                <Select placeholder="Тасаг" style={{ width: "100%" }} allowClear>
                                  {sections.filter(section => section.archive_id === filters.archive_id)?.map((item, index) => {
                                    return <Option key={index} value={item.id}>{item.name}</Option>;
                                  })}
                                </Select>
                              </FormItem>
                            ) : row.section?.name || "-"}
                          </td>
                        </tr>
                      </tbody>
                    );
                  }} />
              </Form>
            );
          }}
        </Formik>

        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={FormSchema}
          onSubmit={() => {}}>
          {() => {
            return (
              <Form layout="horizontal">
                <TableFooter {...formItemLayout}>
                  <FormItem name="archive_id" label="Агуулах" required>
                    <Input type="text" value={data.archive?.name} disabled />
                  </FormItem>
                  <FormItem name="staff_user_id" label="Ажилтан" required>
                    <Input type="text" value={`${data.staff_user?.last_name || "-"} ${data.staff_user?.first_name || "-"}`} disabled />
                  </FormItem>
                  <FormItem name="note" label="Тэмдэглэл" required>
                    <TextArea name="note" placeholder="Тэмдэглэл"/>
                  </FormItem>
                </TableFooter>

                <SubmitForm ref={submitRef} />
              </Form>
            );
          }}
        </Formik>
      </PageContent>
    </PageContainer>
  );
};
const CountingTable = styled(CustomTable)`
  .text-center {
    text-align: center;
  }
  .ant-table-cell {
    padding: 8px;
  }
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
const TableFooter = styled(AntForm)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;
const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 

  @media only screen and (max-width: 682px) { 
    .wrap { 
      display: flex;
      flex-direction: column; 
    }
    .ant-space-item {
      width: 100%;
    }
    .item { 
      width: 100% !important; 
    }
  }
  @media only screen and (max-width: 420px) { 
    flex-direction: row; 
    flex-direction: column;  
    .margin-top {
      margin-top: 10px;
    }
    .item { 
      width: 100% !important; 
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
    } 
  }
  
  @media only screen and (max-width: 1162px) {  
    flex-direction: column;   
    .margin-top {
      margin-top: 8px;
    }
  }
`;