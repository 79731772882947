import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Form, FormItem, Input, Select } from "formik-antd";
import { message as notify, Avatar, Select as Search, Form as AntForm, Button, Row, Col, Input as AntInput, Tag, Modal } from "antd";
import { Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MyTable } from "../../components";
import { product as productApi } from "../../apis";
import { order as orderApi } from "../../apis";
import { CloseOutlined, SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import styled from "styled-components";
import moment from "moment";

const { Option } = Select;

const FormOrderSchema = () => Yup.object().shape({
  supplier_id: Yup.string().optional().nullable(),
  note       : Yup.string().max(2000).optional("Заавал бөглөнө!").nullable(),
  products   : Yup.array().of(Yup.object({
    price   : Yup.number().required("Заавал бөглөнө!"),
    quantity: Yup.number().required("Заавал бөглөнө!"),
  }))
});

const { TextArea } = Input;
const { Option: SearchOption } = Search;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setFieldError } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      console.log(errors);

      if (Object.keys(errors).length > 0)
        return false;

      return { values, setFieldError };
    }
  }));

  return null;
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export default ({ action, onCancel, products: init }) => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const submitRef = React.useRef();
  const { s3host, suppliers, isMobile } = useSelector(state => state.general);
  const [initResults, setInitResults] = React.useState([]);
  const [results, setResult] = React.useState([]);
  const [products, setProducts] = React.useState(init || []);
  const [loading, setLoading] = React.useState(false);
  const [data, setFormData] = React.useState({
    type       : "SUPPLIER",
    supplier_id: undefined,
    note       : undefined,
    products   : [],
    ...(action[1] || {})
  });

  let timeout;

  const onSearch = async (query) => {
    if (query) {
      if (timeout)
        clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await productApi.search({ query });

        setResult(res);
      }, 300);
    } else {
      setResult(initResults);
    }
  };

  const onSave = async () => {
    let valid = await submitRef.current.submitForm();
    if (!valid) return;

    const { values: data } = valid;

    if (!data)
      return notify.warning("Захиалгын мэдээлэл дутуу байна!");

    if (data.products?.length <= 0)
      return notify.warning("Захиалгын бараа хоосон байна!");

    if (data) {
      try {
        setLoading(true);
        if (action && action[0] === "update") {
          if (onCancel) {
            await orderApi.upset(data.id, {
              type       : data.type,
              supplier_id: data.supplier_id,
              note       : data.note,
              products   : data.products.map(item => ({
                product_id: item.product_id,
                quantity  : item.quantity
              }))
            });
          } else {
            await orderApi.update(data.id, {
              type       : data.type,
              supplier_id: data.supplier_id,
              note       : data.note,
              products   : data.products.map(item => ({
                product_id: item.product_id,
                quantity  : item.quantity
              }))
            });
          }
        } else {
          await orderApi.create({
            type       : data.type,
            supplier_id: data.supplier_id,
            note       : data.note,
            products   : data.products.map(item => ({
              product_id: item.product_id,
              quantity  : item.quantity
            }))
          });
        }

        if (onCancel)
          onCancel(true);
        else
          history.push("/order/supplier");
        setLoading(false);
        notify.success("Таны хүсэлт амжилттай!");
      } catch (err) {
        if (typeof err.message === "string")
          notify.error(err.message);
      }
    }
  };

  const reload = React.useCallback(async (signal) => {
    let res = await productApi.search("", { signal });

    setResult(res);
    setInitResults(res);
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader
        title="Захиалга (Бэлтгэн нийлүүлэгч)"
        extra={[
          <Button key="clear" icon={isMobile && <CloseOutlined />} shape={isMobile && "circle"} type="default" onClick={() => history.push("/order/supplier")}>{!isMobile && "Болих"}</Button>,
          <Button key="confirm" icon={isMobile && <SendOutlined />} shape={isMobile && "circle"} type="primary" loading={loading} onClick={() => onSave(data.type)}>{!isMobile && "Болсон"}</Button>
        ]} />
      <PageContent>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={FormOrderSchema}
          onSubmit={() => {}}>
          {({ values, setFieldValue }) => {
            let QUANTITY = values.products.reduce((accumulator, iterator) => {
              return accumulator + (iterator.quantity || 0);
            }, 0);

            const columns = useHeader({
              history,
              s3host,
              onClick: (key, item) => {
                switch (key) {
                  case "remove" : {
                    setProducts(products.filter(p => p.id !== item.id));
                    setFieldValue("products", values.products.filter(p => p.product_id !== item.id));
                    break;
                  }
                  default:
                }
              },
            });

            const onChange = (id) => {
              let product = results.find(result => result.id === id);

              if (product && !products.find(p => p.id === product.id)) {
                setProducts([...products, {
                  ...product
                }]);

                setFormData({
                  ...values,
                  products: [...values.products, {
                    product_id: product.id,
                    price     : product.price,
                    quantity  : undefined
                  }]
                });
              }
            };

            return (
              <Form layout="horizontal">
                <IncomeTable
                  ref={myTableRef}
                  items={products}
                  columns={columns}
                  pagination={false}
                />

                <IncomeFooter {...formItemLayout}>
                  <Row gutter={24}>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="supplier_id" label="Бэлтгэн нийлүүлэгч">
                        <Select name="supplier_id" placeholder="Бэлтгэн нийлүүлэгч" allowClear showSearch>
                          {suppliers.map((item, index) => (
                            <Option key={index} value={item.id}>{item.name}</Option>
                          ))}
                        </Select>
                      </FormItem>

                      <Total>
                        <AntForm.Item label="Тоо хэмжээ" required>
                          <AntInput placeholder="Тоо хэмжээ" value={QUANTITY} disabled />
                        </AntForm.Item>
                      </Total>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="note" label="Нэмэлт мэдээлэл (Тайлбар)">
                        <TextArea type="text" name="note" placeholder="Нэмэлт мэдээлэл" />
                      </FormItem>
                    </Col>
                  </Row>
                </IncomeFooter>

                <SubmitForm ref={submitRef} />
              </Form>
            );
          }}
        </Formik>
      </PageContent>
    </PageContainer>
  );
};

const Total = styled.div`
  .ant-input-disabled {
    background: #fff;
    color: #333;
  }
`;
const IncomeFooter = styled(AntForm)`
  margin-top: 20px;
  /* .ant-input[disabled] {
    background: #fff;
    color: #333;
  } */
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;

const SearchBox = styled.div`
  margin-bottom: 20px;
  .ant-select {
    width: 100%;
  }
`;

const IncomeTable = styled(MyTable)`
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }  
`;

const useHeader = ({ s3host, onClick, onChangeQuantity = () => {} }) => {
  return [{
    title : "№",
    width : 10,
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title : "Нэр",
    render: record => {
      return record.name;
    }
  }, {
    title : "Код",
    render: record => {
      return <a>{record.code}</a>;
    }
  },
  // {
  //   title : "Үнэ",
  //   render: (record) => {
  //     return (
  //       <>
  //         <div>{tugrug(record.price)}</div>
  //         <Tag>Б.Үнэ {tugrug(record.sale_price)}</Tag>
  //       </>
  //     );
  //   }
  // },
  {
    title : "Тоо ширхэг",
    render: (record, i, index) => {
      return (
        <FormItem name={`products[${index}].quantity`}>
          <Input name={`products[${index}].quantity`} type="number" onChange={onChangeQuantity} placeholder="Тоо ширхэг" style={{ backgroundColor: "#fff", color: "#333" }} min={0} />
        </FormItem>
      );
    }
  },
  // {
  //   title : "Үйлдэл",
  //   render: (record) => {
  //     return (
  //       <RowAction
  //         actions={{
  //           remove: "Устгах",
  //         }}
  //         onClick={(key) => onClick(key, record)}
  //       />
  //     );
  //   }
  // }
  ];
};