import { combineReducers } from "redux";
import auth from "./auth";
import general from "./general";
import checkout from "./checkout";

export default combineReducers({
  auth,
  general,
  checkout,
});
