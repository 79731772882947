import React, { useState } from "react";
import { Upload, Button, Space } from "antd";
import { Field, useField } from "formik";
import { connect, useSelector } from "react-redux";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

const UploadComponent = ({
  name,
  auth,
  action,
  onResult = () => {},
  ...restProps
}) => {
  const [meta] = useField(name);
  const { s3host } = useSelector((state) => state.general);
  const { token } = auth;
  const [loading, setLoading] = useState(false);

  const handleChange = (
    info,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    setFieldTouched(name, true);

    if (info.file.status === "uploading") {
      setLoading(true);
      setFieldError(name, "Файл хуулж байна!");
    }

    if (info.file.status === "done") {
      setLoading(false);
      setFieldValue(name, info.file.response.url);
      setFieldError(name, undefined);
      if (onResult) {
        onResult(info.file);
      }
    }
  };

  return (
    <Field name={name}>
      {(fieldProps) => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;

        return (
          <Space>
            <Upload
              name="file"
              listType="text"
              showUploadList={false}
              action={action}
              headers={{
                Authorization: `Bearer ${token}`
              }}
              onChange={(info) => handleChange(
                info,
                setFieldValue,
                setFieldTouched,
                setFieldError
              )
              }
              {...restProps}
            >
              { !loading
                ? <Button
                  style={{
                    border: `solid 1px ${
                      meta.error || (meta.touched && !value) ? "red" : "#e8e8e8"
                    }`,
                    width: "max-content"
                  }}
                  icon={<UploadOutlined />}
                >
                  {value ? "Файл өөрчлөх" : "Файл хуулах"}
                </Button>
                : <Button
                  loading={true}
                  disabled={true}
                  style={{
                    border: `solid 1px ${
                      meta.error || (meta.touched && !value) ? "red" : "#e8e8e8"
                    }`,
                    width: "max-content"
                  }}
                  icon={<UploadOutlined />}
                >
                Файл хуулж байна
                </Button>
              }
            </Upload>
            {value && (
              <a href={`${s3host}${value}`} rel="noopener">
                <Space color="green" style={{ width: "max-content" }}>
                  <DownloadOutlined /> Файл татах
                </Space>
              </a>
            )}
          </Space>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(mapStateToProps)(UploadComponent);
