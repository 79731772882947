import React from "react";
import * as Yup from "yup";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { DatePicker, Form, FormItem, Input, Checkbox, Select, Switch } from "formik-antd";
import { message as notify, Avatar, Select as Search, Form as AntForm, Button, Row, Col, Input as AntInput, Tag } from "antd";
import { Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MyTable } from "../../components";
import { damage as damageApi } from "../../apis";
import { tugrug } from "../../utils";
import { SendOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";

const { Option } = Select;

const FormOrderSchema = () => Yup.object().shape({
  is_cash    : Yup.boolean().required("Заавал бөглөх!"),
  cash_amount: Yup.number().when("is_cash", {
    is  : true,
    then: Yup.number().required("Заавал бөглөх!")
  }),
  is_card    : Yup.boolean().required("Заавал бөглөх!"),
  card_amount: Yup.number().when("is_card", {
    is  : true,
    then: Yup.number().required("Заавал бөглөх!")
  }),
  is_account    : Yup.boolean().required("Заавал бөглөх!"),
  account_amount: Yup.number().when("is_account", {
    is  : true,
    then: Yup.number().required("Заавал бөглөх!")
  }),
  discount_amount : Yup.number().optional().nullable(),
  surcharge_amount: Yup.number().optional().nullable(),
  customer_id     : Yup.string().optional().nullable(),
  note            : Yup.string().optional().nullable()
});

const { TextArea } = Input;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setFieldError } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      console.log(errors);

      if (Object.keys(errors).length > 0)
        return false;

      return { values, setFieldError };
    }
  }));

  return null;
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export default ({ action, onCancel, products: init }) => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const submitRef = React.useRef();
  const { s3host, customers, isMobile } = useSelector(state => state.general);
  const [products, setProducts] = React.useState(init || []);
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState({
    type            : "ORDER",
    customer_id     : undefined,
    is_cash         : false,
    is_account      : false,
    is_card         : false,
    is_sale_price   : false,
    cash_amount     : undefined,
    account_amount  : undefined,
    card_amount     : undefined,
    coupon_code     : undefined,
    discount_amount : undefined,
    surcharge_amount: undefined,
    note            : undefined,
    order_date      : undefined,
    products        : [],
    ...(action[1] || {})
  });

  const onSave = async () => {
    let valid = await submitRef.current.submitForm();
    if (!valid) return;

    const { values: data, setFieldError } = valid;

    let amount = 0;

    let total = data.products.reduce((accumulator, iterator) => {
      let price = data.is_sale_price ? iterator.sale_price : iterator.price;

      return accumulator + (iterator.quantity || 0) * (price || 0);
    }, 0);

    total += (parseFloat(data.surcharge_amount || 0));
    total -= (parseFloat(data.discount_amount || 0));

    if (data.is_card === true)
      amount += data.card_amount;

    if (data.is_cash === true)
      amount += data.cash_amount;

    if (data.is_account === true)
      amount += data.account_amount;

    if (!data)
      return notify.warning("Захиалгын мэдээлэл дутуу байна!");

    if (data.products?.length <= 0)
      return notify.warning("Захиалгын бараа хоосон байна!");

    if (amount > total) {

      if (data.is_card === true && data.is_cash === true){
        setFieldError("card_amount", "Үнийн дүн зөрүүтэй!");
        setFieldError("cash_amount", "Үнийн дүн зөрүүтэй!");
      }

      if (data.is_cash === true && data.is_account === true){
        setFieldError("cash_amount", "Үнийн дүн зөрүүтэй!");
        setFieldError("account_amount", "Үнийн дүн зөрүүтэй!");
      }

      if (data.is_card === true && data.is_account === true){
        setFieldError("card_amount", "Үнийн дүн зөрүүтэй!");
        setFieldError("account_amount", "Үнийн дүн зөрүүтэй!");
      }

      if (data.is_card === false && data.is_cash === false && data.is_account === false){
        setFieldError("card_amount", "Төлбөрийн төрлийг сонгоно уу!");
        setFieldError("cash_amount", "Төлбөрийн төрлийг сонгоно уу!");
        setFieldError("account_amount", "Төлбөрийн төрлийг сонгоно уу!");
      }

      if (total < data.card_amount) {
        setFieldError("card_amount", "Төлбөрийн төрлийг сонгоно уу!");
      }
      if (total < data.cash_amount) {
        setFieldError("cash_amount", "Төлбөрийн төрлийг сонгоно уу!");
      }
      if (total < data.account_amount) {
        setFieldError("account_amount", "Төлбөрийн төрлийг сонгоно уу!");
      }

    }
    else if (amount !== total) {
      notify.error("Та төлбөрийн нөхцлийг сонгоно уу!!!");
    }
    else if (amount === total) {

      try {
        setLoading(true);
        await damageApi.upset(data.id, {
          customer_id: data.customer_id,

          cash_amount   : data.is_cash === false && "0" || data.cash_amount,
          card_amount   : data.is_card === false && "0" || data.card_amount,
          account_amount: data.is_account === false && "0" || data.account_amount,


          surcharge_amount: data.surcharge_amount,
          discount_amount : data.discount_amount,
          is_sale_price   : data.is_sale_price,
          order_date      : moment(data.order_date),
          note            : data.note,
          products        : data.products.map(item => ({
            product_id: item.product_id,
            quantity  : item.quantity,
            box_no    : item.box_no
          }))
        });

        if (onCancel)
          onCancel(true);
        else
          history.push("/damage/list");

        setLoading(false);
        notify.success("Таны хүсэлт амжилттай!");

      } catch (err) {
        setLoading(false);
        if (typeof err.message === "string")
          notify.error(err.message);
      }
    } else {
      if (data.is_card === true && data.is_cash === true && data.is_account === true){
        setFieldError("card_amount", "Үнийн дүн зөрүүтэй");
        setFieldError("cash_amount", "Үнийн дүн зөрүүтэй");
        setFieldError("account_amount", "Үнийн дүн зөрүүтэй");
      }

      if (data.is_card === true && data.is_cash === true && data.is_account === true){
        setFieldError("card_amount", "Үнийн дүн зөрүүтэй");
        setFieldError("cash_amount", "Үнийн дүн зөрүүтэй");
        setFieldError("account_amount", "Үнийн дүн зөрүүтэй");
      }
      if (data.is_card === true){
        setFieldError("card_amount", "Үнийн дүн зөрүүтэй");
      }
      if (data.is_cash === true){
        setFieldError("cash_amount", "Үнийн дүн зөрүүтэй");
      }
      if (data.is_account === true){
        setFieldError("account_amount", "Үнийн дүн зөрүүтэй");
      }

      if (data.is_card === false && data.is_cash === false && data.is_account === false){
        setFieldError("card_amount", "Төлбөрийн төрлийг сонгоно уу!");
        setFieldError("cash_amount", "Төлбөрийн төрлийг сонгоно уу!");
        setFieldError("account_amount", "Төлбөрийн төрлийг сонгоно уу!");
      }
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Акталсан бараа засварлах" extra={[
        <Button key="confirm" icon={isMobile && <SendOutlined />} shape={isMobile && "circle"} type="primary" loading={loading} onClick={() => onSave(data.type)}>{!isMobile && "Болсон"}</Button>
      ]} />
      <PageContent>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={FormOrderSchema}
          onSubmit={() => {}}>
          {({ values, setFieldValue }) => {

            const onChangeCustomer = (id) => {
              let customer = customers.find(c => c.id === id);

              if (customer) {
                setFieldValue("shipping_address", customer.address);
                setFieldValue("shipping_phone", customer.phone);
              } else {
                setFieldValue("shipping_address", "");
                setFieldValue("shipping_phone", "");
              }
            };

            let QUANTITY = values.products.reduce((accumulator, iterator) => {
              return accumulator + (iterator.quantity || 0);
            }, 0);

            let TOTAL_AMOUNT = values.products.reduce((accumulator, iterator) => {
              let price = values.is_sale_price ? iterator.sale_price : iterator.price;

              return accumulator + (iterator.quantity || 0) * (price || 0);
            }, 0);

            TOTAL_AMOUNT += (parseFloat(values.surcharge_amount || {}));
            TOTAL_AMOUNT -= (parseFloat(values.discount_amount || {}));

            const columns = useHeader({
              history,
              s3host,
              onChangeQuantity: () => {
                setFieldValue("card_amount", 0);
                setFieldValue("is_card", false);
                setFieldValue("cash_amount", 0);
                setFieldValue("is_cash", false);
                setFieldValue("account_amount", 0);
                setFieldValue("is_account", false);
              },
              onClick: (key, item) => {
                switch (key) {
                  case "remove" : {
                    setProducts(products.filter(p => p.id !== item.id));
                    setFieldValue("products", values.products.filter(p => p.product_id !== item.id));
                    break;
                  }
                  default:
                }
              },
            });

            const onMethod = (type, field) => {
              if (!values[type]) {
                setFieldValue(field, TOTAL_AMOUNT);
              } else {
                setFieldValue(field, undefined);
              }
            };

            const onChangeValue = (field, value) => {
              switch (field) {
                case "card_amount": {
                  if (values.is_cash === true)
                    setFieldValue("cash_amount", TOTAL_AMOUNT - value);
                  else
                    setFieldValue("cash_amount", undefined);

                  setFieldValue("account_amount", undefined);
                  break;
                }
                case "cash_amount": {
                  if (values.is_account === true)
                    setFieldValue("account_amount", TOTAL_AMOUNT - (values.is_card ? values.card_amount : 0) - value);
                  else
                    setFieldValue("account_amount", undefined);
                  break;
                }
                default:
              }
            };

            return (
              <Form layout="horizontal">

                <IncomeTable
                  ref={myTableRef}
                  items={products}
                  columns={columns}
                  pagination={false}
                />

                <IncomeFooter {...formItemLayout}>
                  <Row gutter={24}>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      <SwitchContainer>
                        <Switch name="is_sale_price" style={{ marginRight: 10 }} disabled /> Бөөний үнээр тооцох
                      </SwitchContainer>

                      <Total>
                        <AntForm.Item label="Тоо хэмжээ" required>
                          <AntInput placeholder="Тоо хэмжээ" value={QUANTITY} disabled />
                        </AntForm.Item>
                        <AntForm.Item label="Нийт дүн" required>
                          <AntInput placeholder="Нийт дүн" value={tugrug(TOTAL_AMOUNT)} disabled />
                        </AntForm.Item>
                        <FormItem label="Хямдарсан дүн" name="discount_amount">
                          <Input type="number" name="discount_amount" placeholder="Хямдарсан дүн (discount)" min={0} />
                        </FormItem>
                        <FormItem label="Нэмэлт төлбөр" name="surcharge_amount">
                          <Input type="number" name="surcharge_amount" placeholder="Нэмэлт төлбөр" min={0} />
                        </FormItem>
                      </Total>

                      <FormItemGroup label="Картаар" name="card_amount" required>
                        <div className="group">
                          <Input min={0} type="number" name="card_amount" placeholder={0} disabled={!values.is_card} onChange={e => onChangeValue("card_amount", e.target.value)} />
                          <div className="exclusion">
                            <Checkbox name="is_card" onChange={() => onMethod("is_card", "card_amount")} />
                          </div>
                        </div>
                      </FormItemGroup>
                      <FormItemGroup label="Бэлнээр" name="cash_amount" required>
                        <div className="group">
                          <Input min={0} type="number" name="cash_amount" placeholder={0} disabled={!values.is_cash} onChange={e => onChangeValue("cash_amount", e.target.value)} />
                          <div className="exclusion">
                            <Checkbox name="is_cash" onChange={() => onMethod("is_cash", "cash_amount")} />
                          </div>
                        </div>
                      </FormItemGroup>
                      <FormItemGroup label="Дансаар" name="account_amount" required>
                        <div className="group">
                          <Input min={0} type="number" name="account_amount" placeholder={0} disabled={!values.is_account} onChange={e => onChangeValue("account_amount", e.target.value)} />
                          <div className="exclusion">
                            <Checkbox name="is_account" onChange={() => onMethod("is_account", "account_amount")} />
                          </div>
                        </div>
                      </FormItemGroup>

                      <FormItem name="order_date" label="Борлуулсан огноо">
                        <DatePicker name="order_date"
                          format="YYYY-MM-DD HH:mm:ss"
                          showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                          style={{ width: "100%" }} placeholder="Огноо" allowClear />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="customer_id" label="Харилцагч">
                        <Select name="customer_id" placeholder="Харилцагч" allowClear showSearch onChange={onChangeCustomer}>
                          {customers.map((item, index) => (
                            <Option key={index} value={item.id}>{item.type === "ORGANIZATION" ? `${item.name}` : `${item?.last_name} ${item?.first_name}`}</Option>
                          ))}
                        </Select>
                      </FormItem>

                      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="note" label="Нэмэлт мэдээлэл (Тайлбар)">
                        <TextArea type="text" name="note" placeholder="Нэмэлт мэдээлэл" />
                      </FormItem>
                    </Col>
                  </Row>
                </IncomeFooter>

                <SubmitForm ref={submitRef} />
              </Form>
            );
          }}
        </Formik>
      </PageContent>
    </PageContainer>
  );
};

const Total = styled.div`
  .ant-input-disabled {
    background: #fff;
    color: #333;
  }
`;
const SwitchContainer = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: whitesmoke;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const FormItemGroup = styled(FormItem)`
  position: ralative;
  .range-number {
    display: flex;
    flex-direction: row;
    width: 100%;
    .range-to {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 74px;
    }
  }

  .ant-picker, ant-picker-range {
    width: 100%;
  }
  .group {
    display: flex;
    flex-direction: row;
    .exclusion {
      height: 32px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .has-exclusion {
    position: absolute;
    right: 0;
  }
`;
const IncomeFooter = styled(AntForm)`
  margin-top: 20px;
  /* .ant-input[disabled] {
    background: #fff;
    color: #333;
  } */
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;

const SearchBox = styled.div`
  margin-bottom: 20px;
  .ant-select {
    width: 100%;
  }
`;

const IncomeTable = styled(MyTable)`
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }  
`;

const useHeader = ({ s3host, onClick, onChangeQuantity }) => {
  return [{
    title : "№",
    width : 10,
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title : "Нэр",
    render: record => {
      return record.name;
    }
  }, {
    title : "Код",
    render: record => {
      return <div><a>{record.code}</a> <br></br>(BoxNo: {record?.box_no})</div>;
    }
  }, {
    title : "Үнэ",
    render: (record) => {
      return (
        <>
          <div>{tugrug(record.price)}</div>
          <Tag>Б.Үнэ {tugrug(record.sale_price)}</Tag>
        </>
      );
    }
  }, {
    title : "Тоо ширхэг",
    render: (record, i, index) => {
      return (
        <FormItem name={`products[${index}].quantity`}>
          <Input name={`products[${index}].quantity`} type="number" onChange={onChangeQuantity} placeholder="Тоо ширхэг" min={0} />
        </FormItem>
      );
    }
  }];
};