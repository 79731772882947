import React from "react";
import { useParams } from "react-router-dom";
import { inout as inoutApi } from "../../apis";
import { Spin, Avatar, Form, Input, Tag, Button, Modal, message } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { tugrug } from "../../utils";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { MyTable } from "../../components";
import styled from "styled-components";
import moment from "moment";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default () => {
  const params = useParams();
  const history = useHistory();
  const myTableRef = React.useRef();
  const { s3host, isMobile } = useSelector(state => state.general);
  const [loading, setLoading] = React.useState(false);
  const [excelLoading, setExcelLoading] = React.useState(false);
  const [data, setFormData] = React.useState([]);

  const columns = useHeader({
    history,
    s3host,
    data,
    onClick: (key) => {
      switch (key) {
        case "remove" : {
          break;
        }
        default:
      }
    },
  });

  const onAction = async (type) => {
    switch (type) {
      case "confirm": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Та баталгаажуулахдаа итгэлтэй байна уу!",
          onOk   : async () => {
            await inoutApi.confirm(data.request_id);
            message.success("Таны хүсэлт амжилттай!");
            history.push("/archive/inout/list");
          }
        });
        break;
      }
      case "cancel": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Та цуцлахдаа итгэлтэй байна уу!",
          okType : "danger",
          onOk   : async () => {
            await inoutApi.cancel(data.request_id);
            message.success("Таны хүсэлт амжилттай!");
            history.push("/archive/inout/list");
          }
        });
        break;
      }
      default:
    }
  };

  const requestStatus = () => {
    switch (data?.request?.request_status) {
      case "CONFIRMED": {
        return [<Tag color='green'>Баталгаажсан</Tag>];
      }
      case "CANCELED": {
        return [<Tag color='red'>Цуцлагдсан</Tag>];
      }
      default:
        return [
          <Button type="danger" onClick={() => onAction("cancel")}>Цуцлах</Button>,
          <Button type="primary" onClick={() => onAction("confirm")}>Баталгаажуулах</Button>
        ];
    }
  };

  const onExport = async () => {
    try {
      setExcelLoading(true);
      let res = await inoutApi.exportExcel(params.id);

      if (res.url)
        window.open(
          s3host + "/files/" + res.url,
          "_blank"
        );

      setExcelLoading(false);
    } catch (err) {
      console.log(err);
      setExcelLoading(false);
    }
  };

  const reload = React.useCallback(async (signal) => {
    setLoading(true);
    let res = await inoutApi.get(params.id, { signal });
    setLoading(false);

    setFormData(res);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      {data && <PageHeader title={(() => {

        if (data?.request?.type === "SUPPLIER")
          return "Бэлтгэн нийлүүлэгч";

        if (data?.request?.type === "IN"){
          return "Бараа авах хүсэлт";
        }

        return "Бараа өгөх хүсэлт";
      })()} extra={[
        requestStatus(),
        <Button type="primary" shape={isMobile && "circle"} loading={excelLoading} onClick={() => onExport()}>{!isMobile && "Excel файл"}</Button>
      ]} />}
      <PageContent>
        <Spin spinning={loading}>
          <IncomeTable
            ref={myTableRef}
            items={data?.products || []}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.id} />

          <IncomeFooter {...formItemLayout}>
            <Form.Item label="Барааны тоо">
              <Input type="text" value={data.quantity} disabled />
            </Form.Item>
            <Form.Item label="Нийт дүн">
              <Input type="text" value={tugrug(data.total_amount)} disabled />
            </Form.Item>
            <Form.Item label={`Хүсэлт илгээсэн (${data.type === "SUPPLIER" ? "Бэлтгэн нийлүүлэгч" : "Агуулах"})`} required>
              <Input type="text" value={data?.supplier?.name || data?.from_archive?.name} disabled />
            </Form.Item>
            <Form.Item label="Хүсэлт илгээсэн (Хэрэглэгч)" required>
              <Input type="text" value={`${data?.request?.sender_user?.last_name} ${data?.request?.sender_user?.first_name}`} disabled />
            </Form.Item>
            <Form.Item label="Хүлээн авсан (Агуулах)" required>
              <Input type="text" value={data?.to_archive?.name} disabled />
            </Form.Item>
            <Form.Item label="Хүлээн авсан (Хэрэглэгч)" required>
              <Input type="text" value={`${data?.request?.confirmed_user?.last_name || "-"} ${data?.request?.confirmed_user?.first_name || "-"}`} disabled />
            </Form.Item>
            <Form.Item label="Огноо" required>
              <Input type="text" value={moment(data.created_at).format("YYYY-MM-DD HH:mm")} disabled />
            </Form.Item>
            <Form.Item label="Тэмдэглэл" required>
              <TextArea placeholder="Тэмдэглэл" value={data?.request?.note || "-"} disabled></TextArea>
            </Form.Item>
          </IncomeFooter>
        </Spin>
      </PageContent>
    </PageContainer>
  );
};

const IncomeFooter = styled(Form)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;

const IncomeTable = styled(MyTable)`
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;

const useHeader = ({ s3host, data }) => {
  return [{
    title : "№",
    width : 10,
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title : "Нэр",
    render: record => {
      return record.name;
    }
  }, {
    title : "Ангилал",
    render: record => {
      return record?.category?.name || "-";
    }
  }, {
    title : "Дотоод код",
    render: record => {
      return <a>{record.code}</a>;
    }
  }, {
    title : "Үнэ",
    render: (record, i, index) => {
      return tugrug(record.price);
    }
  }, {
    title : "Тоо ширхэг",
    render: (record, i, index) => {
      return record.quantity;
    }
  }, {
    title : "Хайрцаг",
    render: (record, i, index) => {
      return (<div>{record.box_no || "-"}</div>);
    }
  }];
};
