const initialState = {
  user  : {},
  token : null,
  tokens: []
};

const auth = (state = initialState, action) => {

  switch (action.type) {
    case "auth.change":
      const { access_token, uid } = action.payload;

      return {
        ...state,
        token : access_token,
        tokens: [access_token, uid],
      };
    case "auth.me":
      return {
        ...state,
        user: action.payload,
      };
    case "auth.logout":
      return initialState;
    default:
      return state;
  }
};

export default auth;
