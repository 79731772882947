import React from "react";
import { Space, Tag, Input, DatePicker, Button, Select } from "antd";
import { damage } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { CalendarOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { CustomTable, RowAction, ProductItem } from "../../components";
import { useHistory } from "react-router-dom";
import { tugrug } from "../../utils";
import moment from "moment";
import styled from "styled-components";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
const { Option } = Select;

const DamageListComponents = () => {
  const history = useHistory();
  const myTableRef = React.useRef(null);
  const [action, setAction] = React.useState();
  const [query, setQuery] = React.useState("");
  const { isMobile, archives, staffs } = useSelector(state => state.general);
  const { user } = useSelector(state => state.auth);
  const [filters, setFilters] = React.useState({
    query     : "",
    type      : "",
    start_date: undefined,
    end_date  : undefined,
    archive_id: undefined
  });

  const orderStatus = (value) => {
    if (value === "NEW") {
      return <Tag>Үүсгэсэн</Tag>;
    }
    if (value === "COMPLETED") {
      return <Tag color="green">Амжилттай</Tag>;
    }
  };

  const onAction = async (type, item) => {
    switch (type) {
      case "detail": {
        history.push(`/damage/detail/${item.id}`);
        break;
      }
      case "edit": {
        history.push(`/damage/edit/${item.id}`);
        break;
      }
      default:
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onCancel = () => {
    setAction();
  };

  const onClear = () => {
    setQuery(null);
    setFilters({ query: "", type: "", archive_id: undefined });
    setAction();
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters({
          ...filters,
          query: query
        });
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };

  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Акталсан барааны жагсаалт" />
      <PageFilter>
        <Space className="wrap item">
          <Space className="item" >
            <Input className="item" name="code" placeholder="Дугаараар хайх ..."
              style={{ minWidth: 180 }} prefix={<SearchOutlined />}
              allowClear onChange={e => setQuery(e.target.value)} />
          </Space>
          <Space className="item">
            {user.role === "ADMIN" && (
              <Select className="item" value={filters.archive_id} placeholder="Агуулах" style={{ width: 200 }} onChange={value => setFilters({ ...filters, archive_id: value })} allowClear>
                {archives.map((item, index) => {
                  return <Option key={index} value={item.id}>{item.name}</Option>;
                })}
              </Select>
            )}
            {user.role === "ADMIN" && (
              <Select className="item" placeholder="Ажилтан" style={{ minWidth: 130 }} onChange={value => setFilters({ ...filters, staff_id: value })} allowClear>
                {staffs?.map((item, index) => {
                  return <Option className="item" key={index} value={item.id}>{`${item.last_name} ${item.first_name}`}</Option>;
                })}
              </Select>
            )}
          </Space>
        </Space>
        <Space className="margin-top" style={{ paddingLeft: "10px" }}>
          {isMobile ? (
            <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
          ) : (
            <RangePicker className="item" value={filters.start_date && filters.end_date &&
            [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate}
            allowClear={false} />)}
          <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <SalesTable
          ref={myTableRef}
          filters={filters}
          loadData={damage.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                <th className="ant-table-cell" rowSpan={2}>Борлуулалт</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" colSpan={4}>Төлбөр</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell">Нийт дүн</th>
                <th className="ant-table-cell">Картаар</th>
                <th className="ant-table-cell">Бэлнээр</th>
                <th className="ant-table-cell">Дансаар</th>
              </tr>
            </thead>
          )} tbody = {(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell" rowSpan={2}><Tag color={row?.type === "PURCHASE" ? "blue" : "warning"}>{row.code}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <a>{row?.customer?.type === "CITIZEN" ? `${row?.customer?.last_name || "-"} ${row?.customer?.first_name || "-"}` : `${row?.customer?.name || "Зочин"}`}</a>
                    <p>{row?.note || "-"}</p>
                  </td>
                  <td className="ant-table-cell">{row?.archive?.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <div>{tugrug(row.total_amount)}</div>
                    {row.is_sale_price && <Tag color="blue" style={{ marginBottom: 5 }}>Бөөний үнээр тооцов</Tag>}
                    {row.discount_amount > 0 && <Tag color="red" style={{ marginBottom: 5 }}>-{tugrug(row.discount_amount)} Хямдрал</Tag>}
                    {row.surcharge_amount > 0 && <Tag color="yellow">{tugrug(row.surcharge_amount)} Нэмэлт төлбөр</Tag>}
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.card_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.cash_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.account_amount)}</td>
                  <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderStatus(row.order_status)}</td>
                  <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.order_date).format("YYYY.MM.DD HH:mm")}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        edit  : "Засварлах",
                        detail: "Дэлгэрэнгүй",
                      }} onClick={(key) => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.user?.first_name || "-"} ${row?.user?.last_name || "-"}`}</a></td>
                </tr>
                <tr>
                  <td></td>
                  <td className="ant-table-cell" colSpan={15}>
                    {row.products.map((item, index) => <ProductItem key={index} image={item?.product?.image} name={item?.product?.name} quantity={item.quantity} code={item?.product?.code} boxNo={item.box_no} />)}
                  </td>
                </tr>
              </tbody>
            );
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

const SalesTable = styled(CustomTable)`
  .ant-tabl-cell {
    padding: 4px 8px;
  }
  .text-center {
   text-align: center;
  }
`;

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 
  flex-direction: row;  

  @media only screen and (max-width: 735px) {  
    flex-direction: column;  
    .wrap {
      display: flex;
      flex-direction: column;
    }
    .ant-space-item {
      width: 100% !important;
    }
    .item {  
      width: 100% !important; 
    } 
  }
  @media only screen and (max-width: 472px) {   
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    } 
    
  }
  @media only screen and (max-width: 1292px) {  
    flex-direction: column;   
    .margin-top {
      margin-top: 8px;
      padding-left: 0px !important; 
    }  
  } 
`;

export default DamageListComponents;