import React from "react";
import * as Yup from "yup";
import { Button, Form as AntForm, Row, Col } from "antd";
import { Form, FormItem, Input } from "formik-antd";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import styled from "styled-components";

const FormSchema = () => Yup.object().shape({
  qty: Yup.number().required("Заавал бөглөнө үү!"),
});

export default ({ onSubmit, onCancel, item, action }) => {
  const { s3host } = useSelector(state => state.general);
  const [data] = React.useState({
    qty: 1
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {() => {
        return (
          <Form layout="vertical">
            <Row gutter={8}>
              <Col span={8}>
                <Image className="image">
                  <img src={`${s3host}/${item?.image}`} />
                </Image>
              </Col>
              <Col span={16}>
                {action && action[3] === "DAMAGE" &&
                <FormItem name="qty" label="Тоо хэмжээ">
                  <Input type="number" name="qty" placeholder="Тоо хэмжээ" min={1} max={action && action[2].qty} />
                </FormItem>}
                <AntForm.Item>
                  <Button className="button" style={{ marginRight: 10 }} type="default" onClick={onCancel}>Болих</Button>
                  <Button className="button" htmlType="submit" type="primary">Хадгалах</Button>
                </AntForm.Item>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
const Image = styled.div`
  min-width: 55px;
  margin-right: 10px;
  img {
    border-radius: 4px;
    width: 100%;
  }
`;
