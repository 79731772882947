import React from "react";
import { Form, FormItem, Input, Select, Checkbox } from "formik-antd";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import styled from "styled-components";
import { user as userApi } from "../../apis";
import useFetch from "../../hooks/useFetch";

const { TextArea } = Input;

const FormSchema = () => Yup.object().shape({
  is_active    : Yup.boolean().required(),
  is_income    : Yup.boolean().required(),
  is_quantity  : Yup.boolean().required(),
  name         : Yup.string().required("Заавал бөглөнө!"),
  staff_user_id: Yup.string().optional().nullable(),
  address      : Yup.string().required("Заавал бөглөнө!"),
});

export default ({ action, onSubmit, onCancel }) => {
  const { archiveTypes } = useSelector(state => state.general);
  const [filters] = React.useState({
    filter: {
      query: ""
    },
    offset: { page: 1, limit: 100 }
  });
  const [{ rows: staffs }] = useFetch(userApi.list, filters)({ rows: [], count: 0 });
  const [data] = React.useState({
    is_active    : true,
    is_income    : false,
    is_quantity  : false,
    name         : undefined,
    staff_user_id: undefined,
    address      : undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form {...formItemLayout}>
          <FormItem name="name" label="Нэр" required>
            <Input name="name" placeholder="Нэр"/>
          </FormItem>
          <FormItem name="address" label="Хаяг" required>
            <TextArea name="address" placeholder="Хаяг" />
          </FormItem>
          <FormItem name="is_active" label="Идэвхтэй эсэх" required>
            <Checkbox name="is_active" />
          </FormItem>
          <FormItem name="is_income" label="Бэлтгэн нийлүүлэгч" required>
            <Checkbox name="is_income" />
          </FormItem>
          <FormItem name="is_quantity" label="Агуулахын үлдэгдэл" required>
            <Checkbox name="is_quantity" />
          </FormItem>
          <StaffContainer>
            <FormItem name="staff_user_id" label="Хариуцсан ажилтан">
              <Select showSearch name="staff_user_id" placeholder="Сонгох" allowClear>
                {staffs?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.last_name} {item.first_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </StaffContainer>
          <AntForm.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};

const StaffContainer = styled.div`
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  background: #fffad0;
  padding: 10px;
`;
