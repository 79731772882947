import React from "react";
import * as Yup from "yup";
import { product as productApi, inout as inoutApi } from "../../apis";
import { Button, message as notify, Avatar, Select as Search, Form as AntForm, Tag, Upload, message, Modal } from "antd";
import { CloseOutlined, SaveOutlined, UploadOutlined, ExclamationCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Form, FormItem, Input, Select, DatePicker } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MyTable, RowAction } from "../../components";
import styled from "styled-components";
import { tugrug } from "../../utils";
import List from "./List";
import Detail from "./Detail";
import ExcelForm from "./components/ExcelForm";

export const IncomeList = List;
export const IncomeDetail = Detail;


const FormSchema = () => Yup.object().shape({
  supplier_id  : Yup.string().required("Заавал бөглөнө!"),
  to_archive_id: Yup.string().required("Заавал бөглөнө!"),
  products     : Yup.array().of(Yup.object({
    price      : Yup.number().required("Заавал бөглөнө!"),
    expiry_date: Yup.date().optional("Заавал бөглөнө!"),
    quantity   : Yup.number().required("Заавал бөглөнө!"),
    box_no     : Yup.string().required("Заавал бөглөнө!"),
  })),
  note: Yup.string().required("Заавал бөглөнө!")
});

const { Option } = Select;
const { Option: SearchOption } = Search;
const { TextArea } = Input;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      console.log(errors);

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const submitRef = React.useRef();
  const { s3host, suppliers, archives, isMobile } = useSelector(state => state.general);
  const [initResults, setInitResults] = React.useState([]);
  const [searchBox, setSearchBox] = React.useState([]);
  const [results, setResult] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const { token } = useSelector(state => state.auth);
  const [loading, setLoading] = React.useState(false);
  const [excelLoading, setExcelLoading] = React.useState(false);
  const [percent, setPercent] = React.useState();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const [data, setFormData] = React.useState({
    supplier_id  : undefined,
    to_archive_id: undefined,
    products     : [],
    note         : undefined
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let timeout;

  const onSearch = async (query) => {
    if (query) {
      if (timeout)
        clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await productApi.search({ query });

        setResult(res);
      }, 300);
    } else {
      setResult(initResults);
    }
  };

  const onChange = (id, values) => {
    let product = results.find(result => result.id === id);
    // && !products.find(p => p.id === id)
    if (product) {
      setProducts([...products, {
        ...product
      }]);

      setFormData({
        ...data,
        products: [...values.products, {
          product_id : id,
          price      : product.price,
          expiry_date: undefined,
          quantity   : undefined,
          box_no     : undefined
        }]
      });
    }
  };

  const onTemplate = () => {
    try {
      setExcelLoading(true);
      window.open(
        "https://storage.googleapis.com/azod/10177673-1cf3-4ff3-a465-6ef968626398/ab-erp2.xlsx",
        "_blank"
      );
      setExcelLoading(false);
    } catch (err) {
      console.log(err);
      setExcelLoading(false);
    }
  };

  const onSave = async () => {
    setLoading(true);
    let data = await submitRef.current.submitForm();

    if (data) {
      try {
        await inoutApi.request("SUPPLIER", {
          note         : data.note,
          supplier_id  : data.supplier_id,
          to_archive_id: data.to_archive_id,
          products     : data.products.map(item => ({
            product_id : item.product_id,
            expiry_date: item.expiry_date,
            quantity   : item.quantity,
            box_no     : item.box_no,
          }))
        });

        notify.success("Таны хүсэлт амжилттай!");
        history.push("/product/income");
      } catch (err) {
        notify.error(err.message);
      }
    }
    setLoading(false);
  };

  const loadData = async () => {
    let items = await inoutApi.init({});

    setProducts(items);
    setFormData({
      ...data,
      products: items.map(item => ({
        product_id: item.id,
        price     : item.price,
        stock     : item.stock,
        quantity  : item.quantity,
        box_no    : item.box_no,
      }))
    });
  };

  const onCancel = () => {
    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Та цуцлахдаа итгэлтэй байна уу!",
      okType : "danger",
      onOk   : async () => {
        await inoutApi.clear();
        message.success("Таны хүсэлт амжилттай!");
        history.push("/product/income");
      }
    });
  };

  const reload = React.useCallback(async (signal) => {
    let res = await productApi.search("", { signal });

    loadData();

    setResult(res);
    setInitResults(res);
  }, []);

  let timer;

  const productSearch = async (q = "") => {
    if (timer)
      clearTimeout(timer);

    timer = setTimeout(async () => {
      let result = await inoutApi.searchBox({ query: q });

      setSearchBox(result);

      // eslint-disable-next-line array-callback-return
      (searchBox || []).map((item) => {
        if (item.box_no !== q){
          setSearchBox([
            {
              box_no: q,
              custom: true
            },
            ...searchBox.filter(f => !f?.custom),
          ]);
        } else if (result.length === 0) {
          setSearchBox([{
            box_no: q
          }]);
        }
      });

    }, 300);
  };

  React.useEffect(() => {
    productSearch();
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Бараа орлогодох" extra={[
        <Button type="default" shape={isMobile && "circle"} icon={isMobile && <CloseOutlined />} onClick={() => onCancel()}>{!isMobile && "Цэвэрлэх"}</Button>,
        <Button type="default" shape={isMobile && "circle"} icon={isMobile} onClick={() => onTemplate()}>{!isMobile && "Загвар"}</Button>,
        // <Button type="default" shape={isMobile && "circle"} icon={<DownloadOutlined />} onClick={() => onExport()} htmlType="submit" loading={excelLoading}>{!isMobile && "Excel файл"}</Button>,
        // <Upload

        //   key="import"
        //   name='file'
        //   listType='text'
        //   showUploadList={false}
        //   action={"/api/inout/import/file/ef39a1ce-016a-4ebc-9996-aa6d701fb0a0/82f65b29-f135-4eb2-b259-421d9c4feeac"}
        //   headers={{
        //     Authorization: `Bearer ${token}`,
        //   }}
        //   onChange={(info) => {
        //     setPercent(info?.file?.percent);

        //     if (info?.file?.percent === 100 && percent !== 100) {
        //       setTimeout(() => {
        //         loadData();
        //       }, 1000);

        //       message.success("Файлыг амжилттай хуулж дууслаа!");
        //     }
        //   }}>
        //   <Button type="primary" shape={isMobile && "circle"} icon={<UploadOutlined />} htmlType="submit" loading={excelLoading}>{!isMobile && "Excel файл"}</Button>
        // </Upload>,

        <Button type="primary" shape={isMobile && "circle"} icon={<UploadOutlined />} onClick={showModal} >{!isMobile && "Excel файл"}</Button>,

        <Button type="primary" shape={isMobile && "circle"} icon={isMobile && <SaveOutlined />} loading={loading} onClick={() => onSave()} htmlType="submit">{!isMobile && "Хадгалах"}</Button>
      ]} />
      <PageContent>

        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={FormSchema}
          onSubmit={() => {}}>
          {({ values, setFieldValue }) => {
            const columns = useHeader({
              history,
              suppliers,
              s3host,
              productSearch,
              searchBox,
              onClick: (key, item) => {
                switch (key) {
                  case "remove" : {
                    setProducts(products.filter(p => p.id !== item.id));
                    setFieldValue("products", values.products.filter(p => p.product_id !== item.id));
                    break;
                  }
                  default:
                }
              },
            });

            let QUANTITY = values.products.reduce((accumulator, iterator) => {
              return accumulator + iterator.quantity || 0;
            }, 0);

            let TOTAL_AMOUNT = values.products.reduce((accumulator, iterator) => {
              return accumulator + (iterator.quantity || 0) * (iterator.price || 0);
            }, 0);

            return (
              <Form layout="horizontal">
                <SearchBox>
                  <Search
                    showSearch
                    showArrow={false}
                    filterOption={false}
                    onSearch={onSearch}
                    onSelect={(id) => {
                      onChange(id, values);
                    }}
                    size="large"
                    placeholder="Эндээс бүгдийг хайж олох ...">
                    {results.map((item, index) => (
                      <SearchOption key={index} value={item.id}><Tag>{item.code}</Tag> {item.name}</SearchOption>
                    ))}
                  </Search>
                </SearchBox>

                {/* excel Бараа орлогодох, Загвар */}

                <IncomeTable
                  ref={myTableRef}
                  items={products}
                  columns={columns}
                  pagination={false}
                  rowKey={(record) => record.id} />

                <IncomeFooter {...formItemLayout}>
                  <FormItem name="quantity" label="Барааны тоо">
                    <Input type="text" value={QUANTITY} disabled />
                  </FormItem>
                  <FormItem name="total_amount" label="Нийт дүн">
                    <Input type="text" value={tugrug(TOTAL_AMOUNT)} disabled />
                  </FormItem>
                  <FormItem name="supplier_id" label="Нийлүүлэгч" required>
                    <Select name="supplier_id" placeholder="Сонгох">
                      {suppliers.map((item, index) => {
                        return <Option key={index} value={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  </FormItem>
                  <FormItem name="to_archive_id" label="Агуулах" required>
                    <Select name="to_archive_id" placeholder="Сонгох">
                      {archives.filter(archive => archive.is_income === true).map((item, index) => {
                        return <Option key={index} value={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  </FormItem>
                  <FormItem name="note" label="Тэмдэглэл" required>
                    <TextArea name="note" placeholder="Тэмдэглэл"/>
                  </FormItem>
                </IncomeFooter>

                <SubmitForm ref={submitRef} />
              </Form>
            );
          }}
        </Formik>
      </PageContent>
      <Modal
        title="Excel файл"
        visible={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose
        footer={false}>
        <ExcelForm onCancel={handleCancel} isModalVisible={isModalVisible} />
      </Modal>
    </PageContainer>
  );
};
const IncomeFooter = styled(AntForm)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;

const SearchBox = styled.div`
  margin-bottom: 20px;
  .ant-select {
    width: 100%;
  }
`;

const IncomeTable = styled(MyTable)`
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
const useHeader = ({ s3host, onClick, productSearch, searchBox }) => {
  return [{
    title : "№",
    width : 10,
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title    : "Нэр",
    dataIndex: "name"
  }, {
    title : "Ангилал",
    render: record => {
      return record?.category?.name || "-";
    }
  }, {
    title : "Дотоод код",
    render: record => {
      return <a>{record.code}</a>;
    }
  }, {
    title : "Үнэ",
    render: (record, i, index) => {
      return tugrug(record.price);
    }
  }, {
    title : "Тоо ширхэг",
    render: (record, i, index) => {
      return (
        <FormItem name={`products[${index}].quantity`}>
          <Input name={`products[${index}].quantity`} type="number" placeholder="Тоо ширхэг" min={0} />
        </FormItem>
      );
    }
  }, {
    title : "Хүчинтэй хугацаа",
    render: (record, i, index) => {
      return (
        <FormItem name={`products[${index}].expiry_date`}>
          <DatePicker name={`products[${index}].expiry_date`} placeholder="Хадгалах хугацаа" />
        </FormItem>
      );
    }
  }, {
    title : "Хайрцаг",
    render: (record, i, index) => {
      return (
        <FormItem name={`products[${index}].box_no`}>
          <Select
            showSearch
            name={`products[${index}].box_no`}
            onSearch={(v) => productSearch(v)}
            placeholder="Барааны хайрцаг"
            allowClear
            style={{ width: "200px" }}
          >
            {(searchBox || []).filter(a => a.box_no).map((item, index) => {
              return (
                <Option key={index} value={item.box_no}>{item.box_no} {item?.custom && "(Шинэ)"}</Option>
              );
            })}
          </Select>
        </FormItem>
      );
    }
  }, {
    title : "Үйлдэл",
    render: (record) => {
      return (
        <RowAction
          actions={{
            remove: "Устгах"
          }}
          onClick={(key) => onClick(key, record)}
        />
      );
    }
  }];
};

