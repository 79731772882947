import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Button, Modal, message as notify, Tag, Avatar, Space, Input } from "antd";
import { PlusOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { MyTable, RowAction } from "../../components";
import { user as userApi } from "../../apis";
import Form from "./Form";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import usePermission from "../../hooks/usePermission";

const COLORS = {
  POS     : "green",
  ARCHIVE : "blue",
  ORDER   : "orange",
  FINANCE : "red",
  PRODUCT : null,
  SETTINGS: null
};

export default () => {
  const myTableRef = React.useRef();
  const dispatch = useDispatch();
  const { checkPermission } = usePermission();
  const { roles, s3host, isMobile } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const columns = useHeader({
    COLORS,
    s3host,
    roles,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "edit" : {
          setAction(["update", {
            ...record,
            roles: record?.roles
          }]);
          break;
        }
        case "remove" : {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
            okText    : "Устгах",
            cancelText: "Болих",
            className : "btn-custom-class",
            onOk      : async () => {
              try {
                await userApi.remove(record.id);

                dispatch({
                  type   : "general.staff.remove",
                  payload: record.id
                });

                myTableRef.current.reload();
              } catch (error) {
                console.log(error); }
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction([]);
  };

  const onSubmit = async (data) => {
    let res;

    if (action && action[0] === "update") {
      res = await userApi.update({
        ...data,
        id: action[1].id
      });
    } else {
      res = await userApi.create(data);
    }

    dispatch({
      type   : "general.staff",
      payload: res
    });

    notify.success("Таны хүсэлт амжилттай!");
    myTableRef.current.reload();
    onCancel();
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Системийн хэрэглэгч"
        extra={[
          checkPermission("SETTINGS") && <Button key={0} shape={isMobile && "circle"} type="primary" icon={<PlusOutlined />} onClick={() => setAction(["create"])}>{!isMobile && "Хэрэглэгч нэмэх"}</Button>
        ]}
      />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ width: 250 }}
            onChange={e => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={userApi.list}
          rowKey={(record) => record.id} />
      </PageContent>

      <Modal
        title={action && action[0] === "create" ? "Хэрэглэгч нэмэх" : "Хэрэглэгч засах"}
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        {action && action[0] && <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />}
      </Modal>

    </PageContainer>
  );
};

const useHeader = ({ checkPermission, COLORS, s3host, onAction }) => {
  return [{
    title : "№",
    width : 10,
    render: (record) => {
      return record.rowIndex;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.avatar}`}/>;
    }
  }, {
    title : "Нэр",
    render: (record) => {
      return `${record.last_name} ${record.first_name}`;
    }
  }, {
    title : "Нэвтрэх нэр",
    render: (record) => {
      return `${record.username}`;
    }
  }, {
    title : "Хандах эрх",
    render: (record) => {
      return record?.roles?.map((role, index) => <Tag style={{ marginBottom: 5 }} key={index} color={COLORS[role]}>{role || "-"}</Tag>);
    }
  }, {
    title : "Имэйл хаяг",
    render: (record) => {
      return `${record.email}`;
    }
  }, {
    title : "Хаяг",
    render: (record) => {
      return record.address;
    }
  }, {
    title : "Утасны дугаар",
    render: (record) => {
      return <p>{record.phone}{record.phone_second && `, ${record.phone_second}`}</p>;
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record) => {
      return (
        <RowAction
          actions = {{
            edit  : checkPermission("SETTINGS") && "Засах",
            remove: checkPermission("SETTINGS") && "Устгах",
          }}
          onClick={(key) => onAction(key, record)} />
      );
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;