import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { SearchOutlined, CloseOutlined, SettingOutlined, } from "@ant-design/icons";
import { Button, Space, Input, Select, Modal, message as notify, Avatar, Tag } from "antd";
import { MyTable, RowAction } from "../../components";
import { product as productApi } from "../../apis";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { tugrug } from "../../utils";
import Section from "./Section";

const { Option } = Select;

export default () => {
  const myTableRef = React.useRef();
  const { archives, sections, s3host } = useSelector(state => state.general);
  const { user } = useSelector(state => state.auth);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query     : "",
    archive_id: user?.staff_archive?.id
  });

  const onCancel = async () => {
    setAction();
  };

  const onSubmit = async (data) => {
    if (action && action[0] === "update") {
      await productApi.update({
        ...data,
        id: action[1].id
      });
    } else {
      await productApi.create(data);
    }

    notify.success("Таны хүсэлт амжилттай!");
    myTableRef.current.reload();
    onCancel();
  };

  const columns = useHeader({
    filters,
    action,
    sections,
    setAction,
    s3host,
    onSection: async (record) => {
      setAction([action[0], action[1], action[2], true]);

      try {
        await productApi.section(record.id, {
          archive_id: filters.archive_id,
          section_id: action[2]
        });

        myTableRef.current.reload();
        notify.success("Таны хүсэлт амжилттай!");
      } catch (err) {
        notify.warning(err.message);
      }

      setAction();
    },
    onClick: (type, record) => {
      switch (type) {
        case "section" : {
          setAction(["section", record, record.section_id]);
          break;
        }
        default:
      }
    }
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Барааны жагсаалт" />
      <PageFilter>
        <Space>
          <Input
            className="item"
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ width: 250 }}
            onChange={e => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
          {user.role === "ADMIN" && (
            <Select className="item" placeholder="Агуулах" style={{ width: 140 }} onChange={value => setFilters({ ...filters, archive_id: value })} allowClear>
              {archives?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          )}
          {filters.archive_id && filters.archive_id !== "" && (
            <Select className="item" placeholder="Тасаг" style={{ width: 140 }} onChange={value => setFilters({ ...filters, section_id: value })} allowClear>
              {sections.filter(section => section.archive_id === filters.archive_id)?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          )}
          {filters.archive_id && filters.archive_id !== "" && (
            <Button className="item" type="default" onClick={() => setAction(["settings"])}><SettingOutlined /> Тасгийн тохиргоо</Button>
          )}
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          ref={myTableRef}
          columns={columns.filter(col => col)}
          filters={filters}
          loadData={productApi.list}
          rowKey={(record) => record.id} />
      </PageContent>
      <Modal
        title="Тасгийн тохиргоо"
        visible={action && action[0] === "settings"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Section onCancel={onCancel} action={action} filters={filters} onSubmit={onSubmit} />
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ sections, onSection, action, setAction, s3host, filters, onClick }) => {
  return [{
    title : "№",
    width : 10,
    render: (record) => {
      return record.rowIndex;
    }
  }, {
    title : "Зураг",
    render: record => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title : "Нэр",
    render: record => {
      return record.name;
    }
  }, {
    title : "Дотоод код",
    render: record => {
      return <a>{record.code}</a>;
    }
  }, {
    title : "Баар код",
    render: record => {
      return record.barcode || "-";
    }
  }, {
    title : "Үнэ",
    render: record => {
      return (
        <>
          {tugrug(record?.price)} <Tag>Б.Үнэ: {tugrug(record?.sale_price)}</Tag>
        </>
      );
    }
  }, {
    title : "Үлдэгдэл",
    sorter: true,
    key   : "quantity",
    render: record => {
      return record.quantity || 0;
    }
  }, filters?.archive_id && filters?.archive_id !== "" && {
    title : "Тасаг",
    render: record => {
      if (action && action[0] === "section" && action[1].id === record.id)
        return (
          <Select placeholder="Тасаг" style={{ width: "100%" }} value={action[2]} onChange={value => setAction([action[0], action[1], value])} allowClear>
            {sections.filter(section => section.archive_id === filters.archive_id)?.map((item, index) => {
              return <Option key={index} value={item.id}>{item.name}</Option>;
            })}
          </Select>
        );

      return record?.section?.name || "-";
    }
  }, filters?.archive_id && filters?.archive_id !== "" && {
    title : "Үйлдэл",
    align : "center",
    width : action && action[0] === "section" ? 180 : 100,
    render: (record) => {
      const checkSection = () => {
        if (!record.quantity || record.quantity === 0)
          return false;

        return "Тасаг";
      };

      if (action && action[0] === "section" && action[1].id === record.id)
        return (
          <>
            <Button className="button" style={{ marginRight: 10 }} loading={action[3]} onClick={() => onSection(record)} type="primary">Хадгалах</Button>
            <Button className="button" shape="circle" onClick={() => {
              setAction();
            }}><CloseOutlined /></Button>
          </>
        );

      return (
        <RowAction
          actions={{
            section: checkSection()
          }}
          onClick={(key) => onClick(key, record)}
        />
      );
    }
  }];
};

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 
  flex-direction: row; 

  @media only screen and (max-width: 502px) {  
    flex-direction: column;   
    .item { 
      width: 100% !important; 
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    } 
    .wrap {
      flex-direction: column;   
    }
  }
`;