import { useSelector } from "react-redux";

export default () => {
  const { user } = useSelector(state => state.auth);

  return {
    checkPermission: (code) => {
      if (user?.role === "ADMIN")
        return true;

      if (user?.roles?.indexOf(code) !== -1)
        return true;

      return false;
    }
  };
};