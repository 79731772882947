import React from "react";
import { SortableElement } from "react-sortable-hoc";
import placeholderImage from "./placeholder.png";
import PopoverHandle from "../PopoverHandle";
import DragHandle from "../DragHandle";
import styles from "./style.module.scss";
import usePermission from "../../../hooks/usePermission";

const SortableItem = props => {
  const { checkPermission } = usePermission();
  const { isActive, item, onSelect } = props;

  return (
    <div className={`${styles.stylizedItem} ${isActive ? styles.active : ""}`}>
      <div className={styles.catItem} onClick={() => onSelect(item)}>
        <div className={styles.cardImageContainer}>
          {item.image ? (
            <img src={`${item.image}`} width='100%' alt={item.name} />
          ) : (
            <img
              className={styles.placeholder}
              src={placeholderImage}
              height='100%'
              alt={item.name}
            />
          )}
        </div>
        <div className={styles.catContent}>
          <div className={styles.catTitle}>
            {item.name} {item.count ? `(${item.count})` : null}
          </div>
        </div>
      </div>
      <div className={styles.catAction}>
        {(checkPermission("CATEGORY", { write: true }) || checkPermission("CATEGORY", { delete: true })) && <PopoverHandle {...props} />}
        <DragHandle />
      </div>
    </div>
  );
};

export default SortableElement(SortableItem);
