import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Button, Avatar, Select, Form, Space, Input } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CustomTable } from "../../components";
import { counting as countingApi } from "../../apis";
import { tugrug } from "../../utils";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const { s3host, sections, isMobile } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    archive_id             : undefined,
    counting_product_status: "",
    query                  : ""
  });
  const [data, setFormData] = React.useState({
    note: undefined
  });

  const reload = React.useCallback(async (signal) => {
    let res = await countingApi.get(params.id, { signal });

    setFilters(state => ({ ...state, archive_id: res.archive_id }));
    setFormData({
      ...res,
      note: res.note || undefined
    });
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Тооллого хийх" extra={[
        <Button type="default" shape={isMobile && "circle"} icon={isMobile && <CloseOutlined />} onClick={() => history.push("/archive/counting")}>{!isMobile && "Болих"}</Button>,
      ]} />
      <PageFilter>
        <Space className="wrap">
          <Space className="item">
            <Input className="item" name="code" placeholder="Хайлт хийх ..." style={{ minWidth: 155 }} prefix={<SearchOutlined />} onChange={e => setQuery(e.target.value)} />
          </Space>
          <Space className="item">
            <Select className="item" placeholder="Тасаг" style={{ width: 140 }} onChange={value => setFilters({ ...filters, section_id: value })} allowClear>
              {sections.filter(section => section.archive_id === filters.archive_id)?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Space>
        </Space>
        <Space className="margin-top">
        </Space>
      </PageFilter>
      <PageContent>
        <Form layout="horizontal">
          <CountingTable
            ref={myTableRef}
            filters={filters}
            loadData={countingApi.product(params.id)}
            thead={() => (
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell" rowSpan={2}>№</th>
                  <th className="ant-table-cell" rowSpan={2}>Зураг</th>
                  <th className="ant-table-cell" rowSpan={2}>Нэр</th>
                  <th className="ant-table-cell" rowSpan={2}>Код / Баар код</th>
                  <th className="ant-table-cell" rowSpan={2}>Тасаг</th>
                  <th className="ant-table-cell" colSpan={2}>Үлдэгдэл</th>
                  <th className="ant-table-cell" colSpan={2}>Тоолсон</th>
                  <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
                </tr>
                <tr>
                  <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                  <th className="ant-table-cell" rowSpan={2}>Дүн</th>
                  <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                  <th className="ant-table-cell" rowSpan={2}>Дүн</th>
                </tr>
              </thead>
            )} tbody={(row, index) => {
              return (
                <tbody key={index} className="ant-table-tbody">
                  <tr style={{ background: row.stock === row.quantity ? "#fff":"#ffd7d6" }}>
                    <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                    <td className="ant-table-cell" rowSpan={2}>
                      <Avatar src={`${s3host}/${row.image}`} shape="square" />
                    </td>
                    <td className="ant-table-cell">{row.name || "-"}</td>
                    <td className="ant-table-cell">{row.code}</td>
                    <td className="ant-table-cell" rowSpan={2}>
                      {row.section?.name || "-"}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>{row.stock || "-"}</td>
                    <td className="ant-table-cell" rowSpan={2}>{tugrug(row.stock_amount)}</td>
                    <td className="ant-table-cell" rowSpan={2} width={140}>
                      {row.quantity}
                    </td>
                    <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                  </tr>
                  <tr style={{ background: row.stock === row.quantity ? "#fff":"#ffd7d6" }}>
                    <td className="ant-table-cell"><a>{row.category?.name || "-"}</a></td>
                    <td className="ant-table-cell">{row.barcode || "-"}</td>
                  </tr>
                </tbody>
              );
            }} />
        </Form>

        <Form layout="horizontal">
          <TableFooter {...formItemLayout}>
            <Form.Item label="Нийт дүн" required>
              <Input type="text" value={tugrug(data.stock_amount)} disabled />
            </Form.Item>
            <Form.Item label="Тоолсон дүн" required>
              <Input type="text" value={tugrug(data.total_amount)} disabled />
            </Form.Item>
            <Form.Item label="Дутсан дүн" required>
              <Input type="text" value={tugrug(data.debt_amount)} disabled />
            </Form.Item>
            <Form.Item label="Агуулах" required>
              <Input type="text" value={data.archive?.name} disabled />
            </Form.Item>
            <Form.Item label="Ажилтан" required>
              <Input type="text" value={`${data.staff_user?.last_name || "-"} ${data.staff_user?.first_name || "-"}`} disabled />
            </Form.Item>
            <Form.Item label="Тэмдэглэл" required>
              <TextArea value={data.note} placeholder="Тэмдэглэл" disabled />
            </Form.Item>
          </TableFooter>
        </Form>
      </PageContent>
    </PageContainer>
  );
};
const CountingTable = styled(CustomTable)`
  .text-center {
    text-align: center;
  }
  .ant-table-cell {
    padding: 8px;
  }
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
const TableFooter = styled(Form)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;
const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 

  @media only screen and (max-width: 682px) { 
    .wrap { 
      display: flex;
      flex-direction: column; 
    }
    .ant-space-item {
      width: 100%;
    }
    .item { 
      width: 100% !important; 
    }
  }
  @media only screen and (max-width: 420px) { 
    flex-direction: row; 
    flex-direction: column;  
    .margin-top {
      margin-top: 10px;
    }
    .item { 
      width: 100% !important; 
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
    } 
  }
  
  @media only screen and (max-width: 1162px) {  
    flex-direction: column;   
    .margin-top {
      margin-top: 8px;
    }
  }
`;