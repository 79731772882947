import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { product as productApi } from "../../apis";
import { notification, Spin } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import Form from "./Form";
import { useSelector } from "react-redux";

export default () => {
  const params = useParams();
  const { categories } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);

  const cascader = ({ cascaders, child }) => {
    if (child?.parent_id) {
      let parent = categories.find(c => c.id === child.parent_id);

      if (parent)
        return cascader({ cascaders: [parent, ...cascaders], child: parent });

      return cascaders;
    }

    return cascaders;
  };

  const reload = React.useCallback(async (signal) => {
    let res = await productApi.get(params.id, { signal });
    let child = categories.find(c => c.id === res.category_id);

    setAction(["update", {
      ...res,
      price     : parseFloat(res.price),
      categories: cascader({ cascaders: [child], child })?.map(c => c?.id)
    }]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Бараа засах" />
      <PageContent>
        <Spin spinning={!action[1]}>
          {action[1] && <Form action={action} />}
        </Spin>
      </PageContent>
    </PageContainer>
  );
};