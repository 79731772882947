import React from "react";
import { Formik, useFormikContext } from "formik";
import { Form, FormItem, Input as FormikInput } from "formik-antd";
import { PageContainer, PageHeader, PageContent } from "../../../components/Layout";
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Form as AntForm, Button, DatePicker, Input, Tag, Modal, Spin, message as notify, Drawer, message } from "antd";
import { CustomTable, RowAction, DrawerRangePicker, ProductItem } from "../../../components";
import { useHistory, useParams } from "react-router-dom";
import { order as orderApi, calculation as calculationApi } from "../../../apis";
import { tugrug, formItemLayout, tailFormItemLayout } from "../../../utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import * as Yup from "yup";
import RefundForm from "./Form";

const { RangePicker } = DatePicker;
const { TextArea } = FormikInput;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setFieldError } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return { values, setFieldError };
    }
  }));

  return null;
});

const footerLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

const FormLineSchema = () => Yup.array().of(Yup.object({
  name  : Yup.string().required("Заавал бөглөнө!"),
  amount: Yup.number().required("Заавал бөглөнө!"),
  note  : Yup.string().required("Заавал бөглөнө!")
}));

const FormSchema = (type) => () => Yup.object().shape({
  note: Yup.string().required("Заавал бөглөнө!"),
  ...(type === "canceled" ? {} : {
    debt_lines: FormLineSchema()
  })
});

export default () => {
  const myTableRef = React.useRef();
  const submitRef = React.useRef();
  const history = useHistory();
  const params = useParams();
  const { isMobile } = useSelector(state => state.general);
  const [action, setAction] = React.useState();
  const [data, setFormData] = React.useState();
  const [totals, setTotals] = React.useState({});
  const [filters, setFilters] = React.useState({
    calculation_id: params.id
  });

  const onAction = async (type, item) => {
    switch (type) {
      case "cancel": {
        setAction(["canceled", item]);
        break;
      }

      case "edit": {
        setAction(["update", {
          id                     : item.id,
          type                   : item.type,
          shipping_amount        : item.shipping_amount || undefined,
          shipping_additional_fee: item.shipping_additional_fee || undefined,
          shipping_phone         : item.shipping_phone || undefined,
          shipping_phone_second  : item.shipping_phone_second || undefined,
          shipping_address       : item.shipping_address || undefined,
          shipping_date          : item.shipping_date || undefined,
          paid_amount            : item.paid_amount || undefined,
          order_date             : item.order_date,
          is_sale_price          : item.is_sale_price,
          surcharge_amount       : item.surcharge_amount || undefined,
          discount_amount        : item.discount_amount || undefined,
          card_amount            : parseInt(item.card_amount, 10) || 0,
          account_amount         : parseInt(item.account_amount, 10) || 0,
          cash_amount            : parseInt(item.cash_amount, 10) || 0,
          note                   : item.note || undefined,
          products               : item.products.map(p => ({
            product_id     : p?.product_id,
            price          : p?.price,
            sale_price     : p?.product?.sale_price,
            quantity       : p?.quantity,
            box_no         : p?.box_no,
            refund_quantity: p?.refund_quantity
          }))
        }, item.products.map(p => {
          return {
            box_no: p.box_no,
            ...p.product
          };
        })]);
        break;
      }
      case "refund": {
        history.push(`/calculation/refund/${item.id}`);
        break;
      }
      case "canceled": {
        await orderApi.cancel(action[1]?.id, item);
        setAction();
        reload();
        break;
      }
      default:
    }
  };

  const orderType = (item) => {
    switch (item.type) {
      case "REFUND": {
        return <Tag>{"Буцаалт"}</Tag>;
      }
      case "PURCHASE": {
        return <Tag>{"Борлуулалт"}</Tag>;
      }

      default:
        return <Tag>-</Tag>;
    }
  };

  const orderStatus = (item) => {
    switch (item.order_status) {
      case "COMPLETED": {
        return <Tag color='green'>{"Амжилттай"}</Tag>;
      }
      case "NEW": {
        return <Tag>{"Үүсгэсэн"}</Tag>;
      }

      default:
        return <Tag>-</Tag>;
    }
  };

  const checkCancel = (item) => {
    if (item?.order_status === "CANCELED")
      return false;

    return "Цуцлах";
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onCancel = (loaded) => {
    if (loaded) {
      const abortController = new AbortController();
      const signal = abortController.signal;

      reload(signal);
    }

    setAction();
  };

  const onSave = async () => {
    let valid = await submitRef.current.submitForm();
    if (!valid) return notify.warning("Тооцооны мэдээлэл дутуу байна!");

    const { values } = valid;

    Modal.confirm({
      title  : "Баталгаажуулах",
      icon   : <ExclamationCircleOutlined />,
      content: "Тооцоог дуусгахдаа итгэлтэй байна уу!",
      onOk   : async () => {
        await calculationApi.finish(data.id, {
          start_date: filters.start_date,
          end_date  : filters.end_date,
          note      : values.note
        });

        notify.success("Таны хүсэлт амжилттай!");
        history.push("/calculation/init");
      }
    });
  };

  const reload = React.useCallback(async (signal) => {
    let res = await calculationApi.get(params.calculation_id || params.id, { signal });

    setFilters(state => ({
      ...state,
      archive_id: res.archive_id
    }));

    setFormData(state => ({
      ...state,
      ...res,
      note: res.note || undefined
    }));
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Тооцоо хийх" extra={[
        <RangePicker key="1" className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear />,
        <Button key="2" icon={isMobile && <CloseOutlined />} shape={isMobile && "circle"} type="default" onClick={() => history.push("/calculation/init")}>{!isMobile && "Болих"}</Button>,
        // <Button key="3" icon={isMobile && <SaveOutlined />} shape={isMobile && "circle"} type="primary" onClick={() => onSave()}>{!isMobile && "Тооцоо дуусгах"}</Button>
      ]} />
      <PageContent>
        {data ? (() => {
          return (
            <InoutTable
              ref={myTableRef}
              filters={filters}
              loadData={orderApi.list("calculation")}
              onResult={({ totals }) => setTotals(totals || {})}
              rowKey={(record) => record.id}
              thead={() => (
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" rowSpan={2}>№</th>
                    <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                    <th className="ant-table-cell" rowSpan={2}>Тайлбар</th>
                    <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                    <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                    <th className="ant-table-cell" rowSpan={2}>Буцаагдсан хүсэлтүүд</th>
                    <th className="ant-table-cell" colSpan={4}>Мөнгөн дүн</th>
                    <th className="ant-table-cell" rowSpan={2}>Төрөл</th>
                    <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                    <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                    <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
                  </tr>
                  <tr>
                    <th className="ant-table-cell">Нийт дүн</th>
                    <th className="ant-table-cell">Картаар</th>
                    <th className="ant-table-cell">Бэлнээр</th>
                    <th className="ant-table-cell">Дансаар</th>
                  </tr>
                </thead>
              )} tbody={(row, index) => {
                return (
                  <tbody key={index} className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                      <td className="ant-table-cell" rowSpan={2}><Tag color={row?.type === "PURCHASE" ? "blue" : "warning"}>{row.code}</Tag></td>
                      <td className="ant-table-cell" rowSpan={2}>{row?.note || "-"}</td>
                      <td className="ant-table-cell"><Tag>{row?.archive?.name || "-"}</Tag></td>
                      <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                      <td className="ant-table-cell" rowSpan={2}>{row.refund_request || 0}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.card_amount)}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.cash_amount)}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.account_amount)}</td>
                      <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderType(row)}</td>
                      <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderStatus(row)}</td>
                      <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.order_date).format("YYYY.MM.DD HH:mm")}</td>
                      <td className="ant-table-cell" rowSpan={2}>
                        <RowAction
                          icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined />}
                          actions={{
                            edit  : "Буцаалт хийх",
                            refund: "Буцаалтын хүсэлт",
                            // cancel: checkCancel(row)
                          }} onClick={(key) => onAction(key, row)} />
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"><a>{`${row?.user?.first_name || "-"} ${row?.user?.last_name || "-"}`}</a></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="ant-table-cell" colSpan={15}>
                        {row.products.map((item, index) => <ProductItem key={index} image={item?.product?.image} name={item?.product?.name} quantity={item.quantity} code={item?.product?.code} boxNo={item?.box_no}/>)}
                      </td>
                    </tr>
                  </tbody>
                );
              }} />
          );
        })() : <Spin style={{ width: "100%" }} loading={!data} />}
      </PageContent>

      {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}

      {data && (
        <CalculationLines>
          <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={FormSchema()}
            onSubmit={() => {}}>
            {() => {
              return (
                <Form layout="horizontal">
                  <PageContent>
                    <CalculationFooter {...footerLayout}>
                      <FormItem name="quantity" label="Захиалгын тоо">
                        <Input type="text" value={totals.order_quantity} disabled />
                      </FormItem>
                      <FormItem name="quantity" label="Барааны тоо">
                        <Input type="text" value={totals.quantity} disabled />
                      </FormItem>
                      <FormItem name="total_amount" label="Нийт дүн">
                        <Input type="text" value={tugrug(totals.total_amount)} disabled />
                      </FormItem>
                      <FormItem name="archive_id" label="Агуулах" required>
                        <Input type="text" value={data.archive?.name} disabled />
                      </FormItem>
                      <FormItem name="staff_user_id" label="Ажилтан" required>
                        <Input type="text" value={`${data.staff_user?.last_name || "-"} ${data.staff_user?.first_name || "-"}`} disabled />
                      </FormItem>
                      <FormItem name="note" label="Тэмдэглэл" required>
                        <TextArea name="note" placeholder="Тэмдэглэл"/>
                      </FormItem>
                    </CalculationFooter>

                    <SubmitForm ref={submitRef} />
                  </PageContent>
                </Form>
              );
            }}
          </Formik>
        </CalculationLines>
      )}

      <Drawer
        title="Буцаалт батлах"
        placement="right"
        onClose={() => setAction([])}
        width="70%"
        visible={action && action[0] === "update"} >
        {
          action && action[0] === "update" && <RefundForm action={action} products={action[2]} onCancel={onCancel} />
        }
      </Drawer>

      <Modal
        title="Баталгаажуулах"
        visible={action && action[0] === "canceled"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Formik
          enableReinitialize
          initialValues={{
            note: undefined
          }}
          validationSchema={FormSchema("canceled")}
          onSubmit={(data) => onAction("canceled", data)}>
          {({ isSubmitting }) => {
            return (
              <Form {...formItemLayout}>
                <Canceled>
                  <div className="alert">
                    <ExclamationCircleOutlined /> Та борлуулалтыг цуцлахдаа итгэлтэй байна уу?
                  </div>
                </Canceled>
                <FormItem name="note" label="Нэмэлт мэдээлэл" required>
                  <TextArea name="note" placeholder="Нэмэлт мэдээлэл" />
                </FormItem>
                <AntForm.Item {...tailFormItemLayout}>
                  <Button className="button" style={{ marginRight: 10 }} loading={isSubmitting} htmlType="submit" type="primary">Болсон</Button>
                  <Button className="button" type="default" onClick={onCancel}>Болих</Button>
                </AntForm.Item>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </PageContainer>
  );
};
const CalculationLines = styled.div``;
const CalculationFooter = styled(AntForm)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;
const Canceled = styled.div`
  .alert {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    svg {
      display: flex;
      font-size: 22px;
      color: #faad14;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
`;

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;