import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { CloseOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Form as AntForm, Input, Button, Tag, Modal, message } from "antd";
import { MyTable } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { debt as debtApi } from "../../apis";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import { Form, FormItem, Input as FormikInput } from "formik-antd";
import styled from "styled-components";
import moment from "moment";
import usePermission from "../../hooks/usePermission";
import * as Yup from "yup";

const FormSchema = () => Yup.object().shape({
  code: Yup.string().required("Заавал бөглөнө!"),
});

const { TextArea } = Input;

const footerLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const params = useParams();
  const { isMobile } = useSelector(state => state.general);
  const { checkPermission } = usePermission();
  const [data, setFormData] = React.useState();
  const [action, setAction] = React.useState();

  const reload = React.useCallback(async (signal) => {
    let res = await debtApi.get(params.id, { signal });
    setFormData(res);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  const onCancel = () => {
    setAction();
  };

  const onSubmit = async (data) => {
    // await transactionApi.pair({
    //   code        : data.code,
    //   debt_line_id: action[1]?.id
    // });

    // message.success("Таны хүсэлт амжилттай!");
    // setAction();

    reload();
  };

  return (
    <PageContainer>
      <PageHeader title="Төлбөр тооцоо" extra={[
        <Button icon={isMobile && <CloseOutlined />} shape={isMobile && "circle"} type="default" onClick={() => history.push("/finance/debt")}>{!isMobile && "Болих"}</Button>
      ]} />
      <PageContent>
        {data && (
          <CalculationLines>
            {(() => {
              const columns = useHeader({
                debt_lines: data.debt_lines,
                checkPermission,
                onClick   : (type, row) => {
                  switch (type) {
                    case "pair": {
                      setAction(["transaction", row]);
                      break;
                    }
                    case "unpair": {
                      Modal.confirm({
                        title     : "Баталгаажуулах",
                        icon      : <ExclamationCircleOutlined />,
                        content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
                        okText    : "Устгах",
                        cancelText: "Болих",
                        className : "btn-custom-class",
                        onOk      : async () => {
                          try {
                            // await transactionApi.unpair({
                            //   code        : row.code,
                            //   debt_line_id: row.id
                            // });

                            message.success("Таны хүсэлт амжилттай!");
                            reload();
                          } catch (error) {
                            console.log(error); }
                        },
                      });
                      break;
                    }
                    default:
                  }
                }
              });

              return (
                <AntForm layout="horizontal">
                  <CalculationLineTable
                    ref={myTableRef}
                    items={data.debt_lines}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.id} />

                  <CalculationFooter {...footerLayout}>
                    <AntForm.Item label="Нийт дүн">
                      <Input type="text" value={tugrug(data.total_amount)} disabled />
                    </AntForm.Item>
                    <AntForm.Item label="Агуулах" required>
                      <Input type="text" value={data.archive?.name} disabled />
                    </AntForm.Item>
                    <AntForm.Item label="Ажилтан" required>
                      <Input type="text" value={`${data.staff_user?.last_name || "-"} ${data.staff_user?.first_name || "-"}`} disabled />
                    </AntForm.Item>
                    <AntForm.Item label="Тэмдэглэл" required>
                      <TextArea name="note" value={data.note} placeholder="Тэмдэглэл"/>
                    </AntForm.Item>
                  </CalculationFooter>
                </AntForm>
              );
            })()}
          </CalculationLines>
        )}
      </PageContent>

      <Modal
        title="Гүйлгээ"
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        {action && action[0] && (
          <Formik
            enableReinitialize
            initialValues={{
              code: undefined
            }}
            validationSchema={FormSchema}
            onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <Form {...formItemLayout}>
                <FormItem name="code" label="Гүйлгээний код" required>
                  <FormikInput name="code" placeholder="Гүйлгээний код" />
                </FormItem>
                <AntForm.Item {...tailFormItemLayout}>
                  <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                  <Button className="button" type="default" onClick={onCancel}>Болих</Button>
                </AntForm.Item>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </PageContainer>
  );
};
const useHeader = ({ onClick }) => {
  return [{
    title : "№",
    width : 10,
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Нэр",
    render: (record, i, index) => {
      return record.name;
    }
  }, {
    title : "Төрөл",
    width : 140,
    render: record => {
      return record?.type === "DEBIT" ? <Tag color="green">ОРЛОГО</Tag> : <Tag color="red">ЗАРЛАГА</Tag>;
    }
  }, {
    title : "Огноо",
    width : 200,
    render: (record, i, index) => {
      return moment(record.created_at).format("YYYY-MM-DD HH:mm");
    }
  }, {
    title : "Дүн",
    width : 200,
    render: (record, i, index) => {
      return tugrug(record.amount);
    }
  }, {
    title : "Төлөв",
    width : 140,
    render: record => {
      if (record.type === "CREDIT")
        return <Tag color="blue">Тооцсон</Tag>;
      return record?.debt_line_status === "PAID" ? <Tag color="green">Төлөгдсөн</Tag> : <Tag color="red">Төлөгдөөгүй</Tag>;
    }
  }, {
    title : "Гүйлгээ",
    width : 300,
    render: (record, i, index) => {
      return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div>
            {record.transactions?.map(trx => {
              return <Tag color='red' style={{ marginBottom: 5 }}>{trx.code} ({tugrug(trx.amount)}) <CloseOutlined onClick={() => onClick("unpair", {
                id  : record.id,
                code: trx.code
              })}/></Tag>;
            })}
            {record.transactions?.length === 0 && "-"}
          </div>
          {record.type === "DEBIT" && record?.debt_line_status !== "PAID" && <Button type="default" size="small" onClick={() => onClick("pair", record)}><PlusOutlined /></Button>}
        </div>
      );
    }
  }, {
    title : "Тайлбар",
    width : 300,
    render: (record, i, index) => {
      return record.note;
    }
  }];
};
const CalculationLines = styled.div``;
const CalculationFooter = styled(AntForm)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;
const CalculationLineTable = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
  }
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;