/* eslint-disable radix */
import React, { useState } from "react";
import { Upload, message } from "antd";
import { Field } from "formik";
import styles from "./style.module.scss";
import { connect, useSelector } from "react-redux";
import { PlusOutlined, LoadingOutlined, CloudUploadOutlined } from "@ant-design/icons";

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp" ||
    file.type === "image/svg+xml";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt2M;
}

const UploadComponent = ({ name, auth, general, action, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const { token } = auth;
  const { s3host } = useSelector((state) => state.general);

  const handleChange = (
    info,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    setFieldTouched(name, true);

    if (info.file.status === "uploading") {
      setLoading(true);
      setFieldError(name, "Зураг хуулж байна!");
    }

    if (info.file.status === "done") {
      setFieldValue(name, info.file.response);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading === false ? <PlusOutlined /> : <LoadingOutlined />}
      <div className="ant-upload-text">
        {loading ? "Хуулж байна" : "Хуулах"}
      </div>
    </div>
  );

  const [fileList, setFileList] = useState([
    {
      uid   : "-1",
      name  : "image.png",
      status: "done",
      url   : "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const [progress, setPercent] = React.useState(0);

  return (
    <Field name={name}>
      {(fieldProps) => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError },
        } = fieldProps;

        const props = {
          name   : "file",
          action : "/api/general/upload",
          headers: {
            authorization: `Bearer ${token}`,
          },
          onChange(info) {

            if (info.file.status !== "uploading") {
              setLoading(false);
              setFieldError(name, "Зураг хуулж байна!");
            }
            if (info.file.status === "done") {
              setFieldValue(name, info.file.response);
              message.success(`${info.file.name} Зураг хуулж дууслаа.`);
              setLoading(true);

            } else if (info.file.status === "error") {
              message.error(`${info.file.name} Зураг хуулж чадсангүй.`);
              setLoading(true);
            }
          },
          listType      : "picture-card",
          showUploadList: false,
          maxCount      : 1,
          progress      : {
            strokeColor: {
              "0%"  : "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format     : (percent) => {
              setPercent(percent);
              let b = parseInt(percent);
              return `${b}%`;
            },
          },
        };

        return (
          <>
            <Upload {...props} {...restProps}>
              {/* {fileList.length < 5 && "+ Upload"} */}
              {/* {value} */}
              {fileList.length < 5 && value && !loading ? (
                <img
                  src={`http://dev-erp-emall.goodtech.mn/${value}`}
                  alt="avatar"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <div style={{ color: "#000" }}>
                  <CloudUploadOutlined /> Зураг оруулах
                </div>
              )}
            </Upload>
          </>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth, general }) => ({
  auth,
  general,
});

export default connect(mapStateToProps)(UploadComponent);
