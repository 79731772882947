import React from "react";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Field } from "formik";

const wrapper = ({ name, location }) => {
  if (name)
    return (
      <Field name={name}>
        {fieldProps => <FieldComponent name={name} {...fieldProps} />}
      </Field>
    );

  let defaultCenter = { lat: 47.918, lng: 106.917 },
    markerPosition;

  if (location && location.length === 2) {
    markerPosition = { lat: location[0], lng: location[1] };
    defaultCenter = markerPosition;
  }

  return <GoogleMap
    defaultZoom={15}
    defaultCenter={defaultCenter}>
    {markerPosition && <Marker position={markerPosition} />}
  </GoogleMap>;
};

const FieldComponent = props => {
  const { field, form, name } = props;
  const { value = [] } = field;
  const { setFieldValue } = form;

  const memoLatLng = React.useMemo(() => ({
    lat: value[0],
    lng: value[1]
  }), [value[0], value[1]]);

  const [lat, setLat] = React.useState("47.918");
  const [lng, setLng] = React.useState("106.917");

  let googleMapRef = React.useRef(null);

  const [, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "init": {
        if (googleMapRef) {
          googleMapRef.current.panTo(action.payload);
        }

        return state;
      }

      default:
        return state;
    }
  }, {});

  React.useEffect(() => {
    if (memoLatLng && memoLatLng.lat && memoLatLng.lng) {
      setLat(memoLatLng.lat);
      setLng(memoLatLng.lng);
      dispatch({
        type   : "init",
        payload: {
          lat: memoLatLng.lat,
          lng: memoLatLng.lng
        }
      });
    }
  }, [memoLatLng]);

  const handleMapClick = e => {
    const closureLat = e.latLng.lat();
    const closureLng = e.latLng.lng();
    e.isMarkerShown = true;

    setLat(closureLat);
    setLng(closureLng);

    console.log(closureLat+ "  s: "+closureLng);


    setFieldValue(name, [closureLat, closureLng]);
  };

  return (
    <GoogleMap
      onClick={handleMapClick}
      defaultZoom={15}
      ref={googleMapRef}
      isMarkerShown={false}
      defaultCenter={{ lat: 47.918, lng: 106.917 }}>
      {lat && lng ? <Marker position={{ lat, lng }} /> : null}
    </GoogleMap>
  );
};

export default compose(withProps({
  googleMapURL    : `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
  loadingElement  : <div style={{ height: "100%" }} />,
  containerElement: <div style={{ height: "300px" }} />,
  mapElement      : <div style={{ height: "100%" }} />
}), withScriptjs, withGoogleMap)(wrapper);