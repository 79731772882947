import React from "react";
import { tugrug } from "../../../utils";
import { Button } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";

export default ({ onAddCart, product, filters }) => {
  const { s3host } = useSelector(state => state.general);

  return (
    <Container>
      <div className="thumb">
        <img src={`${s3host}/${product.image}`} alt={product.name} />
      </div>
      <div className="section">
        <div className="price">{tugrug(product.price)} ({product.quantity || 0})</div>
        <span className="quantity">{"#"+product.code}</span>
        <div className="name">{product.name}</div>
        {filters?.filter?.archive_id && <Button className="add-cart" size="middle" onClick={() => onAddCart(product)} block>Сагсанд нэмэх</Button>}
      </div>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  padding-right: .5rem;
  padding-left: .5rem;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  .add-cart {
    background: #096cda;
    border-color: #0255b7;
    color: #fff;
    height: 35px;
    border-radius: 4px;
    font-size: 13px;
  }
  .price {
    font-size: 18px;
    letter-spacing: -0.5px;
    color: #222;
    font-family: Roboto-Medium, sans-serif;
    vertical-align: middle;
  }
  .thumb {
    display: inline-block;
    position: relative;
    width: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden; 
    &:after {
      content: "";
      padding-top: 100%;
      display: block;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      border-radius: 3px;
    }
  }
  .name {
    color: #333;
    font-weight: 500;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;