import React from "react";
import { useParams } from "react-router-dom";
import { calculation as calculationApi } from "../../apis";
import { Tag } from "antd";
import { PageContainer, PageHeader } from "../../components/Layout";
import { CustomTable } from "../../components";
import { tugrug } from "../../utils";
import styled from "styled-components";
import moment from "moment";

export default () => {

  const myTableRef = React.useRef();
  const params = useParams();
  const [t, setT] = React.useState([]);
  const [filters] = React.useState({});

  const reload = React.useCallback(async (signal) => {
    let res = await calculationApi.refundGet(params.id, { signal });
    setT(res);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  const orderType = (row) => {
    switch (row.type) {
      case "REFUND":
        return <Tag style={{ float: "right" }}>Буцаалт</Tag>;
      case "PURCHASE":
        return <Tag style={{ float: "right" }}>Борлуулалт</Tag>;
      default:
        return <Tag style={{ float: "right" }}>-</Tag>;
    }
  };

  const orderStatus = (row) => {
    switch (row.order_status) {
      case "NEW":
        return <Tag style={{ float: "right" }}>Үүсгэсэн</Tag>;
      case "COMPLETED":
        return <Tag style={{ float: "right" }} color="green">Амжилттай</Tag>;
      default:
        return <Tag style={{ float: "right" }}>-</Tag>;
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Буцаалтын жагсаалт" />

      <InoutTable
        loadData={() => {}}
        ref={myTableRef}
        filters={filters}
        rows={t}
        pagination={false}
        rowKey={(record) => record.id}
        thead={() => (
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
              <th className="ant-table-cell" rowSpan={2}>Тайлбар</th>
              <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
              <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
              <th className="ant-table-cell" colSpan={4}>Төлбөр</th>
              <th className="ant-table-cell" rowSpan={2}>Төрөл</th>
              <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
              <th className="ant-table-cell" rowSpan={2}>Огноо</th>
            </tr>
            <tr>
              <th className="ant-table-cell">Нийт дүн</th>
              <th className="ant-table-cell">Картаар</th>
              <th className="ant-table-cell">Бэлнээр</th>
              <th className="ant-table-cell">Дансаар</th>
            </tr>
          </thead>
        )} tbody={(row, index) => {
          return (
            <tbody key={index} className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" rowSpan={2}><Tag color={row?.type === "PURCHASE" ? "blue" : "warning"}>{row.code}</Tag></td>
                <td className="ant-table-cell" rowSpan={2}>{row?.note || "-"}</td>
                <td className="ant-table-cell"><Tag>{row?.archive?.name || "-"}</Tag></td>
                <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                <td className="ant-table-cell" rowSpan={2}>{tugrug(parseFloat(row.total_amount) + parseFloat(row.shipping_amount))}</td>
                <td className="ant-table-cell" rowSpan={2}>{tugrug(row.card_amount)}</td>
                <td className="ant-table-cell" rowSpan={2}>{tugrug(row.cash_amount)}</td>
                <td className="ant-table-cell" rowSpan={2}>{tugrug(row.account_amount)}</td>
                <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderType(row)}</td>
                <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderStatus(row)}</td>
                <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.order_date).format("YYYY.MM.DD HH:mm")}</td>
              </tr>
            </tbody>
          );
        }}
      />
    </PageContainer>
  );
};

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;