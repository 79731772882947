import React from "react";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { order as orderApi } from "../../apis";
import Form from "./Form";

const DamageEditComponent = () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const reload = React.useCallback(async (signal) => {
    let res = await orderApi.get(params.id, { signal });

    setProducts(res.products);
    setAction(["update", {
      id              : res.id,
      note            : res.note || undefined,
      customer_id     : res.customer_id,
      cash_amount     : res.cash_amount,
      card_amount     : res.card_amount,
      account_amount  : res.account_amount,
      surcharge_amount: res.surcharge_amount,
      discount_amount : res.discount_amount,
      is_sale_price   : res.is_sale_price,
      order_date      : res.order_date,
      products        : res.products.map(p => ({
        product_id: p.id,
        price     : p.price,
        sale_price: p.sale_price,
        quantity  : p.quantity,
        box_no    : p.box_no
      }))
    }]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <Spin spinning={!action[1]}>
      {action[1] && <Form action={action} products={products} />}
    </Spin>
  );

};

export default DamageEditComponent;