import React from "react";
import { Button } from "antd";
import { SortableContainer } from "react-sortable-hoc";

import styles from "./style.module.scss";
import SortableItem from "./SortableItem";
import usePermission from "../../hooks/usePermission";

const CategoryList = props => {
  const { checkPermission } = usePermission();
  const { onNew, onSelect, onEdit, onRemove, items, selected, index, ...rest } = props;

  return (
    <div>
      {checkPermission("CATEGORY", { write: true }) && (
        <div className={styles.action}>
          <Button onClick={onNew}>
          Нэмэх
          </Button>
        </div>
      )}
      <div className={styles.stylizedList}>
        {items&&items.map((item, index) => {
          return (
            <SortableItem
              key={index}
              index={index}
              onSelect={item => {
                onSelect(item);
              }}
              onEdit={() => onEdit(item)}
              onRemove={() => onRemove(item)}
              item={item}
              isActive={selected && selected.id === item.id}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SortableContainer(CategoryList);
