import request from "../utils/request";

export const search = (data, options) => request.get("/api/product/search", data, options);
export const get = (id, options) => request.get(`/api/product/${id}`, {}, options);
export const list = (data, options) => request.get("/api/product", data, options);
export const create = (data) => request.post("/api/product", data);
export const update = (data) => request.put(`/api/product/${data.id}`, data);
export const remove = (id) => request.del(`/api/product/${id}`);
export const section = (id, data) => request.put(`/api/product/${id}/section`, data);

