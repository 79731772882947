import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Button, Modal, message as notify, Space, Input, Tag } from "antd";
import { PlusOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { CustomTable, RowAction, ProductItem } from "../../components";
import { order as orderApi } from "../../apis";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { tugrug } from "../../utils";
import moment from "moment";

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const { cities, districts, s3host } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const [loading, setLoading] = React.useState({});
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const onExport = async (row) => {
    try {
      setLoading(true);
      let res = await orderApi.fileExport(row.id);

      window.open(
        s3host + "/files/" + res.url,
        "_blank"
      );

      window.open(
        s3host + res.url,
        "_blank"
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };


  const onAction = (key, record) => {
    switch (key) {
      case "edit" : {
        history.push(`/order/edit/${record.id}`);
        break;
      }
      case "remove" : {
        Modal.confirm({
          title     : "Баталгаажуулах",
          icon      : <ExclamationCircleOutlined />,
          content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
          okText    : "Устгах",
          cancelText: "Болих",
          className : "btn-custom-class",
          onOk      : async () => {
            try {
              await orderApi.remove(record.id);
              myTableRef.current.reload();
            } catch (error) {
              console.log(error);
            }
          },
        });
        break;
      }
      case "export": {
        onExport(record);
        break;
      }
      default:
    }
  };

  const orderStatus = (row) => {
    switch (row.order_status) {
      case "NEW":
        return <Tag style={{ float: "right" }}>Шинэ</Tag>;
      case "COMPLETED":
        return <Tag style={{ float: "right" }} color="green">Захиалсан</Tag>;
      default:
        return <Tag style={{ float: "right" }}>Захиалга</Tag>;
    }
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Захиалга (Харилцагч)"
      />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ width: 250 }}
            onChange={e => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <InoutTable
          ref={myTableRef}
          filters={filters}
          loadData={orderApi.list("order")}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                <th className="ant-table-cell" rowSpan={2}>Харилцагч</th>
                <th className="ant-table-cell" rowSpan={2}>Хаяг</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Ажилтан</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell" rowSpan={2}><Tag color={row?.type === "PURCHASE" ? "blue" : "warning"}>{row.code}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <a>{row?.customer?.type === "CITIZEN" ? `${row?.customer?.last_name || "-"} ${row?.customer?.first_name || "-"}` : `${row?.customer?.name || "Зочин"}`}</a>
                    <p>{row?.shipping_phone} {row?.shipping_phone_second}</p>
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <p>
                      <div>{row?.shipping_address || "-"}</div>
                      {row?.customer && <Tag style={{ margin: "5px 5px 0 0" }}>{cities.find(c => c.code === row?.customer?.city)?.name || "-"}</Tag>}
                      {row?.customer && <Tag style={{ margin: "5px 5px 0 0" }}>{districts.find(d => d.code === row?.customer?.district)?.name || "-"}</Tag>}
                    </p>
                  </td>
                  <td className="ant-table-cell">{row.quantity || 0}</td>
                  <td className="ant-table-cell" width="300">
                    <a>{`${row?.user?.first_name || "-"} ${row?.user?.last_name || "-"}`}</a>
                    {orderStatus(row)}
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        edit  : "Засварлах",
                        export: loading[row.id] ? "Loading..." : "Excel файл",
                        remove: "Устгах"
                      }} onClick={(key) => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell">
                    {tugrug(parseFloat(row.total_amount) + parseFloat(row.shipping_amount))} {row.shipping_additional_fee > 0 && `+${tugrug(row.shipping_additional_fee)}`}
                  </td>
                  <td className="ant-table-cell" width={140}>{moment(row.created_at).format("YYYY.MM.DD HH:mm")}</td>
                </tr>
                <tr>
                  <td></td>
                  <td className="ant-table-cell" colSpan={15}>
                    {row.products.map((item, index) => <ProductItem key={index} image={item?.product?.image} name={item?.product?.name} quantity={item.quantity} code={item?.product?.code} />)}
                  </td>
                </tr>
              </tbody>
            );
          }} />
      </PageContent>
    </PageContainer>
  );
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

