import React from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Modal } from "antd";
import Product from "../Product";

export default () => {
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const [visible, setVisible] = React.useState(params._id || null);
  const onCancel = () => {
    delete params._id;
    history.push({
      search: "?" + queryString.stringify(params)
    });
    setVisible(null);
  };

  React.useEffect(() => {
    return history.listen((location) => {
      const params = queryString.parse(location.search);
      setVisible(params._id);
    });
  }, [history]);

  return (
    <Modal
      className="emall-product-modal"
      footer={null}
      onCancel={() => onCancel()}
      visible={!!visible}>
      {visible && <Product _id={visible} />}
    </Modal>
  );
};