import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, Row, Col, message as notify } from "antd";
import { Form, FormItem, Input, Select } from "formik-antd";
import { inout as inoutApi } from "../../../apis";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";

const FormSchema = () => Yup.object().shape({
  quantity : Yup.number().required("Заавал бөглөнө үү!"),
  to_box_no: Yup.string().required("Заавал бөглөнө үү!"),
});

const { Option } = Select;

export default ({ onCancel, item, action }) => {
  const params = useParams();
  const history = useHistory();
  const { s3host } = useSelector(state => state.general);
  const [searchBox, setSearchBox] = React.useState([]);
  const [data] = React.useState({
    quantity  : 1,
    archive_id: params.archive_id,
    to_box_no : undefined
  });

  const onSubmit = async (items, actions) => {
    actions.setSubmitting(true);
    try {
      await inoutApi.inoutBox({ ...items, from_box_no: action[2].box_no, product_id: action[1].id });
      window.location.reload();
      actions.setSubmitting(false);
      notify.success("Таны хүсэлт амжилттай!");
    } catch (err) {
      actions.setSubmitting(false);
      notify.error(err.payload);
      onCancel();
      throw err;
    }
  };

  let timer;

  const productSearch = async (q = "") => {
    if (timer)
      clearTimeout(timer);

    timer = setTimeout(async () => {
      let result = await inoutApi.searchBox({ query: q });

      setSearchBox(result);

      // eslint-disable-next-line array-callback-return
      (searchBox || []).map((item) => {
        if (item.box_no !== q){
          setSearchBox([
            {
              box_no: q,
              custom: true
            },
            ...searchBox.filter(f => !f?.custom),
          ]);
        } else if (result.length === 0) {
          setSearchBox([{
            box_no: q
          }]);
        }
      });
    }, 300);
  };

  React.useEffect(() => {
    productSearch();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form layout="vertical">
            <Row gutter={8}>
              <Col span={8}>
                <Image className="image">
                  <img src={`${s3host}/${item?.image}`} />
                </Image>
              </Col>
              <Col span={16}>
                <FormItem name="quantity" label="Тоо хэмжээ">
                  <Input type="number" name="quantity" placeholder="Тоо хэмжээ" min={1} max={action && action[2].qty} />
                </FormItem>
                <FormItem name="to_box_no" label="Шилжүүлэх хайрцагны дугаар">
                  <Select
                    showSearch
                    name="to_box_no"
                    onSearch={(v) => productSearch(v)}
                    placeholder="Бараагаар хайх"
                    allowClear
                    style={{ width: "100%" }}>
                    {(searchBox || {}).map((item, index) => {
                      return (
                        <Option key={index} value={item.box_no}>{item.box_no} {item?.custom && "(Шинэ)"}</Option>
                      );
                    })}
                  </Select>
                </FormItem>
                <AntForm.Item>
                  <Button className="button" style={{ marginRight: 10 }} type="default" onClick={onCancel}>Болих</Button>
                  <Button className="button" htmlType="submit" loading={isSubmitting} type="primary">Хадгалах</Button>
                </AntForm.Item>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
const Image = styled.div`
  min-width: 55px;
  margin-right: 10px;
  img {
    border-radius: 4px;
    width: 100%;
  }
`;