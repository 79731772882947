import React, { useState } from "react";
import { Space, Button, DatePicker, Select, message, Tag } from "antd";
import { calculation as calculationApi, product as productApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { CustomTable, RowAction, DrawerRangePicker } from "../../components";
import { CalendarOutlined, FileOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { tugrug } from "../../utils";
import moment from "moment";
import styled from "styled-components";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const { isMobile, archives, s3host } = useSelector(state => state.general);
  const [searchProduct, setSearchProduct] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();

  const [action, setAction] = React.useState();
  const [filters, setFilters] = React.useState({
    type              : "",
    calculation_status: "CALCULATED",
    archive_id        : undefined,
    product_id        : undefined,
  });

  const [filtered, setFilteres] = React.useState({
    filter: {
      type              : "",
      archive_id        : undefined,
      product_id        : undefined,
      calculation_status: "CALCULATED",
      is_download       : false,
    },
    offset: {
      page : 1,
      limit: 100
    },
  });

  const onAction = (key, item) => {
    switch (key) {
      case "detail" : {
        history.push(`/finance/report/${item.id}`);
        break;
      }
      case "edit" : {
        history.push(`/finance/report/edit/${item.id}`);
        break;
      }

      default:
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
      setFilteres({
        filter: {
          ...filtered.filter,
          start_date : moment(new Date(values[0])).startOf("day").toDate(),
          end_date   : moment(new Date(values[1])).endOf("day").toDate(),
          is_download: false
        },
        offset: filtered.offset
      });
    }
  };

  const onClear = () => {
    setFilters({ code: "", type: "", archive_id: "", product_id: "" });
    setAction();
  };

  const onCancel = () => {
    setAction();
  };

  const productSearch = async (q =" ") => {
    let timer = setTimeout(async () => {
      let result = await productApi.search({ query: q });
      setSearchProduct(result);
    }, 600);
    return () => clearTimeout(timer);
  };
  React.useEffect(() => {
    productSearch();
  }, []);

  const downloadFile = async () => {
    setLoading(true);
    try {
      let res = await calculationApi.list({
        ...filtered,
        is_download: true
      });
      setLoading(false);
      if (res.url)

        window.open(
          s3host + "/files/" + res.url,
          "_blank"
        );

    } catch (err) {
      setLoading(false);
      message.error(err);
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Тооцооны тайлан" />
      <PageFilter>
        <Space>
          <Select
            className="item"
            value={filters.archive_id}
            optionFilterProp="children"
            onChange={value => setFilters({ ...filters, archive_id: value })}
            placeholder="Агуулах"
            style={{ width: 250 }}
            allowClear>
            {archives?.map((item, index) => {
              return <Option key={index} value={item.id}>{item.name}</Option>;
            })}
          </Select>
          <Select
            allowClear
            showSearch
            className="item"
            value={filters.product_id}
            optionFilterProp="children"
            onChange={(e) => setFilters({ ...filters, product_id: e })}
            onSearch={(v) => productSearch(v)}
            placeholder="Бараагаар хайх"
            style={{ width: 250 }}>
            {(searchProduct || {}).map((item, index) => {
              return (
                <Option key={index} value={item.id}><Tag>{item.code}</Tag> {item.name}</Option>
              );
            })}
          </Select>
        </Space>
        <Space className="margin-top wrap">
          <Button
            type="default"
            icon={<FileOutlined />}
            onClick={downloadFile}
            loading={loading}
          >Файл татах</Button>
          <div className="gap item" style={{ paddingLeft: "8px" }}>
            {isMobile ? (
              <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
            ) : (
              <RangePicker className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear={false} />)}
          </div>
          <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <CalculationTable
          ref={myTableRef}
          onResult={(res) => setData([res.totals])}
          filters={filters}
          loadData={calculationApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо</th>
                <th className="ant-table-cell" colSpan={5}>Борлуулалт</th>
                <th className="ant-table-cell" colSpan={5}>Буцаалт</th>
                <th className="ant-table-cell" rowSpan={2}>Тооцсон дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Ажилтан</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Барааны тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Картаар</th>
                <th className="ant-table-cell" rowSpan={2}>Дансаар</th>
                <th className="ant-table-cell" rowSpan={2}>Бэлнээр</th>
                <th className="ant-table-cell" rowSpan={2}>Нийт</th>
                <th className="ant-table-cell" rowSpan={2}>Барааны тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Картаар</th>
                <th className="ant-table-cell" rowSpan={2}>Дансаар</th>
                <th className="ant-table-cell" rowSpan={2}>Бэлнээр</th>
                <th className="ant-table-cell" rowSpan={2}>Нийт</th>
              </tr>
            </thead>
          )}
          tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell"><Tag>{row.archive?.name || "-"}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>{row.order_quantity}</td>

                  <td className="ant-table-cell" rowSpan={2}>{row.purchase_product_quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.purchase_card_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.purchase_account_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.purchase_cash_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.debit_amount)}</td>

                  <td className="ant-table-cell" rowSpan={2}>{row.refund_product_quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.refund_card_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.refund_account_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.refund_cash_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.credit_amount)}</td>

                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{`${row.finance_user?.last_name || "-"} ${row.finance_user?.first_name || "-"}`}</td>
                  <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.created_at).format("YYYY.MM.DD HH:mm")}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      actions={{
                        detail: "Дэлгэрэнгүй",
                        edit  : "Буцаалт",
                        // edits : "Zasvarlah",
                      }} onClick={key => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.staff_user?.first_name || "-"} ${row?.staff_user?.last_name || "-"}`}</a></td>
                </tr>
              </tbody>
            );
          }} />
        <CalculationTable
          loadData={() => {}}
          ref={myTableRef}
          filters={filters}
          rows={data}
          pagination={false}
          rowKey={(record, index) => `${record.id}_${index}`}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" colSpan={6}>Борлуулалт</th>
                <th className="ant-table-cell" colSpan={6}>Буцаалт</th>
                <th className="ant-table-cell" rowSpan={2}>Тооцсон дүн</th>
              </tr>
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Захиалгын тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Барааны тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Картаар</th>
                <th className="ant-table-cell" rowSpan={2}>Дансаар</th>
                <th className="ant-table-cell" rowSpan={2}>Бэлнээр</th>
                <th className="ant-table-cell" rowSpan={2}>Нийт</th>
                <th className="ant-table-cell" rowSpan={2}>Захиалгын тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Барааны тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Картаар</th>
                <th className="ant-table-cell" rowSpan={2}>Дансаар</th>
                <th className="ant-table-cell" rowSpan={2}>Бэлнээр</th>
                <th className="ant-table-cell" rowSpan={2}>Нийт</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2}>{row.purchase_order_quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.purchase_product_quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.purchase_card_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.purchase_account_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.purchase_cash_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.debit_amount)}</td>

                  <td className="ant-table-cell" rowSpan={2}>{row.refund_order_quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.refund_product_quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.refund_card_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.refund_account_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.refund_cash_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.credit_amount)}</td>

                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                </tr>
              </tbody>
            );
          }}
        />

        {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}
      </PageContent>
    </PageContainer>
  );
};
const CalculationTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 
  flex-direction: row;  
  @media only screen and (max-width: 530px) {
    flex-direction: column;  
    .ant-space-item {
      width: 100% !important;
    }
    .item {  
      width: 100% !important; 
    } 
    .margin-top {
      margin-top: 8px;
      padding-left: 0px !important; 
    }
    .gap { 
      padding: 0 !important;
    }  
  }
  @media only screen and (max-width: 411px) {  
    flex-direction: column;  
    .wrap {
      display: flex;
      flex-direction: column;
    }
  }  
`;