import React from "react";
import styled from "styled-components";

export default ({ item }) => {
  const getSalePercent = () => {
    let percent = 0;

    if (item.sale) {
      percent = Math.round((item.price - item.sale.price) * 100 / item.price);
    }

    return percent;
  };

  if (!item)
    return <Tags></Tags>;

  return (
    <Tags>
      {item.highlight && <div className="highlight">Онцлох</div>}
      {item.new && <div className="new">Шинэ</div>}
      {item.sale && <div className="sale">{getSalePercent()}% OFF</div>}
    </Tags>
  );
};
const Tags = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 100;
  margin-top: 5px;
  height: 18px;
  div {
    line-height: 18px;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    padding: 0 4px;
    font-weight: 500;
    margin-right: 5px;
  }
  .highlight {
    background-color: #fad040;
  }
  .new {
    background-color: #a6dd28;
  }
  .sale {
    background-color: #e21529;
    color: #fff;
  }
`;