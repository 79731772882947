import React from "react";
import { calculation as calculationApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { DownloadOutlined, SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import { Button, Avatar, Input, Space, DatePicker } from "antd";
import { DrawerRangePicker, CustomTable } from "../../components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { tugrug } from "../../utils";
import moment from "moment";
import styled from "styled-components";

const { RangePicker } = DatePicker;

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { s3host, isMobile } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const [loading, setLoading] = React.useState();
  const [action, setAction] = React.useState();
  const [data, setData] = React.useState();
  const [filters, setFilters] = React.useState({
    query: "",
  });

  const [filtered, setFilteres] = React.useState({
    filter: {
      query      : "",
      is_download: false,
    },
    offset: {
      page : 1,
      limit: 100
    },
  });

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
      setFilteres({
        filter: {
          ...filtered.filter,
          start_date : moment(new Date(values[0])).startOf("day").toDate(),
          end_date   : moment(new Date(values[1])).endOf("day").toDate(),
          is_download: false
        },
        offset: filtered.offset
      });
    }
  };

  const onCancel = () => {
    setAction();
  };

  const onClear = () => {
    setFilters({ query: "", type: "" });
    setAction();
  };

  const onExport = async () => {
    try {
      setLoading(true);
      let res = await calculationApi.purchase({
        ...filtered,
        is_download: true
      });

      window.open(
        s3host + "/files/" + res.url,
        "_blank"
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Борлуулалт (Бараа)"
        extra={[
          <Button type="default" shape={isMobile && "circle"} icon={<DownloadOutlined />} onClick={() => onExport()} htmlType="submit" loading={loading}>{!isMobile && "Excel файл"}</Button>
        ]}
      />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ maxWidth: "250px", width: "100%" }}
            onChange={e => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
        <Space>
          <Space className="margin-top" style={{ paddingLeft: "10px" }}>
            {isMobile ? (
              <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
            ) : (
              <RangePicker className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear={false} />)}
            <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
          </Space>
        </Space>
      </PageFilter>
      <PageContent>
        <SalesTable
          bordered={!isMobile}
          ref={myTableRef}
          filters={filters}
          loadData={calculationApi.purchase}
          rowKey={(record) => record.id}
          onResult={(res) => setData([res.totals])}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Зураг</th>
                <th className="ant-table-cell" rowSpan={2}>Нэр</th>
                <th className="ant-table-cell" rowSpan={2}>Ангилал</th>
                <th className="ant-table-cell" rowSpan={2}>Дотоод код</th>
                <th className="ant-table-cell" rowSpan={2}>Ж/Үнэ</th>
                <th className="ant-table-cell" rowSpan={2}>Б/Үнэ</th>
                <th className="ant-table-cell" colSpan={2}>Тооцоолол</th>
                <th className="ant-table-cell" colSpan={2}>Борлуулалт</th>
                <th className="ant-table-cell" colSpan={2}>Буцаалт</th>
              </tr>
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Үнэ</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" rowSpan={2}>Үнэ</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" rowSpan={2}>Үнэ</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
              </tr>
            </thead>
          )}
          tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell">{<Avatar
                    size={50}
                    shape="square"
                    src={`${s3host}${row?.image}`}
                    style={{ alignItems: "center", display: "flex" }}
                  ></Avatar>}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.category.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}><a onClick={() => history.push(`/product/item/edit/${row.id}`)}>{row.code}</a></td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.sale_price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.statics.balance.price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.statics.balance.quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.statics.purchase.price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.statics.purchase.quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.statics.refund.price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.statics.refund.quantity || 0}</td>
                </tr>
              </tbody>
            );
          }}

        />

        <SalesTable
          loadData={() => {}}
          ref={myTableRef}
          filters={() => {}}
          rows={data}
          pagination={false}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Барааны төрлийн тоо</th>
                <th className="ant-table-cell" colSpan={2}>Тооцоолол</th>
                <th className="ant-table-cell" colSpan={2}>Борлуулалт</th>
                <th className="ant-table-cell" colSpan={2}>Буцаалт</th>
              </tr>
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Үнэ</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" rowSpan={2}>Үнэ</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" rowSpan={2}>Үнэ</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2}>{row.count || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.statics.balance.price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.statics.balance.quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.statics.purchase.price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.statics.purchase.quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.statics.refund.price || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.statics.refund.quantity || 0}</td>
                </tr>
              </tbody>
            );
          }}
        />

        {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}

      </PageContent>
    </PageContainer>
  );
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;

const SalesTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;