import { message } from "antd";

const initialState = {
  draft_id: null,
  carts   : [],
  orders  : [],
  damage  : [],
  shipping: {
    archive_id: null
  },
  payment: {
    method: "bank_account"
  }
};

const addCart = (state, payload) => {
  let key = `${payload.product_id}_${payload?.box_no?.box_no}`;

  let mapped = (payload?.type === "ORDER" ? state.orders : state.carts)?.reduce((accumulator, iterator) => {
    return {
      ...accumulator,
      [iterator.key]: iterator
    };
  }, {}) || {};

  if (!mapped[key]) {
    mapped[key] = {
      key,
      product_id : payload.product_id,
      code       : payload.code,
      name       : payload.name,
      image      : payload.image,
      qty        : payload.qty || 1,
      stock      : payload.quantity,
      price      : payload.price,
      sale_price : payload.sale_price,
      box_no     : payload.box_no,
      is_shipment: payload.is_shipment,
      type       : payload.type
    };

    message.success("Таны сагсанд бараа 1 бараа нэмэгдлээ!");
  } else if (payload.type === "ORDER" || mapped[key].qty + payload.qty <= mapped[key].stock) {
    if (payload.qty > 0) {
      message.success("Таны сагсанд бараа 1 бараа нэмэгдлээ!");
    } else {
      message.warning("Таны сагснаас 1 бараа хасагдлаа!");
    }

    mapped[key].qty += payload.qty;
  } else {
    message.error("Уучлаарай барааны үлдэгдэл хүрэлцэхгүй байна!");
  }

  return {
    [payload.type === "ORDER" ? "orders" : "carts"]: Object.values(mapped).map(cart => cart)
  };
};

const damageCart = (state, payload) => {
  let key = `${payload.product_id}_${payload?.box_no?.box_no}`;
  let mapped = (payload?.type === "DAMAGE" ? state.damage : state.carts)?.reduce((accumulator, iterator) => {
    return {
      ...accumulator,
      [iterator.key]: iterator
    };
  }, {}) || {};

  if (!mapped[key]) {
    mapped[key] = {
      key,
      product_id : payload.product_id,
      code       : payload.code,
      name       : payload.name,
      image      : payload.image,
      qty        : payload.qty || 1,
      stock      : payload.quantity,
      price      : payload.price,
      sale_price : payload.sale_price,
      box_no     : payload.box_no,
      is_shipment: payload.is_shipment,
      type       : payload.type
    };

    message.success("Таны сагсанд бараа 1 бараа нэмэгдлээ!");
  } else if (payload.type === "DAMAGE" || mapped[key].qty + payload.qty <= mapped[key].stock) {
    if (payload.qty > 0) {
      message.success("Таны сагсанд бараа 1 бараа нэмэгдлээ!");
    } else {
      message.warning("Таны сагснаас 1 бараа хасагдлаа!");
    }

    mapped[key].qty += payload.qty;
  } else {
    message.error("Уучлаарай барааны үлдэгдэл хүрэлцэхгүй байна!");
  }

  return {
    [payload.type === "DAMAGE" ? "damage" : "carts"]: Object.values(mapped).map(cart => cart)
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "checkout.carts": {
      return {
        ...state,
        carts: action.payload
      };
    }
    case "checkout.type": {
      return {
        ...state,
        type : action.payload,
        carts: []
      };
    }
    case "cart.add": {
      return {
        ...state,
        ...addCart(state, action.payload)
      };
    }
    case "cart.remove": {
      return {
        ...state,
        ...(action?.payload?.type === "ORDER" ? {
          orders: state.orders.filter(cart => cart.key !== action?.payload?.key)
        } : {
          carts: state.carts.filter(cart => cart.key !== action?.payload?.key)
        })
      };
    }
    case "cart.clear": {
      return {
        ...state,
        ...(action?.payload?.type === "ORDER" ? { orders: [] } : { carts: [] })
      };
    }
    case "cart.sale_price": {
      return {
        ...state,
        is_sale_price: !state.is_sale_price
      };
    }
    case "damage.add": {
      return {
        ...state,
        ...damageCart(state, action.payload)
      };
    }
    case "carts.remove": {
      return {
        ...state,
        ...(action?.payload?.type === "DAMAGE" && {
          damage: state.damage.filter(cart => cart.key !== action?.payload?.key)
        })
      };
    }
    case "carts.clear": {
      return {
        ...state,
        ...(action?.payload?.type === "DAMAGE" ? { damage: [] } : { carts: [] })
      };
    }
    case "shipping.update": {
      return {
        ...state,
        shipping: action.payload
      };
    }
    case "payment.update": {
      return {
        ...state,
        payment: action.payload
      };
    }
    default:
      return state;
  }
};
