import React from "react";
import withSizes from "react-sizes";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { general, auth } from "./apis";
import { PrivateRoute, PublicRoute, NavBar, Header, ProductModal } from "./components";
import { Login, Notfound } from "./pages";
import { ArchiveList, ArchiveProduct, BoxSwap } from "./pages/Archive";
import { SupplierList } from "./pages/Supplier";
import { UserList } from "./pages/User";
import { ProductEdit, ProductList, ProductNew } from "./pages/Product";
import { PartEdit, PartList } from "./pages/Part";
import { PosProduct, PosPart, Detail as DetailPos, Edit as EditPos } from "./pages/Pos";
import { SalesList, SalesCalculation } from "./pages/Sales";
import { DebtDetail, DebtList } from "./pages/Debt";
import { CustomerList } from "./pages/Customer";
import { OrderList, EditOrder } from "./pages/Order";
import { OrderSupplierList, EditOrderSupplier } from "./pages/OrderSupplier";
import { ShipmentList, EditShipment, NewShipment } from "./pages/Shipment";
import Calculation, { CalculationInit, CalculationReport, CalculationDetail, PurchaseReport, RefundDetail, ReportLists } from "./pages/Calculation";
import Income, { IncomeList, IncomeDetail } from "./pages/Income";
import Inout, { InoutList, InoutDetail } from "./pages/Inout";
import CategoryList from "./pages/Category";
import styled from "styled-components";
import Dashboard from "./pages/Dashboard";
import Counting, { CountingInit, CountingReport, CountingDetail } from "./pages/Counting";
import { DamageCart, DamageList, DamageDetail, DamageEdit } from "./pages/Damage";

const App = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch), auth.me()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (token)
      asyncFn();
    else
      setLoading(false);

  }, [dispatch, token]);

  React.useEffect(()=>{
    dispatch({
      type   : "layout.mobile",
      payload: isMobile
    });
  }, [isMobile]);

  if (loading) return <div>loading...</div>;

  return (
    <div>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/">
          <Header isMobile={isMobile} />
          <Container isMobile={isMobile}>
            <div className="content">
              <div className="navbar">
                <NavBar isMobile={isMobile} />
              </div>
              <div className="wrapper">
                <Switch>
                  <Route path="/" component={Dashboard } exact />

                  <Route path="/customer/list" component={CustomerList} exact />
                  <Route path="/order/list" component={OrderList} exact />
                  <Route path="/order/edit/:id" component={EditOrder} exact />

                  <Route path="/order/supplier" component={OrderSupplierList} exact />
                  <Route path="/order/supplier/:id" component={EditOrderSupplier} exact />
                  <Route path="/order/:id/shipment" component={NewShipment} exact />

                  <Route path="/order/shipment" component={ShipmentList} exact />
                  <Route path="/order/shipment/:id" component={NewShipment} exact />
                  <Route path="/order/shipment/edit/:id" component={EditShipment} exact />

                  <Route path="/pos/product/:archive_id" component={PosProduct} exact />
                  <Route path="/pos/part/:archive_id" component={PosPart} exact />
                  <Route path="/pos/sales" component={SalesList} exact />
                  <Route path="/pos/sales/calculation/:id" component={SalesCalculation} exact />
                  <Route path="/pos/detail/:id" component={DetailPos} exact />
                  <Route path="/pos/edit/:id" component={EditPos} exact />

                  <Route path="/calculation/init" component={CalculationInit} exact />
                  <Route path="/calculation/:id" component={Calculation} exact />
                  <Route path="/calculation/edit/:calculation_id" component={Calculation} exact />
                  <Route path="/calculation/refund/:id" component={RefundDetail} exact />

                  <Route path="/finance/report" component={CalculationReport} exact />
                  <Route path="/finance/purchase" component={PurchaseReport} exact />
                  <Route path="/finance/report/:id" component={CalculationDetail} exact />
                  <Route path="/finance/report/edit/:id" component={ReportLists} exact />

                  <Route path="/finance/debt" component={DebtList} exact />
                  <Route path="/finance/debt/:id" component={DebtDetail} exact />

                  <Route path="/archive/list" component={ArchiveList} exact />
                  <Route path="/archive/inout/list" component={InoutList} exact />
                  <Route path="/archive/inout/detail/:id" component={InoutDetail} exact />
                  <Route path="/archive/inout/:type/:archive_id" component={Inout} exact />
                  <Route path="/archive/product" component={ArchiveProduct} exact />
                  <Route path="/archive/counting" component={CountingInit} exact />
                  <Route path="/archive/counting/:id" component={Counting} exact />
                  <Route path="/archive/report" component={CountingReport} exact />
                  <Route path="/archive/report/:id" component={CountingDetail} exact />
                  <Route path="/archive/box/:archive_id" component={BoxSwap} exact />

                  <Route path="/product/item" component={ProductList} exact />
                  <Route path="/product/item/new" component={ProductNew} exact />
                  <Route path="/product/item/edit/:id" component={ProductEdit} exact />

                  <Route path="/product/income" component={IncomeList} exact />
                  <Route path="/product/income/new" component={Income} exact />
                  <Route path="/product/income/detail/:id" component={IncomeDetail} exact />

                  <Route path="/product/part2" component={PartList} exact />
                  <Route path="/product/part2/:id" component={PartEdit} exact />

                  <Route path="/settings/category" component={CategoryList} exact />
                  <Route path="/settings/supplier" component={SupplierList} exact />
                  <Route path="/settings/user" component={UserList} exact />

                  <Route path="/pos/damage/:id" component={DamageCart} exact />
                  <Route path="/damage/list" component={DamageList} exact />
                  <Route path="/damage/detail/:id" component={DamageDetail} exact />
                  <Route path="/damage/edit/:id" component={DamageEdit} exact />

                  <Route component={Notfound} />
                </Switch>
              </div>
            </div>
          </Container>
        </PrivateRoute>
      </Switch>

      <ProductModal />
    </div>
  );
};

export default withSizes(({ width }) => ({
  isMobile: width < 994
}))(App);

const Container = styled.div`
  overflow: hidden; 
  .content { 
    position: relative;
    width: 100%; 
    background: #fff;
    .navbar {
      display: flex;
      width: ${(props) => props.isMobile ? 80 : 230}px;
      border-right: 1px solid #e8e8e8;
      height: calc(100vh - 60px);
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      overflow: auto;
      overflow-x: hidden; 
      background: #ffffff;
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-button {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #1890ff;
        border: 0px none #ffffff;
        border-radius: 50px;
      } 
    }
    .wrapper {
      position: relative; 
      padding-left: ${(props) => props.isMobile ? 80 : 230}px;
      height: calc(100vh - 60px);
      overflow: auto;
      overflow-x: hidden; 
    }
  }
`;
