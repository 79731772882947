import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Input, message } from "antd";
import styled from "styled-components";
import { MinusOutlined, PlusOutlined, HeartOutlined, LoadingOutlined, HeartFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { tugrug } from "../../utils";
import Tags from "./Tags";
import Skeleton from "react-loading-skeleton";

const SectionPrice = ({ item }) => (
  <Price className="section-price">
    <div className="pricing">
      <label>Онлайн авах үнэ</label>
      {item.price ? (
        <div className="original">
          {tugrug(item.sale ? item.sale.price : item.price)} {item.sale && <span className="sale">{tugrug(item.price)}</span>}
        </div>
      ) : (
        <div className="original">
          <Skeleton height={30} width={140} /> ₮
        </div>
      )}
      <Tags item={item} />
    </div>
  </Price>
);

export default ({ item = {} }) => {
  const dispatch = useDispatch();
  const carts = useSelector(state => state.checkout.carts);
  const { getBrand, getCategory } = useSelector(state => ({
    getBrand   : id => state.general.mappedBrands[id] || {},
    getCategory: id => state.general.mappedCategories[id] || {},
  }));
  const [quantity, setQuantity] = React.useState(1);
  const onQuantity = (count) => {
    if (quantity + count > 0 && quantity + count <= item.quantity)
      setQuantity(quantity + count);
  };
  const onAddCart = () => {
    dispatch({
      type   : "cart/add",
      payload: {
        ...item,
        product: item._id,
        qty    : quantity
      }
    });
  };
  const getStock = () => {
    let cart = carts.find(cart => cart.product === item._id);
    return cart ? cart.stock - cart.qty : item.quantity;
  };

  return (
    <Product>
      <div className="section-title">
        <div className="names">
          <h4>{item.title || <Skeleton height={25} width="70%" style={{ marginBottom: 5 }} />}</h4>
          <h5>{getCategory(item.category).name || <Skeleton height={15} width="50%" />}</h5>
        </div>
        <Link className="branding" to="#" href="#">
          {item.brand ? <img src={getBrand(item.brand).icon} alt="" /> : <Skeleton height={60} width={60} style={{ position: "absolute", right: 15, top: 15 }} />}
        </Link>
      </div>
      <SectionPrice item={item} />
      <div className="section-qty">
        <div className="input-qty">
          <Input
            value={quantity}
            disabled
            addonBefore={<Button onClick={() => onQuantity(-1)} icon={<MinusOutlined />} />}
            addonAfter={<Button onClick={() => onQuantity(1)} icon={<PlusOutlined />} />}
          />
        </div>
        <span className="quantity">Үлдэгдэл ({getStock() || 0})</span>
      </div>
      <div className="section-buy">
        <Button className="add-cart" style={{ width: "50%" }} onClick={onAddCart}>Сагсанд нэмэх</Button>
      </div>
      <Description>
        {item.desc ? (
          <p dangerouslySetInnerHTML={{ __html: item.desc }} />
        ) : (
          <div>
            <Skeleton height={20} width="70%" style={{ marginBottom: 5 }} />
            <Skeleton height={20} width="100%" style={{ marginBottom: 5 }} />
            <Skeleton height={20} width="60%" style={{ marginBottom: 5 }} />
          </div>
        )}
      </Description>
    </Product>
  );
};
const Price = styled.div`
  .pricing {
    padding: 15px;
    label {
      font-size: 14px;
      font-weight: normal;
      color: #333;
    }
    .original {
      font-size: 28px;
      font-weight: bold;
      letter-spacing: -2px;
      line-height: 22px;
      color: #333;
      .sale {
        color: #6b7478;
        font-size: 18px;
        font-weight: 500;
        text-decoration: line-through;
        padding-left: 10px;
      }
    }
  }
`;
export const Product = styled.div`
  border-left: 1px solid #eee;
  height: 100%;
  .section-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    .names {    
      font-size: 32px;
      font-weight: 500;
      color: #222;
      margin-bottom: 0;
      line-height: 40px;
      padding: 15px;
      display: block;
      width: 100%;
      h4 {
        font-size: 22px;
        font-weight: 500;
        color: rgb(34, 34, 34);
        margin-bottom: 0px;
        text-transform: uppercase;
        line-height: 22px;
      }
      h5 {
        font-size: 16px;
        font-weight: normal;
        color: rgb(34, 34, 34);
        align-items: center;
        display: block;
        margin: 0px;
        line-height: 22px;
      }
    }
    .branding {
      margin-top: 10px;
      img {
        height: 60px;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .section-qty {
    display: flex;
    padding: 15px;
    .quantity {
      padding: 0 15px;
      line-height: 42px;
      font-size: 14px;
      font-style: italic;
      color: #333;
    }
    .input-qty {
      width: 140px;
      .ant-input-group, .ant-input {
        height: 42px;
        text-align: center;
        background: #fff;
        cursor: pointer;
        color: #333;
        font-weight: 400;
        font-size: 18px;
      }
      .ant-input-group-addon {
        padding: 0;
        button {
          height: 40px;
          border: none;
          width: 40px;
          line-height: 0;
          .anticon {
            font-size: 18px;
          }
        }
      }
    }
  }
  .section-buy {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 15px;
    .ant-btn {
      display: block;
      width: 100%;
      height: 48px;
      font-size: 12px;
      letter-spacing: -0.5px;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 4px;
    }
    .add-cart {
      color: rgb(36, 36, 36);
      border: 2px solid #494b57;
      margin-right: 5px;
    }
    .pay {
      position: relative;
      background: #4a4b57;
      border-color: #333;
      color: rgb(255,255,255);
      margin-left: 5px;
    }
  }
  .section-tools {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 5px 15px 20px 15px;
    margin: 0 15px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    border-bottom: 1px dashed #ddd;
    .add-wish {
      color: #4a4b57;
      line-height: 22px;
      display: flex;
      .anticon {
        font-size: 22px;
        line-height: 0;
        margin-right: 8px;
      }
    }
  }
`;
const Description = styled.div`
  padding: 1rem;
`;