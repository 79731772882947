import request from "../utils/request";

export const init = (data) => request.get("/api/calculation/init", data);
export const start = (id) => request.post(`/api/calculation/${id}/start`);
export const finish = (id, data) => request.put(`/api/calculation/${id}/finish`, data);
export const draft = (id, data) => request.put(`/api/calculation/${id}/draft`, data);
export const get = (id, data) => request.get(`/api/calculation/${id}`, data);
export const cancel = (id) => request.post(`/api/calculation/${id}/cancel`);
export const list = (data) => request.get("/api/calculation", data);
export const purchase = (data) => request.get("/api/calculation/purchase", data);
export const exportFile = (data) => request.get("/api/calculation/export/file", data);

export const refund = (id, data) => request.put(`/api/order/${id}/refund`, data);
// export const refundGet = (id, data) => request.get(`/api/order/${id}/refund`, data);
export const refundGet = (id, data) => request.get(`/api/order/${id}/refund`, data);
