import React from "react";
import { Formik } from "formik";
import { Form, Input, FormItem } from "formik-antd";
import * as Yup from "yup";
import { Button, Input as AntInput, Form as AntForm, Alert, notification } from "antd";
import { auth } from "../../apis";

const FormSchema = Yup.object().shape({
  old_password: Yup.string().required("Одоогын нууц үг"),
  password    : Yup.string().required("Шинэ нууц үг"),
});

const ChangePassword = () => {
  const [error, setError] = React.useState();

  const handleSubmit = async (values, actions) => {
    const res = await auth.changePassword(values);
    if (res.type === "error") return setError(res.payload);
    notification.success({
      message    : "Нууц үг амжилттай солигдлоо",
      description: "Таны нэвтрэх нууц үг амжилттай солигдлоо.",
    });
    setError(false);
    actions.setSubmitting(false);
  };

  return (
    <div className="auth-box">
      <Formik
        initialValues={{
          username: undefined,
          password: undefined,
        }}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form layout={"vertical"}>
            {error ? <Alert type="error" message={error} banner /> : null}
            <FormItem name="old_password" label="Одоогын нууц үг">
              <Input.Password name="old_password" />
            </FormItem>
            <FormItem name="password" label="Шинэ нууц үг" hasFeedback>
              <Input.Password name="password" />
            </FormItem>

            <AntForm.Item
              name="confirm"
              label="Нууц үг баталгаажуулах"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message : "Нууц үгээ давтан оруулна уу!",
                },
                () => ({
                  // validator(rule, value) {
                  //   if (!value || values.password === value) {
                  //     return Promise.resolve();
                  //   }
                  //   return Promise.reject("Нууц үг таарсангүй! Нууц үгээ давтан оруулна уу!");
                  // },
                }),
              ]}
            >
              <AntInput.Password />
            </AntForm.Item>

            <Button htmlType="submit" type="primary" loading={isSubmitting} block>
              Солих
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ChangePassword;
