import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import DashboardOrder from "./components/DashboardOrder";

export default () => {
  return (
    <PageContainer>
      <PageHeader title="Дашбоард" />
      <PageContent>
        <DashboardOrder />
      </PageContent>
    </PageContainer>
  );
};