import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, Row, Col, message } from "antd";
import { Form, FormItem, Input, Select, Switch } from "formik-antd";
import { inout as inoutApi } from "../../../apis";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  to_archive_id: Yup.string().nullable().required("Заавал бөглөнө!"),
  quantity     : Yup.number().nullable().required("Заавал бөглөнө!"),
  new_box_no   : Yup.string().nullable().required("Заавал бөглөнө!"),
});

export default ({ onCancel, item, action }) => {
  const params = useParams();
  const { s3host, archives } = useSelector(state => state.general);
  const [searchBox, setSearchBox] = React.useState([]);
  const [data] = React.useState({
    to_archive_id: undefined,
    quantity     : undefined,
    new_box_no   : undefined,
    is_sale      : false
  });

  const onSubmit = async (data, actions) => {
    actions.setSubmitting(true);
    const productData = {
      products: [
        {
          product_id: item.id,
          quantity  : data.quantity,
          box_no    : action && action[2].box_no,
          new_box_no: data.new_box_no,
          is_sale   : data.is_sale,
        }
      ]
    };

    try {
      await inoutApi.request(params.type.toUpperCase(), {
        from_archive_id: params.archive_id,
        to_archive_id  : data.to_archive_id,
        ...productData
      });
      actions.setSubmitting(false);
      onCancel(null);
      message.success("Таны хүсэлт амжилттай.");
    } catch (err) {
      console.log(err);
      actions.setSubmitting(false);
    }

  };

  let timer;

  const productSearch = async (q = "") => {
    if (timer)
      clearTimeout(timer);

    timer = setTimeout(async () => {
      let result = await inoutApi.searchBox({ query: q });

      setSearchBox(result);

      // eslint-disable-next-line array-callback-return
      (searchBox || []).map((item) => {
        if (item.box_no !== q){
          setSearchBox([
            {
              box_no: q,
              custom: true
            },
            ...searchBox.filter(f => !f?.custom),
          ]);
        } else if (result.length === 0) {
          setSearchBox([{
            box_no: q
          }]);
        }
      });

    }, 300);
  };

  React.useEffect(() => {
    productSearch();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, values, errors }) => {
        return (
          <Form layout="vertical">
            <Row gutter={8}>
              <Col span={8}>
                <Image className="image">
                  <img src={`${s3host}/${item?.image}`} />
                </Image>
              </Col>
              <Col span={16}>
                <FormItem name="to_archive_id" label="Бараа өгөх агуулах" required>
                  <Select name="to_archive_id" placeholder="Агуулах сонгох">
                    {
                      (archives || {}).map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })
                    }
                  </Select>
                </FormItem>
                <FormItem name="new_box_no" label="Хайрцаг" required>
                  <Select showSearch name="new_box_no" onSearch={(v) => productSearch(v)} placeholder="Барааны хайрцаг" allowClear>
                    {(searchBox || {}).map((item, index) => {
                      return (
                        <Option key={index} value={item.box_no}>{item.box_no} {item?.custom && "(Шинэ)"}</Option>
                      );
                    })}
                  </Select>
                </FormItem>
                <FormItem name="quantity" label="Тоо хэмжээ" required>
                  <Input type="number" name="quantity" placeholder="Тоо хэмжээ" min={0} max={action && action[2].qty} />
                </FormItem>
                <FormItem name="is_sale" label="Бөөний үнээр бодох" required>
                  <Switch name="is_sale" checkedChildren="Тийм" unCheckedChildren="Үгүй" defaultChecked />
                </FormItem>
                <AntForm.Item>
                  <Button className="button" style={{ marginRight: 10 }} type="default" onClick={onCancel}>Болих</Button>
                  <Button className="button" htmlType="submit" loading={isSubmitting} type="primary">Хадгалах</Button>
                </AntForm.Item>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
const Image = styled.div`
  min-width: 55px;
  margin-right: 10px;
  img {
    border-radius: 4px;
    width: 100%;
  }
`;