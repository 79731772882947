import styled from "styled-components";
import Input from "./Input";
import Search from "./Search";

const Confirm = styled.div`
  &.primary {
    button {
      background: #096dd9;
      border-color: #0157b7;
      &:hover, &:focus {
        background-color: #0863ca;
        border-color: #024ea5;
      }
      .name {
        color: #fff;
        div {
          color: #81bdff;
        }
      }
      .amount {
        color: #fff;
        background: #0256b7;
        border: 1px solid #024ea5;
      }
    }
  }
  &.danger {
    button {
      background: #ff4d4f;
      border-color: #ff4d4f;
      &:hover, &:focus {
        color: #fff;
        background-color: #d9363e;
        border-color: #d9363e;
      }
      .name {
        color: #fff;
        div {
          color: #ffc4c4;
        }
      }
    }
  }
  &.success {
    button {
      background: rgb(43, 191, 79);
      border-color: rgb(43, 191, 79);
      &:hover, &:focus {
        background-color: rgb(15, 187, 57);
        border-color: #269e43;
      }
      .name {
        color: #fff;
        div {
          color: #a3f37c;
        }
      }
      .amount {
        color: #fff;
        background: #269e43;
        border: 1px solid #21943d;
      }
    }
  }
  button {
    height: 58px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      text-align: left;
      font-size: 17px;
      font-weight: 500;
      div {
        font-size: 12px;
        font-weight: 200;
      }
    }
    .amount {
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 15px;
      float: right;
      right: 15px;
      position: absolute;
    }
  }
`;

export {
  Confirm,
  Input,
  Search,
};