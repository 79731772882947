import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { DatePicker, Form, FormItem, Input, Checkbox, Select, Switch } from "formik-antd";
import { message as notify, Avatar, Select as Search, Form as AntForm, Button, Row, Col, Input as AntInput, Tag, Modal } from "antd";
import { Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MyTable, RowAction } from "../../components";
import { product as productApi } from "../../apis";
import { order as orderApi } from "../../apis";
import { tugrug } from "../../utils";
import { CloseOutlined, SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import styled from "styled-components";
import moment from "moment";

const { Option } = Select;

const FormOrderSchema = () => Yup.object().shape({
  // shipping_amount        : Yup.number().required("Заавал бөглөнө!"),
  // shipping_additional_fee: Yup.number().optional("Заавал бөглөнө!"),
  // shipping_phone         : Yup.string().length(8).required("Заавал бөглөнө!"),
  // shipping_phone_second  : Yup.string().length(8).optional("Заавал бөглөнө!").nullable(),
  // shipping_address       : Yup.string().max(500).required("Заавал бөглөнө!"),
  // shipping_date          : Yup.date().required("Заавал бөглөнө!"),
  customer_id: Yup.string().optional().nullable(),
  // is_cash    : Yup.boolean().required("Заавал бөглөх!"),
  // cash_amount: Yup.number().when("is_cash", {
  //   is  : true,
  //   then: Yup.number().required("Заавал бөглөх!")
  // }),
  // is_card    : Yup.boolean().required("Заавал бөглөх!"),
  // card_amount: Yup.number().when("is_card", {
  //   is  : true,
  //   then: Yup.number().required("Заавал бөглөх!")
  // }),
  // is_account    : Yup.boolean().required("Заавал бөглөх!"),
  // account_amount: Yup.number().when("is_account", {
  //   is  : true,
  //   then: Yup.number().required("Заавал бөглөх!")
  // }),
  // discount_amount : Yup.number().optional().nullable(),
  // surcharge_amount: Yup.number().optional().nullable(),
  note       : Yup.string().max(2000).optional("Заавал бөглөнө!").nullable(),
  products   : Yup.array().of(Yup.object({
    price   : Yup.number().required("Заавал бөглөнө!"),
    quantity: Yup.number().required("Заавал бөглөнө!"),
  }))
});

const { TextArea } = Input;
const { Option: SearchOption } = Search;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setFieldError } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      console.log(errors);

      if (Object.keys(errors).length > 0)
        return false;

      return { values, setFieldError };
    }
  }));

  return null;
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export default ({ action, onCancel, products: init }) => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const submitRef = React.useRef();
  const { s3host, customers, isMobile } = useSelector(state => state.general);
  const [initResults, setInitResults] = React.useState([]);
  const [results, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState(init || []);

  const [data, setFormData] = React.useState({
    type                   : "ORDER",
    customer_id            : undefined,
    shipping_amount        : undefined,
    shipping_additional_fee: 0,
    shipping_phone         : undefined,
    shipping_phone_second  : undefined,
    shipping_address       : undefined,
    shipping_date          : moment(),
    is_cash                : false,
    is_account             : false,
    is_card                : false,
    is_sale_price          : false,
    cash_amount            : undefined,
    account_amount         : undefined,
    card_amount            : undefined,
    coupon_code            : undefined,
    discount_amount        : undefined,
    surcharge_amount       : undefined,
    note                   : undefined,
    products               : [],
    ...(action[1] || {})
  });

  let timeout;

  const onSearch = async (query) => {
    if (query) {
      if (timeout)
        clearTimeout(timeout);

      timeout = setTimeout(async () => {
        let res = await productApi.search({ query });

        setResult(res);
      }, 300);
    } else {
      setResult(initResults);
    }
  };

  const onSave = async () => {
    let valid = await submitRef.current.submitForm();
    if (!valid) return;

    const { values: data, setFieldError } = valid;

    let amount = 0;

    let total = data.products.reduce((accumulator, iterator) => {
      let price = data.is_sale_price ? iterator.sale_price : iterator.price;

      return accumulator + (iterator.quantity || 0) * (price || 0);
    }, 0);

    total += (data.surcharge_amount || 0);
    total -= (data.discount_amount || 0);

    if (data.is_card === true)
      amount += data.card_amount;

    if (data.is_cash === true)
      amount += data.cash_amount;

    if (data.is_account === true)
      amount += data.account_amount;

    if (amount !== total) {
      if (data.is_card === true)
        setFieldError("card_amount", "Үнийн дүн зөрүүтэй!");

      if (data.is_cash === true)
        setFieldError("cash_amount", "Үнийн дүн зөрүүтэй!");

      if (data.is_account === true)
        setFieldError("account_amount", "Үнийн дүн зөрүүтэй!");

      if (data.is_card !== false && data.is_cash !== false && data.is_account !== false) {
        setFieldError("card_amount", "Төлсөн дүн!");
        setFieldError("cash_amount", "Төлсөн дүн!");
        setFieldError("account_amount", "Төлсөн дүн!");
      }
    }

    if (!data)
      return notify.warning("Захиалгын мэдээлэл дутуу байна!");

    if (data.products?.length <= 0)
      return notify.warning("Захиалгын бараа хоосон байна!");

    if (data) {
      try {
        setLoading(true);
        if (action && action[0] === "update") {
          if (onCancel) {
            await orderApi.upset(data.id, {
              shipping_amount        : data.shipping_amount,
              shipping_additional_fee: data.shipping_additional_fee,
              shipping_phone         : data.shipping_phone,
              shipping_phone_second  : data.shipping_phone_second,
              shipping_address       : data.shipping_address,
              shipping_date          : data.shipping_date,
              customer_id            : data.customer_id,
              cash_amount            : data.cash_amount,
              card_amount            : data.card_amount,
              account_amount         : data.account_amount,
              surcharge_amount       : data.surcharge_amount,
              discount_amount        : data.discount_amount,
              is_sale_price          : data.is_sale_price,
              note                   : data.note,
              products               : data.products.map(item => ({
                product_id: item.product_id,
                quantity  : item.quantity
              }))
            });
          } else {
            await orderApi.update(data.id, {
              shipping_amount        : data.shipping_amount,
              shipping_additional_fee: data.shipping_additional_fee,
              shipping_phone         : data.shipping_phone,
              shipping_phone_second  : data.shipping_phone_second,
              shipping_address       : data.shipping_address,
              shipping_date          : data.shipping_date,
              customer_id            : data.customer_id,
              cash_amount            : data.cash_amount,
              card_amount            : data.card_amount,
              account_amount         : data.account_amount,
              surcharge_amount       : data.surcharge_amount,
              discount_amount        : data.discount_amount,
              is_sale_price          : data.is_sale_price,
              note                   : data.note,
              products               : data.products.map(item => ({
                product_id: item.product_id,
                quantity  : item.quantity
              }))
            });
          }
        } else {
          await orderApi.create({
            shipping_amount        : data.shipping_amount,
            shipping_additional_fee: data.shipping_additional_fee,
            shipping_phone         : data.shipping_phone,
            shipping_phone_second  : data.shipping_phone_second,
            shipping_address       : data.shipping_address,
            shipping_date          : data.shipping_date,
            customer_id            : data.customer_id,
            cash_amount            : data.cash_amount,
            card_amount            : data.card_amount,
            account_amount         : data.account_amount,
            surcharge_amount       : data.surcharge_amount,
            discount_amount        : data.discount_amount,
            is_sale_price          : data.is_sale_price,
            note                   : data.note,
            products               : data.products.map(item => ({
              product_id: item.product_id,
              quantity  : item.quantity
            }))
          });
        }

        if (onCancel)
          onCancel(true);
        else
          history.push("/order/list");
        setLoading(false);
        notify.success("Таны хүсэлт амжилттай!");
      } catch (err) {
        if (typeof err.message === "string")
          notify.error(err.message);
      }
    }
  };

  const onClear = () => {
    Modal.confirm({
      title     : "Баталгаажуулах",
      icon      : <ExclamationCircleOutlined />,
      content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
      okText    : "Устгах",
      cancelText: "Болих",
      className : "btn-custom-class",
      onOk      : async () => {
        try {
          await orderApi.clearCart();
          myTableRef.current.reload();

          if (onCancel)
            return onCancel();

          history.push("/order/new");
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const reload = React.useCallback(async (signal) => {
    let res = await productApi.search("", { signal });

    setResult(res);
    setInitResults(res);
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader title="Захиалга баталгаажуулах" extra={[
        action[0] === "create" && <Button key="clear" icon={isMobile && <CloseOutlined />} shape={isMobile && "circle"} type="default" onClick={() => onClear()}>{!isMobile && "Цэвэрлэх"}</Button>,
        <Button key="confirm" icon={isMobile && <SendOutlined />} shape={isMobile && "circle"} type="primary" loading={loading} onClick={() => onSave(data.type)}>{!isMobile && "Болсон"}</Button>
      ]} />
      <PageContent>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={FormOrderSchema}
          onSubmit={() => {}}>
          {({ values, setFieldValue }) => {

            const onChangeCustomer = (id) => {
              let customer = customers.find(c => c.id === id);

              if (customer) {
                setFieldValue("shipping_address", customer.address);
                setFieldValue("shipping_phone", customer.phone);
              } else {
                setFieldValue("shipping_address", "");
                setFieldValue("shipping_phone", "");
              }
            };

            let QUANTITY = values.products.reduce((accumulator, iterator) => {
              return accumulator + (iterator.quantity || 0);
            }, 0);

            let TOTAL_AMOUNT = values.products.reduce((accumulator, iterator) => {
              let price = parseFloat(values.is_sale_price ? iterator.sale_price : iterator.price);

              return accumulator + (iterator.quantity || 0) * (price || 0);
            }, 0);

            TOTAL_AMOUNT += parseFloat((values.surcharge_amount || 0));
            TOTAL_AMOUNT -= parseFloat((values.discount_amount || 0));

            const columns = useHeader({
              history,
              s3host,
              values,
              onChangeQuantity: () => {
                setFieldValue("card_amount", 0);
                setFieldValue("is_card", false);
                setFieldValue("cash_amount", 0);
                setFieldValue("is_cash", false);
                setFieldValue("account_amount", 0);
                setFieldValue("is_account", false);
              },
              onClick: (key, item) => {
                switch (key) {
                  case "remove" : {
                    setProducts(products.filter(p => p.id !== item.id));
                    setFieldValue("products", values.products.filter(p => p.product_id !== item.id));
                    break;
                  }
                  default:
                }
              },
            });

            const onChange = (id) => {
              let product = results.find(result => result.id === id);

              if (product && !products.find(p => p.id === product.id)) {
                setProducts([...products, {
                  ...product
                }]);

                setFormData({
                  ...values,
                  products: [...values.products, {
                    product_id: product.id,
                    price     : product.price,
                    quantity  : undefined
                  }]
                });
              }
            };

            const onMethod = (type, field) => {
              if (!values[type]) {
                setFieldValue(field, TOTAL_AMOUNT);
              } else {
                setFieldValue(field, undefined);
              }
            };

            const onChangeValue = (field, value) => {
              switch (field) {
                case "card_amount": {
                  if (values.is_cash === true)
                    setFieldValue("cash_amount", TOTAL_AMOUNT - value);
                  else
                    setFieldValue("cash_amount", undefined);

                  setFieldValue("account_amount", undefined);
                  break;
                }
                case "cash_amount": {
                  if (values.is_account === true)
                    setFieldValue("account_amount", TOTAL_AMOUNT - (values.is_card ? values.card_amount : 0) - value);
                  else
                    setFieldValue("account_amount", undefined);
                  break;
                }
                default:
              }
            };

            return (
              <Form layout="horizontal">
                <SearchBox>
                  <Search
                    showSearch
                    showArrow={false}
                    filterOption={false}
                    onSearch={onSearch}
                    onChange={onChange}
                    className="search-select-product"
                    allowClear
                    size="large"
                    placeholder="Эндээс бүгдийг хайж олох ..." >
                    {results.map((item, index) => (
                      <SearchOption className="option-product-item" key={index} value={item.id}>
                        <Avatar src={`${s3host}/${item.image}`} shape="square" />
                        <div className="item name boxNameFox">
                          <div>{item.code}</div>
                          <span>{item.name}</span>
                        </div>
                        <div className="item">
                          <div>{tugrug(item.price)}</div>
                          <span>Үнэ</span>
                        </div>
                        {item?.archives.map((archive, index) => {
                          if (archive.quantity > 0){
                            return (
                              <div key={index} className="item">
                                <div>{archive.quantity}</div>
                                <span>{archive.name}</span>
                              </div>
                            );
                          }
                        })}
                      </SearchOption>
                    ))}
                  </Search>
                </SearchBox>

                <IncomeTable
                  ref={myTableRef}
                  items={products}
                  columns={columns}
                  pagination={false}
                />

                <IncomeFooter {...formItemLayout}>
                  <Row gutter={24}>
                    <Col span={8}></Col>
                    <Col span={8}>
                      {/* <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="customer_id" label="Харилцагч">
                        <Select name="customer_id" placeholder="Харилцагч" allowClear showSearch onChange={onChangeCustomer}>
                          {customers.map((item, index) => (
                            <Option key={index} value={item.id}>{item.type === "ORGANIZATION" ? `${item.name}` : `${item?.last_name} ${item?.first_name}`}</Option>
                          ))}
                        </Select>
                      </FormItem> */}
                      {/* <FormItem name="shipping_amount" label="Хүргэлт үнэ" required>
                        <Input type="number" name="shipping_amount" placeholder="Хүргэлт үнэ" />
                      </FormItem>
                      <FormItem name="shipping_additional_fee" label="Хүргэлтийн нэмэгдэл (Төлбөр)">
                        <Input type="number" name="shipping_additional_fee" placeholder="Хүргэлт нэмэгдэл" />
                      </FormItem>
                      <FormItem name="shipping_date" label="Хүргэлтийн огноо" required>
                        <DatePicker style={{ width: "100%" }} name="shipping_date" showTime showNow />
                      </FormItem>
                      <FormItem name="shipping_phone" label="Холбоо барих утас (1)" required>
                        <Input type="text" name="shipping_phone" placeholder="Утас 1" />
                      </FormItem>
                      <FormItem name="shipping_phone_second" label="(2)">
                        <Input type="text" name="shipping_phone_second" placeholder="Утас 2" />
                      </FormItem> */}
                    </Col>
                    <Col span={8}>
                      {/* <SwitchContainer>
                        <Switch name="is_sale_price" style={{ marginRight: 10 }} /> Бөөний үнээр тооцох
                      </SwitchContainer> */}
                      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="customer_id" label="Харилцагч">
                        <Select name="customer_id" placeholder="Харилцагч" allowClear showSearch onChange={onChangeCustomer}>
                          {customers.map((item, index) => (
                            <Option key={index} value={item.id}>{item.type === "ORGANIZATION" ? `${item.name}` : `${item?.last_name} ${item?.first_name}`}</Option>
                          ))}
                        </Select>
                      </FormItem>

                      <Total>
                        <AntForm.Item label="Тоо хэмжээ" required>
                          <AntInput placeholder="Тоо хэмжээ" value={QUANTITY} disabled />
                        </AntForm.Item>
                        <AntForm.Item label="Нийт дүн" required>
                          <AntInput placeholder="Нийт дүн" value={tugrug(TOTAL_AMOUNT)} disabled />
                        </AntForm.Item>
                        {/* <FormItem label="Хямдарсан дүн" name="discount_amount">
                          <Input type="number" name="discount_amount" placeholder="Хямдарсан дүн (discount)" />
                        </FormItem>
                        <FormItem label="Нэмэлт төлбөр" name="surcharge_amount">
                          <Input type="number" name="surcharge_amount" placeholder="Нэмэлт төлбөр" />
                        </FormItem> */}
                      </Total>

                      {/* <FormItemGroup label="Картаар" name="card_amount" required>
                        <div className="group">
                          <Input min={0} type="number" name="card_amount" placeholder="Картаар" disabled={!values.is_card} onChange={e => onChangeValue("card_amount", e.target.value)} />
                          <div className="exclusion">
                            <Checkbox name="is_card" onChange={() => onMethod("is_card", "card_amount")} />
                          </div>
                        </div>
                      </FormItemGroup>
                      <FormItemGroup label="Бэлнээр" name="cash_amount" required>
                        <div className="group">
                          <Input min={0} type="number" name="cash_amount" placeholder="Бэлнээр" disabled={!values.is_cash} onChange={e => onChangeValue("cash_amount", e.target.value)} />
                          <div className="exclusion">
                            <Checkbox name="is_cash" onChange={() => onMethod("is_cash", "cash_amount")} />
                          </div>
                        </div>
                      </FormItemGroup>
                      <FormItemGroup label="Дансаар" name="account_amount" required>
                        <div className="group">
                          <Input min={0} type="number" name="account_amount" placeholder="Дансаар" disabled={!values.is_account} onChange={e => onChangeValue("account_amount", e.target.value)} />
                          <div className="exclusion">
                            <Checkbox name="is_account" onChange={() => onMethod("is_account", "account_amount")} />
                          </div>
                        </div>
                      </FormItemGroup> */}
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}></Col>
                    <Col span={8}>
                      {/* <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="shipping_address" label="Хаяг байршил" required>
                        <TextArea type="text" name="shipping_address" placeholder="Хаяг байршил" />
                      </FormItem> */}
                    </Col>
                    <Col span={8}>
                      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="note" label="Нэмэлт мэдээлэл (Тайлбар)">
                        <TextArea type="text" name="note" placeholder="Нэмэлт мэдээлэл" />
                      </FormItem>
                    </Col>
                  </Row>
                </IncomeFooter>

                <SubmitForm ref={submitRef} />
              </Form>
            );
          }}
        </Formik>
      </PageContent>
    </PageContainer>
  );
};

const Total = styled.div`
  .ant-input-disabled {
    background: #fff;
    color: #333;
  }
`;
const SwitchContainer = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: whitesmoke;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const FormItemGroup = styled(FormItem)`
  position: ralative;
  .range-number {
    display: flex;
    flex-direction: row;
    width: 100%;
    .range-to {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 74px;
    }
  }

  .ant-picker, ant-picker-range {
    width: 100%;
  }
  .group {
    display: flex;
    flex-direction: row;
    .exclusion {
      height: 32px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .has-exclusion {
    position: absolute;
    right: 0;
  }
`;
const IncomeFooter = styled(AntForm)`
  margin-top: 20px;
  /* .ant-input[disabled] {
    background: #fff;
    color: #333;
  } */
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;

const SearchBox = styled.div`
  margin-bottom: 20px;
  .ant-select {
    width: 100%;
  }
`;

const IncomeTable = styled(MyTable)`
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }  
`;

const useHeader = ({ values, s3host, onClick, onChangeQuantity }) => {
  return [{
    title : "№",
    width : 10,
    // render: (record) => {
    //   return record.rowIndex;
    // }
    render: (item, index, count) => {
      return count + 1;
    }
  }, {
    title : "Зураг",
    render: (record) => {
      return <Avatar src={`${s3host}/${record.image}`} shape="square" />;
    }
  }, {
    title : "Нэр",
    render: record => {
      return record.name;
    }
  }, {
    title : "Код",
    render: record => {
      return <a>{record.code}</a>;
    }
  }, {
    title : "Үнэ",
    render: (record) => {
      return (
        <>
          <div>{tugrug(record.price)}</div>
          <Tag>Б.Үнэ {tugrug(record.sale_price)}</Tag>
        </>
      );
    }
  }, {
    title : "Тоо ширхэг",
    render: (record, i, index) => {
      return (
        <FormItem name={`products[${index}].quantity`}>
          <Input name={`products[${index}].quantity`} type="number" onChange={onChangeQuantity} placeholder="Тоо ширхэг" min={0} />
        </FormItem>
      );
    }
  }, {
    title : "Нийт дүн",
    render: (record, i, index) => {
      return tugrug((values.products[index]?.quantity || 0) * record.price);
    }
  }, {
    title : "Үйлдэл",
    render: (record) => {
      return (
        <RowAction
          actions={{
            remove: "Устгах",
          }}
          onClick={(key) => onClick(key, record)}
        />
      );
    }
  }];
};