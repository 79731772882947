import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Button, message as notify, Modal } from "antd";
import { Formik } from "formik";
import { MyTable, RowAction } from "../../components";
import { section as sectionApi } from "../../apis";
import styled from "styled-components";
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

const FormSchema = () => Yup.object().shape({
  type: Yup.string().required("Заавал бөглөнө!"),
  name: Yup.string().when("type", {
    is  : "new",
    then: Yup.string().required("Заавал бөглөнө!")
  }),
  section: Yup.string().when("type", {
    is  : "edit",
    then: Yup.string().required("Заавал бөглөнө!")
  })
});

export default ({ filters }) => {
  const myTableRef = React.useRef(null);
  const dispatch = useDispatch();
  const [action, setAction] = React.useState([]);
  const [data] = React.useState({
    type   : "new",
    name   : undefined,
    section: undefined
  });

  const onSubmit = async (data, { resetForm }) => {
    let res;

    if (action && action[0] === "edit") {
      res = await sectionApi.update({
        id        : action[1].id,
        name      : data.section,
        archive_id: filters.archive_id
      });

    } else {
      res = await sectionApi.create({
        name      : data.name,
        archive_id: filters.archive_id
      });
    }

    dispatch({
      type   : "general.section",
      payload: res
    });

    resetForm();
    setAction();

    myTableRef.current.reload();
    notify.success("Таны хүсэлт амжилттай!");
  };

  return (
    <Container>
      <Formik
        initialValues={data}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, setFieldValue }) => {
          const columns = useHeader({
            action,
            setFieldValue,
            setAction,
            onAction: async (key, record) => {
              switch (key) {
                case "edit" : {
                  setAction(["edit", record]);
                  setFieldValue("type", "edit");
                  setFieldValue("section", record.name);
                  break;
                }
                case "remove" : {
                  Modal.confirm({
                    title     : "Баталгаажуулах",
                    icon      : <ExclamationCircleOutlined />,
                    content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
                    okText    : "Устгах",
                    cancelText: "Болих",
                    className : "btn-custom-class",
                    onOk      : async () => {
                      await sectionApi.remove(record.id);

                      dispatch({
                        type   : "general.section.remove",
                        payload: record.id
                      });

                      myTableRef.current.reload();
                    },
                  });
                  break;
                }
                default:
              }
            }
          });

          return (
            <Form layout="horizontal">
              <Section>
                <div className="space" style={{ width: "100%" }}>
                  <FormItem name="name" label="Нэр" required>
                    <Input name="name" placeholder="Нэр"/>
                  </FormItem>
                </div>
                <div className="space" style={{ marginLeft: 20 }}>
                  <Button className="button" htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                </div>
              </Section>

              <MyTable
                ref={myTableRef}
                filters={filters}
                columns={columns}
                loadData={sectionApi.list}
              />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const Section = styled(Form)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-form-item {
    width: 100%;
  }
  .space {
    display: flex;
  }
`;

const Container = styled.div`
  td {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-explain {
      display: none!important;
    }
  }
`;

const useHeader = ({ action, setFieldValue, setAction, onAction }) => {
  return [{
    title : "№",
    width : 10,
    render: (record) => {
      return record.rowIndex;
    }
  }, {
    title : "Нэр",
    render: (record, i, index) =>{
      if (action && action[0] === "edit" && action[1].id === record.id)
        return (
          <FormItem name={"section"}>
            <Input name={"section"} placeholder="Нэр"/>
          </FormItem>
        );

      return record?.name || "-";
    }
  }, {
    title : "Үйлдэл",
    align : "center",
    width : action && action[0] === "edit" ? 180 : 100,
    render: (record) => {
      if (action && action[0] === "edit" && action[1].id === record.id)
        return (
          <>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary">Хадгалах</Button>
            <Button className="button" shape="circle" type="default" onClick={() => {
              setAction();
              setFieldValue("type", "new");
            }}><CloseOutlined /></Button>
          </>
        );
      return (
        <RowAction
          actions={{
            edit  : "Засварлах",
            remove: "Устгах",
          }}
          onClick={(key) => onAction(key, record)}
        />
      );
    }
  }];
};