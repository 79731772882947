import React from "react";
import { Form, FormItem, Input, Radio, Select } from "formik-antd";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

const FormSchema = () => Yup.object().shape({
  type: Yup.string().required("Заавал бөглөнө!"),
  name: Yup.string().when("type", {
    is  : "ORGANIZATION",
    then: Yup.string().max(45).required("Заавал бөглөнө!")
  }).optional().nullable(),
  first_name: Yup.string().when("type", {
    is  : "CITIZEN",
    then: Yup.string().max(45).required("Заавал бөглөнө!")
  }).optional().nullable(),
  last_name: Yup.string().when("type", {
    is  : "CITIZEN",
    then: Yup.string().max(45).required("Заавал бөглөнө!")
  }).optional().nullable(),
  register_no: Yup.string().required("Заавал бөглөнө!"),
  phone      : Yup.string().required("Заавал бөглөнө!"),
  address    : Yup.string().required("Заавал бөглөнө!"),
  email      : Yup.string().optional().nullable(),
  city       : Yup.string().required("Заавал бөглөнө!"),
  district   : Yup.string().required("Заавал бөглөнө!"),
});

export default ({ action, onSubmit, onCancel }) => {
  const { cities, districts } = useSelector(state => state.general);
  const [data] = React.useState({
    type       : "CITIZEN",
    name       : undefined,
    register_no: undefined,
    first_name : undefined,
    last_name  : undefined,
    phone      : undefined,
    email      : undefined,
    address    : undefined,
    city       : undefined,
    district   : undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ values, isSubmitting }) => (
        <Form {...formItemLayout}>
          <FormItem name="type" label="Төрөл" required>
            <Radio.Group
              name="type"
              options={[{
                label: "Иргэн",
                value: "CITIZEN"
              }, {
                label: "Байгууллага",
                value: "ORGANIZATION"
              }]}
              optionType="button"
              buttonStyle="solid"
            />
          </FormItem>
          <FormItem name="register_no" label="Регистрийн дугаар" required>
            <Input name="register_no" placeholder="Регистрийн дугаар" />
          </FormItem>
          {values.type === "CITIZEN" ? (
            <>
              <FormItem name="last_name" label="Овог" required>
                <Input name="last_name" placeholder="Овог" />
              </FormItem>
              <FormItem name="first_name" label="Нэр" required>
                <Input name="first_name" placeholder="Нэр" />
              </FormItem>
            </>
          ) : (
            <FormItem name="name" label="Нэр" required>
              <Input name="name" placeholder="Нэр" />
            </FormItem>
          )}
          <FormItem name="phone" label="Утасны дугаар" required>
            <Input name="phone" placeholder="Утасны дугаар" />
          </FormItem>
          <FormItem name="email" label="Имэйл хаяг">
            <Input name="email" placeholder="Имэйл хаяг" />
          </FormItem>
          <FormItem name="city" label="Аймаг / Хот" required>
            <Select name="city" placeholder="Аймаг / Хот">
              {cities.map((city, index) => (
                <Option value={city.code} key={index}>{city.name}</Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="district" label="Сум / Дүүрэг" required>
            <Select name="district" placeholder="Сум / Дүүрэг">
              {districts.filter(d => d.parent === values.city).map((district, index) => (
                <Option value={district.code} key={index}>{district.name}</Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="address" label="Хаяг" required>
            <TextArea name="address" placeholder="Хаяг" />
          </FormItem>
          <AntForm.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};