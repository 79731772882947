import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { part as partApi } from "../../apis";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Row, Col, Button, Avatar, Spin, Tag, Space, Pagination, message, Modal, Input } from "antd";
import { ToolFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { MyTable } from "../../components";
import { useParams } from "react-router-dom";
import Quantity from "./components/Quantity";
import { tugrug } from "../../utils";
import List from "./List";
import Detail from "./Detail";

export const InoutList = List;
export const InoutDetail = Detail;

export default () => {
  const params = useParams();
  const history = useHistory();
  const myTableRef = React.useRef();
  const { archives, s3host } = useSelector(state => state.general);
  const { carts, orders } = useSelector(state => state.checkout);
  const [action, setAction] = React.useState();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });
  const [loading, setLoading] = React.useState();
  const [offset, setOffset] = React.useState({
    page : 1,
    limit: 10
  });
  const [{ payload }, setResult] = React.useState({ payload: [] });

  const onAddCart = (type, item, box) => {
    if (item.quantity <= 0)
      return message.warning("Барааны үлдэгдэл хүрэлцэхгүй байна!");

    if (type === "PURCHASE") {
      setAction(["cart", item, box, type]);
    } else {
      setAction(["cart", item, box?.box_no, type]);
    }
  };

  const columns = useHeader({
    s3host,
    history,
    carts,
    orders,
    onAddCart,
    onClick: (key, item) => {
      switch (key) {
        case "edit" : {
          history.push(`/part/edit/${item.id}`);
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction(null);
  };

  const onPagination = (page) => {
    let value = {
      ...offset,
      page
    };

    setOffset(value);
    setFilters(state => ({
      ...state,
      offset: value
    }));
  };

  React.useEffect(() => {
    if (params.archive_id !== filters?.archive_id) {
      setFilters(state => ({
        ...state,
        archive_id: params.archive_id
      }));
    }
  }, [params]);

  const reload = React.useCallback(
    async (signal) => {
      try {
        setLoading(true);

        let res = await partApi.search({
          filter: filters,
          offset
        }, { signal });

        setResult(res);
        setLoading(false);
      } catch (err) {
        setResult({ type: "PRODUCT", payload: [] });
        setLoading(false);

        throw err;
      }
    },
    [filters, offset]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({
          ...state,
          maker_id : null,
          market_id: null,
          model_id : null,
          query
        }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title={<span>{archives.find(a => a.id === params.archive_id)?.name || "Борлуулалт хийх"}</span>}/>

      <PageContent>
        <Row gutter={8} style={{ marginBottom: 20 }}>
          <Col xs={24} md={24} lg={4} style={{ marginBottom: 5 }}>
            <Input placeholder="OEM эсвэл PART" onChange={e => setQuery(e.target.value)} prefix={<SearchOutlined />} />
          </Col>
        </Row>
        <Spin spinning={loading}>
          <>
            <MyTable
              columns={columns}
              ref={myTableRef}
              items={payload?.rows}
              pagination={false}
              rowKey={(record) => record.id} />

            {payload?.count > 0 && <Pagination style={{ marginTop: 20 }} current={offset.page} total={payload?.count} onChange={onPagination} />}
          </>
        </Spin>
      </PageContent>

      <Modal
        title={action && `Сэлбэгийн код: ${action[1]?.code}`}
        visible={action && action[0] === "cart"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Quantity onCancel={onCancel} item={action && action[1]} action={action} />
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ s3host, carts, onAddCart, orders }) => {
  return [{
    title : "On scheme",
    width : 100,
    render: (record) => {
      if (!record.part)
        return record.sort_of_position;

      return (
        <>
          {record.sort_of_position} <Avatar src={record?.image || `${s3host}/${record?.part?.image}`} size={100} shape="square" />
        </>
      );
    }
  }, {
    title : "PART / Мэдээлэл",
    render: record => {
      if (!record?.part)
        return (
          <>
            <p style={{ margin: 0 }}>
              Not Available
              {record.additional && <div style={{ fontSize: 12 }}>{record.additional}</div>}
            </p>
          </>
        );

      const onCar = (note) => {
        let array = note?.split("on car:")[1]?.split("&&")[0]?.trim()?.split("package:");

        let car = (array && array[0] || "")?.trim();
        let pcs = (array && array[1] || "")?.trim();

        return (
          <>
            <Tag style={{ marginBottom: 10 }} color="#f50">On car: {car} </Tag>
            <Tag style={{ marginBottom: 10 }} color="#2db7f5">Package: {pcs} </Tag>
          </>
        );
      };

      return (
        <>
          <Tag color="black">{record?.part?.code}</Tag>
          <p>
            {record?.part?.name}
            {record?.additional && <div style={{ fontSize: 12 }}>{record?.additional}</div>}
          </p>
          <div>
            <Space>
              {record.note && onCar(record.note)}
              <Tag style={{ marginBottom: 10 }} color={record?.part?.product?.quantity > 0 ? "blue" : "red"}>Нийт үлдэгдэл: {record?.part?.product?.quantity}</Tag>
              {record?.qties?.map(q => <Tag style={{ marginBottom: 10 }} color="blue">{q.name}: {q.qty}</Tag>)}
              {record?.part?.product?.order_quantity > 0 && <Tag style={{ marginBottom: 10 }} color="orange">Захиалга: {record?.part?.product?.order_quantity}</Tag>}
              {record?.part?.product?.shipment_quantity > 0 && <Tag style={{ marginBottom: 10 }} color="green">Татан авалт: {record?.part?.product?.shipment_quantity}</Tag>}
              {record?.part?.product?.produce_quantity > 0 && <Tag style={{ marginBottom: 10 }} color="lime">Татан авалт: {record?.part?.product?.produce_quantity}</Tag>}
            </Space>
          </div>
          {(() =>{

            let cartCount = carts?.filter(c => c.product_id === record?.part?.product?.id)?.reduce((acc, iter)=>{
              return parseFloat(acc) + parseFloat(iter.qty);
            }, 0);

            let orderCount = orders?.find(c => c.product_id === record?.part?.product?.id);

            if (cartCount || orderCount) {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Space>
                    {cartCount !== 0 && <Tag color="geekblue">Сагсанд: {cartCount}</Tag>}
                    {orderCount &&<Tag color="purple">Захиалхаар төлөвлөсөн: {orderCount.qty}</Tag>}
                  </Space>
                </div>
              );
            }
          })()}

          {(() => {

            return (
              <Space direction="vertical">
                <div>
                  {record?.boxes?.filter(b => b.qty > 0).map((box, index) => (
                    <Button type="primary" key={index} style={{ marginRight: 10 }} onClick={() => onAddCart("PURCHASE", record?.part?.product, box)} styles={{ marginBottom: 5, marginRight: 5 }}><PlusOutlined /> { `Хайрцаг (${box.box_no}): ${box.qty}`}</Button>
                  ))}
                </div>
              </Space>
            );
          })()}
        </>
      );
    }
  }, {
    title : "Үнэ",
    width : 100,
    render: record => {
      if (!record?.part) return "-";

      return (
        <>
          {tugrug(record?.part?.product?.price)} <Tag>Б.Үнэ: {tugrug(record?.part?.product?.sale_price)}</Tag>
        </>
      );
    }
  }, {
    title : "Гарын авлага",
    width : 50,
    render: record => {
      if (!record?.part) return "-";

      return (
        <a href={record?.part?.manual_url} target="_blank">
          <Button className='manual' type="default" size="large"><ToolFilled size={44} /></Button>
        </a>
      );
    }
  }];
};