import React from "react";
import { useParams } from "react-router-dom";
import { order as orderApi } from "../../apis";
import { Spin } from "antd";
import Form from "./Form";

export default () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const reload = React.useCallback(async (signal) => {
    let res = await orderApi.shipment(params.id);

    setProducts(res.products);
    setAction(["create", {
      order_id: params.id,
      note    : res.note || undefined,
      products: res.products.map(p => ({
        product_id: p.id,
        price     : p.price,
        sale_price: p.sale_price,
        quantity  : p.quantity
      }))
    }]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <Spin spinning={!action[1]}>
      {action[1] && <Form action={action} products={products} orderId={params.id} />}
    </Spin>
  );
};