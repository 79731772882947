import arrayToTree from "array-to-tree";

const initialState = {
  s3host       : "/uploads/",
  isMobile     : false,
  categories   : [],
  categoryTrees: [],
  staffs       : [],
  suppliers    : [],
  roles        : [],
  archives     : [],
  sections     : [],
  makers       : [],
  markets      : [],
  models       : [],
  cities       : [],
  districts    : [],
  customers    : []
};

const makeMappedCategories = (payload) => {
  let mapped = payload.reduce((accumilator, iterator) => ({
    ...accumilator,
    [iterator._id]: iterator
  }), {});

  return mapped;
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case "layout.mobile": {
      return {
        ...state,
        isMobile: action.payload
      };
    }
    case "general.init": {
      const {
        categories,
        customers,
        staffs,
        roles,
        units,
        archives,
        sections,
        s3,
        suppliers, makers, markets, models, cities, districts } = action.payload;
      return {
        ...state,
        s3host       : s3,
        categories,
        categoryTrees: arrayToTree(categories.filter(c => c.type === "PRODUCT"), {
          parentProperty: "parent_id",
          customID      : "id"
        }),
        staffs,
        archives,
        sections,
        suppliers,
        roles,
        units,
        makers,
        markets,
        models,
        cities,
        districts,
        customers
      };
    }
    case "general.category": {
      return {
        ...state,
        categories   : action.payload,
        categoryTrees: arrayToTree(action.payload.filter(c => c.type === "PRODUCT"), {
          parentProperty: "parent_id",
          customID      : "id"
        }),
      };
    }
    case "general.section": {
      return {
        ...state,
        sections: [...state.sections.filter(s => s.id !== action.payload.id), action.payload]
      };
    }
    case "general.section.remove": {
      return {
        ...state,
        sections: state.sections.filter(s => s.id !== action.payload)
      };
    }
    case "general.staff": {
      return {
        ...state,
        staffs: [...state.staffs.filter(s => s.id !== action.payload.id), action.payload]
      };
    }
    case "general.staff.remove": {
      return {
        ...state,
        staffs: state.staffs.filter(s => s.id !== action.payload)
      };
    }
    case "general.archive": {
      return {
        ...state,
        archives: [...state.archives.filter(s => s.id !== action.payload.id), action.payload]
      };
    }
    case "general.archive.remove": {
      return {
        ...state,
        archives: state.archives.filter(s => s.id !== action.payload)
      };
    }
    default:
      return state;
  }
};

export default general;
