import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Button, Modal, Space, Input, Tag } from "antd";
import { PlusOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { CustomTable, RowAction } from "../../components";
import { shipment as shipmentApi } from "../../apis";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const { isMobile } = useSelector(state => state.general);
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const onAction = (key, record) => {
    switch (key) {
      case "edit" : {
        history.push(`/order/shipment/edit/${record.id}`);
        break;
      }
      case "remove" : {
        Modal.confirm({
          title     : "Баталгаажуулах",
          icon      : <ExclamationCircleOutlined />,
          content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
          okText    : "Устгах",
          cancelText: "Болих",
          className : "btn-custom-class",
          onOk      : async () => {
            try {
              await shipmentApi.remove(record.id);
              myTableRef.current.reload();
            } catch (error) {
              console.log(error);
            }
          },
        });
        break;
      }
      case "shipment": {
        Modal.confirm({
          title     : "Баталгаажуулах",
          icon      : <ExclamationCircleOutlined />,
          content   : "Та татан авахдаа итгэлтэй байна уу!!!",
          okText    : "Баталгаажуулах",
          cancelText: "Болих",
          className : "btn-custom-class",
          onOk      : async () => {
            try {
              await shipmentApi.status(record.id, "COMPLETED");
              myTableRef.current.reload();
            } catch (error) {
              console.log(error);
            }
          },
        });
        break;
      }
      default:
    }
  };

  const orderStatus = (row) => {
    switch (row.shipment_status) {
      case "NEW":
        return <Tag style={{ float: "right" }}>Үйлдвэрээс гарсан</Tag>;
      default:
        return <Tag style={{ float: "right" }} color='blue'>Татан авсан</Tag>;
    }
  };


  const checkCancel = (item) => {
    if (item?.shipment_status === "NEW")
      return "Цуцлах";

    return false;
  };

  const checkShipment = (item) => {
    if (item?.shipment_status === "NEW")
      return "Татан авсан";

    return false;
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Захиалга (Татан авалт)"
        // extra={[
        //   <Button key={0} shape={isMobile && "circle"} type="primary" icon={<PlusOutlined />} onClick={() => history.push("/order/shipment/new")}>{!isMobile && "Татан авалт"}</Button>
        // ]}
      />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ width: 250 }}
            onChange={e => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <InoutTable
          ref={myTableRef}
          filters={filters}
          loadData={shipmentApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                <th className="ant-table-cell" rowSpan={2}>Бэлтгэн нийлүүлэгч</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" rowSpan={2}>Ажилтан</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowKey}</td>
                  <td className="ant-table-cell" rowSpan={2}><Tag color="red">{row.code}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <a>{row?.supplier?.name || "-"}</a>
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                  <td className="ant-table-cell" width="300">
                    <a>{`${row?.user?.first_name || "-"} ${row?.user?.last_name || "-"}`}</a>
                    {orderStatus(row)}
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        edit    : "Засварлах",
                        remove  : checkCancel(row),
                        shipment: checkShipment(row)
                      }} onClick={(key) => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell" width={140}>{moment(row.created_at).format("YYYY.MM.DD HH:mm")}</td>
                </tr>
              </tbody>
            );
          }} />
      </PageContent>
    </PageContainer>
  );
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

