import React from "react";
import { Space, Tabs, DatePicker, Card, Button, Spin, Radio } from "antd";
import { Line } from "@ant-design/charts";
import { order as orderApi } from "../../../apis";
import moment from "moment";
import styled from "styled-components";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const CHARTS = [{
  title: "Бүгд",
  key  : "ALL"
}];

export default () => {
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [filters, setFilters] = React.useState({
    order_status: "ALL",
    type        : "DAY",
    start_date  : "",
    end_date    : "",
  });

  const loadData = async () => {
    setLoading(true);

    let res = await orderApi.dashboard(filters);

    setResults(res.map(item => ({
      ...item,
      date: moment(item.date).format("YYYY-MM-DD")
    })));
    setLoading(false);
  };

  const onChange = (type) => {
    setFilters(state => ({ ...state, order_status: type }));
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  React.useEffect(() => {
    loadData();
  }, [filters]);

  return (
    <Container isMainContainer title="Борлуулалт">
      <Spin spinning={loading}>
        <Tabs
          activeKey={filters.order_status}
          onChange={onChange}
          tabBarExtraContent={(
            <Space size={15}>
              <Radio.Group value={filters.type} onChange={e => setFilters(state => ({ ...state, type: e.target.value }))}>
                <Radio.Button value="DAY">Хоног</Radio.Button>
                <Radio.Button value="WEEK">7 хоног</Radio.Button>
                <Radio.Button value="MONTH">1 сар</Radio.Button>
                <Radio.Button value="YEAR">1 жил</Radio.Button>
              </Radio.Group>
              <RangePicker value={filters.start_date && filters.end_date ? [moment(filters.start_date), moment(filters.end_date)] : [null, null]} onChange={onChangeRangeDate} allowClear={false} />
              <Button type="default" onClick={() => setFilters(state => ({ ...state, start_date: null, end_date: null }))}>Цэвэрлэх</Button>
            </Space>
          )}>

          {CHARTS.map((item, index) => {
            return (
              <TabPane key={index} tab={item.title} key={item.key}>
                <TabContainer>
                  <Line style={{ width: "100%" }} {...{
                    data       : results,
                    xField     : "date",
                    yField     : "value",
                    isGroup    : true,
                    isStack    : true,
                    groupField : "name",
                    seriesField: "name",
                    legend     : { position: "top-left" },
                  }} height={400} forceFit={true} />
                </TabContainer>
              </TabPane>
            );
          })}
        </Tabs>
      </Spin>
    </Container>
  );
};
const TabContainer = styled.div `
 display: flex;
 justify-content: space-between;
 flex-direction: row;
 margin-top: 20px;

  .empty-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled(Card)`
  border: 1px solid#e8e8e8;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.08);
  margin-left: 5px;
  .ant-tabs-nav{
    border-bottom: none;
  }
  .ant-card-body {
    border-radius:5px;
    padding: 15px 22px 15px 22px;
    
  }
`;