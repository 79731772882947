import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Dropdown, Menu } from "antd";
import { DownOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";
import { ReactComponent as LogoSVG } from "../../assets/logo.svg";
import NoImage from "../../assets/noimage.png";

const { Header } = Layout;

export default () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { isMobile } = useSelector(state => state.general);

  const logout = () => {
    dispatch({
      type: "auth.logout",
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <UserOutlined /> Миний мэдээлэл
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>
        <LogoutOutlined /> Гарах
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className={styles.header}>
      <div className={styles.navbar}>
        <div className={styles.logo} >
          <LogoSVG />
        </div>
      </div>
      {isMobile ? (<div className={styles.userWrap}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className={styles.userAction}>
            <UserOutlined/>
          </div>
        </Dropdown>
      </div>) : (<div className={styles.userInfo}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className={styles.userAction}>
            <div className={styles.avatar}>
              <img src={NoImage} alt="Avatar" />
            </div>
            <div className={styles.username}>
              {`${user?.last_name} ${user?.first_name}`}
              <DownOutlined />
            </div>
          </div>
        </Dropdown>
      </div>)}
    </Header>
  );
};
