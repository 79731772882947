import React from "react";
import { Form, FormItem, Input, Cascader, Select } from "formik-antd";
import { Button, Form as AntForm, message as notify } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { product as productApi } from "../../apis";
import { ImageUpload } from "../../components";
import styled from "styled-components";

const { Option } = Select;
const phoneReqExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const FormSchema = () => Yup.object().shape({
  categories: Yup.array().required("Заавал бөглөнө!"),
  name      : Yup.string().required("Заавал бөглөнө!"),
  code      : Yup.string().required("Заавал бөглөнө!"),
  barcode   : Yup.string().optional().nullable(),
  unit      : Yup.string().required("Заавал бөглөнө!"),
  price     : Yup.number().required("Заавал бөглөнө!"),
  sale_price: Yup.number().required("Заавал бөглөнө!"),
  image     : Yup.string().required("Заавал бөглөнө!")
});

export default ({ action }) => {
  const history = useHistory();
  const { categoryTrees, units } = useSelector(state => state.general);
  const [data] = React.useState({
    categories : undefined,
    user_id    : undefined,
    category_id: undefined,
    name       : undefined,
    code       : undefined,
    barcode    : undefined,
    image      : undefined,
    unit       : undefined,
    price      : undefined,
    sale_price : undefined,
    quantity   : undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const onSubmit = async (values) => {
    let categoryId = values.categories.pop();

    if (action && action[0] === "update") {
      await productApi.update({
        ...values,
        category_id: categoryId
      });
    } else {
      await productApi.create({
        ...values,
        category_id: categoryId
      });
    }

    history.push("/product/item");
    notify.success("Таны хүсэлт амжилттай!");
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form {...formItemLayout}>
            <FormItem name="categories" label="Ангилал" required>
              <Cascader fieldNames={{ label: "name", value: "id" }} options={categoryTrees} name="categories" />
            </FormItem>
            <FormItem name="name" label="Барааны нэр" required>
              <Input name="name"/>
            </FormItem>
            <FormItem name="code" label="Дотоод код" required>
              <Input name="code" />
            </FormItem>
            <FormItem name="barcode" label="Баар код" required>
              <Input name="barcode" />
            </FormItem>
            <FormItem label="Зураг" name="image" required>
              <ImageUpload action="/api/general/upload" name="image" />
            </FormItem>
            <FormItem name="price" label="Үнэ">
              <Input type="number" name="price" placeholder="Үнэ" min={0} />
            </FormItem>
            <FormItem name="sale_price" label="Б.Үнэ">
              <Input type="number" name="sale_price" placeholder="Б.Үнэ" min={0} />
            </FormItem>
            <FormItem name="unit" label="Хэмжих нэгж" required>
              <Select name="unit" placeholder="Хэмжих нэгж">
                {units?.map((item, index) => {
                  return <Option key={index} value={item.code}>{item.short}</Option>;
                })}
              </Select>
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
              <Button className="button" type="default" onClick={() => history.push("/product/list")}>Болих</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  width: 80%;
  @media (max-width: 1000px){
    width:100%;
  }
`;