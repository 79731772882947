import React from "react";
import { Formik, useFormikContext } from "formik";
import { Form, FormItem, Input as FormikInput } from "formik-antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Form as AntForm, Button, Input, Tag, Modal, Spin, message as notify, Avatar, Tooltip } from "antd";
import { CustomTable, MyTable, RowAction, DrawerRangePicker, ProductItem } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { order as orderApi, calculation as calculationApi } from "../../apis";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import * as Yup from "yup";

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

const footerLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

const FormLineSchema = () => Yup.array().of(Yup.object({
  name  : Yup.string().required("Заавал бөглөнө!"),
  amount: Yup.number().required("Заавал бөглөнө!"),
  note  : Yup.string().required("Заавал бөглөнө!")
}));

const FormSchema = (type) => () => Yup.object().shape({
  note: Yup.string().required("Заавал бөглөнө!"),
  ...(type === "canceled" ? {} : {
    debt_lines: FormLineSchema()
  })
});

export default () => {
  const myTableRef = React.useRef();
  const submitRef = React.useRef();
  const history = useHistory();
  const params = useParams();
  const { isMobile, s3host } = useSelector(state => state.general);
  const [action, setAction] = React.useState();
  const [data, setFormData] = React.useState();
  const [filters, setFilters] = React.useState({
    code: "",
    type: ""
  });

  const orderStatus = (item) => {
    switch (item.order_status) {
      case "COMPLETED": {
        return <Tag color='green'>{item.type === "ORDER" ? "Хүргэгдсэн" : "Борлуулагдсан"}</Tag>;
      }
      case "PENDING": {
        return <Tag color='yellow'>{item.type === "ORDER" ? "Хүргэлтэд гарсан" : "Хүлээгдэж байна"}</Tag>;
      }
      case "CANCELED": {
        return <Tag color='red'>Цуцлагдсан</Tag>;
      }
      default:
        return <Tag>Шинэ захиалга</Tag>;
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onCancel = (loaded) => {
    if (loaded) {
      const abortController = new AbortController();
      const signal = abortController.signal;

      reload(signal);
    }

    setAction();
  };

  const onSave = async () => {
    let valid = await submitRef.current.submitForm();

    if (valid) {
      Modal.confirm({
        title  : "Баталгаажуулах",
        icon   : <ExclamationCircleOutlined />,
        content: "Тооцоог дуусгахдаа итгэлтэй байна уу!",
        onOk   : async () => {
          await calculationApi.draft(data.id, {
            debt_lines: valid.debt_lines.map(line => ({
              type  : line.type,
              name  : line.name,
              amount: line.amount,
              note  : line.note
            })),
            note: valid.note
          });

          notify.success("Таны хүсэлт амжилттай!");
          history.push("/cashier/sales");
        }
      });
    } else {
      notify.warning("Тооцооны мэдээлэл дутуу байна!");
    }
  };

  const reload = React.useCallback(async (signal) => {
    let res = await calculationApi.get(params.id, { signal });

    setFilters(state => ({
      ...state,
      archive_id: res.archive_id
    }));
    setFormData(state => ({
      ...state,
      ...res,
      debt_lines: res.debt_lines?.map(line => ({
        id         : line.id,
        name       : line.name,
        type       : line.type,
        debt_status: line.debt_status,
        amount     : parseFloat(line.amount),
        note       : line.note || undefined
      })),
      note: res.note || undefined
    }));
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);


  return (
    <PageContainer>
      <PageHeader title="Тооцоо хийх" extra={[
        <Button icon={isMobile && <CloseOutlined />} shape={isMobile && "circle"} type="default" onClick={() => history.push("/calculation")}>{!isMobile && "Болих"}</Button>,
        <Button icon={isMobile && <SaveOutlined />} shape={isMobile && "circle"} type="primary" onClick={() => onSave()}>{!isMobile && "Хадгалах"}</Button>
      ]} />

      <PageContent>
        {data ? (() => {
          return (
            <InoutTable
              ref={myTableRef}
              filters={filters}
              loadData={orderApi.list("calculation")}
              rowKey={(record) => record.id}
              thead={() => (
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" rowSpan={2}>№</th>
                    <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                    <th className="ant-table-cell" rowSpan={2}>Тайлбар</th>
                    <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                    <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                    <th className="ant-table-cell" colSpan={4}>Төлбөр</th>
                    <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                    <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                  </tr>
                  <tr>
                    <th className="ant-table-cell">Нийт дүн</th>
                    <th className="ant-table-cell">Картаар</th>
                    <th className="ant-table-cell">Бэлнээр</th>
                    <th className="ant-table-cell">Дансаар</th>
                  </tr>
                </thead>
              )} tbody={(row, index) => {
                return (
                  <tbody key={index} className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                      <td className="ant-table-cell" rowSpan={2}><Tag color={row?.type === "PURCHASE" ? "blue" : "warning"}>{row.code}</Tag></td>
                      <td className="ant-table-cell" rowSpan={2}>{row?.note || "-"}</td>
                      <td className="ant-table-cell">{row?.archive?.name || "-"}</td>
                      <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.card_amount)}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.cash_amount)}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.account_amount)}</td>
                      <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderStatus(row)}</td>
                      <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.created_at).format("YYYY.MM.DD HH:mm")}</td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"><a>{`${row?.user?.first_name || "-"} ${row?.user?.last_name || "-"}`}</a></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="ant-table-cell" colSpan={15}>
                        {row.products.map((item, index) => <ProductItem key={index} image={item?.product?.image} name={item?.product?.name} quantity={item.quantity} code={item?.product?.code} />)}
                      </td>
                    </tr>
                  </tbody>
                );
              }} />
          );
        })() : <Spin style={{ width: "100%" }} loading={!data} />}
      </PageContent>

      {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}

      {data && (
        <CalculationLines>
          <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={FormSchema()}
            onSubmit={() => {}}>
            {({ values, setFieldValue }) => {
              const onAddLine = (type) => {
                setFieldValue("debt_lines", [
                  ...values.debt_lines, {
                    name       : undefined,
                    type       : type,
                    debt_status: undefined,
                    amount     : undefined,
                    note       : undefined
                  }
                ]);
              };

              const columns = useHeader({
                values,
                onClick: (key, index) => {
                  switch (key) {
                    case "remove" : {
                      setFieldValue("debt_lines", values.debt_lines.filter((p, i) => i !== index));
                      break;
                    }
                    default:
                  }
                },
              });

              return (
                <Form layout="horizontal">
                  <PageHeader title="Орлого & Зарлага" extra={[
                    <Button key={0} type="danger" icon={<PlusOutlined />} onClick={() => onAddLine("CREDIT")}>Зарлага</Button>
                  ]} />
                  <PageContent>
                    <CalculationLineTable
                      ref={myTableRef}
                      items={values.debt_lines}
                      columns={columns}
                      pagination={false}
                      rowKey={(record) => record.id} />

                    <CalculationFooter {...footerLayout}>
                      <FormItem name="quantity" label="Барааны тоо">
                        <Input type="text" value={data.order_quantity} disabled />
                      </FormItem>
                      <FormItem name="total_amount" label="Нийт дүн">
                        <Input type="text" value={tugrug(data.total_amount)} disabled />
                      </FormItem>
                      <FormItem name="archive_id" label="Агуулах" required>
                        <Input type="text" value={data.archive?.name} disabled />
                      </FormItem>
                      <FormItem name="staff_user_id" label="Ажилтан" required>
                        <Input type="text" value={`${data.staff_user?.last_name || "-"} ${data.staff_user?.first_name || "-"}`} disabled />
                      </FormItem>
                    </CalculationFooter>

                    <SubmitForm ref={submitRef} />
                  </PageContent>
                </Form>
              );
            }}
          </Formik>
        </CalculationLines>
      )}
    </PageContainer>
  );
};
const useHeader = ({ onClick, values }) => {
  return [{
    title : "№",
    width : 10,
    render: (record) => {
      return record.rowIndex;
    }
  }, {
    title : "Нэр",
    render: (record, i, index) => {
      if (record.type === "DEBIT")
        return record.name;

      return (
        <FormItem name={`debt_lines[${index}].name`} required>
          <FormikInput name={`debt_lines[${index}].name`} type="text" placeholder="Нэр" />
        </FormItem>
      );
    }
  }, {
    title : "Төрөл",
    width : 140,
    render: record => {
      return record?.type === "DEBIT" ? <Tag color="green">ОРЛОГО</Tag> : <Tag color="red">ЗАРЛАГА</Tag>;
    }
  }, {
    title : "Дүн",
    width : 200,
    render: (record, i, index) => {
      if (record.type === "DEBIT")
        return tugrug(record.amount);

      return (
        <FormItem name={`debt_lines[${index}].amount`} required>
          <FormikInput name={`debt_lines[${index}].amount`} type="number" placeholder="Дүн" />
        </FormItem>
      );
    }
  }, {
    title : "Тайлбар",
    width : 300,
    render: (record, i, index) => {
      if (record.type === "DEBIT")
        return record.note;

      return (
        <FormItem name={`debt_lines[${index}].note`} required>
          <FormikInput name={`debt_lines[${index}].note`} type="text" placeholder="Тайлбар" />
        </FormItem>
      );
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record, i, index) => {
      const checkRemove = () => {
        if (record.type === "DEBIT")
          return false;

        return "Устгах";
      };

      return (
        <RowAction
          actions={{
            remove: checkRemove()
          }}
          onClick={(key) => onClick(key, index)}
        />
      );
    }
  }];
};
const CalculationLines = styled.div``;
const CalculationFooter = styled(AntForm)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;
const CalculationLineTable = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
  }
  .ant-form-item {
    margin-bottom: 0!important;
  }
  .ant-form-item-explain {
    display: none!important;
  }
`;
const Canceled = styled.div`
  .alert {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    svg {
      display: flex;
      font-size: 22px;
      color: #faad14;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
`;

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 
  flex-direction: row;  

  @media only screen and (max-width: 735px) {  
    flex-direction: column;  
    .wrap {
      display: flex;
      flex-direction: column;
    }
    .ant-space-item {
      width: 100% !important;
    }
    .item {  
      width: 100% !important; 
    } 
  }
  @media only screen and (max-width: 472px) {   
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    } 
    
  }
  @media only screen and (max-width: 1292px) {  
    flex-direction: column;   
    .margin-top {
      margin-top: 8px;
      padding-left: 0px !important; 
    }  
  } 
`;