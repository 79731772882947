import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Confirm } from "../../components/Design";
import { Row, Col, Input, Pagination, Button, Modal, Spin, message as notify, Drawer as DrawerAnt, message } from "antd";
import { product as productApi } from "../../apis";
import { CloseCircleOutlined, SearchOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { tugrug } from "../../utils";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import Product from "./components/Product";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { order as orderApi } from "../../apis";

export default () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { archives, isMobile } = useSelector(state => state.general);
  const [action, setAction] = React.useState();
  const [filters, setFilters] = React.useState({
    offset: {
      page : 1,
      limit: 100
    },
    filter: {
      query     : "",
      archive_id: params.archive_id
    }
  });
  const [{ rows, count }, fetching] = useFetch(productApi.list, filters)({ rows: [], count: 0 });
  const { carts, is_sale_price } = useSelector(state => state.checkout);
  const [query, setQuery] = React.useState("");
  const [visible, setVisible] = React.useState(false);

  const onAddCart = (item) => {
    if (item.quantity <= 0)
      return message.warning("Барааны үлдэгдэл хүрэлцэхгүй байна!");

    dispatch({
      type   : "cart.add",
      payload: {
        ...item,
        product_id: item.id,
        qty       : 1
      }
    });
  };

  const getCartCount = () => {
    return carts.reduce((accumulator, iterator) => {
      return accumulator + iterator.qty;
    }, 0);
  };

  const onPagination = (page) => {
    setFilters(state => ({
      ...state,
      offset: {
        ...state.offset,
        page
      }
    }));
  };

  const getTotalAmount = () => {
    return tugrug(carts.reduce((accumulator, iterator) => {
      return accumulator + (iterator.qty * iterator.price);
    }, 0));
  };

  const onCancel = () => {
    setAction();
  };

  const onConfirm = () => {
    if (carts.length > 0)
      setAction(["checkout"]);
  };

  const onSubmit = async (data) => {
    await orderApi.purchase({
      archive_id      : params.archive_id,
      card_amount     : data.is_card === false && "0" || data.card_amount,
      cash_amount     : data.is_cash === false && "0" || data.cash_amount,
      account_amount  : data.is_account === false && "0" || data.account_amount,
      discount_amount : data.discount_amount,
      surcharge_amount: data.surcharge_amount,
      coupon_code     : data.coupon_code,
      is_sale_price   : is_sale_price,
      products        : carts.map(cart => ({
        product_id : cart.product_id,
        quantity   : cart.qty,
        coupon_code: cart.coupon_code
      }))
    });

    dispatch({
      type   : "cart.clear",
      payload: {
        type: "PURCHASE"
      }
    });

    onCancel();

    notify.success("Таны хүсэлт амжилттай!");
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.filter.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({
          ...state,
          filter: {
            ...state.filter,
            query
          }
        }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    if (params.archive_id !== filters?.filter?.archive_id) {
      setFilters(state => ({
        ...state,
        filter: {
          ...state.filter,
          archive_id: params.archive_id
        }
      }));

      dispatch({
        type: "cart.clear"
      });
    }
  }, [params]);

  return (
    <PageContainer>
      <PageHeader title={archives.find(a => a.id === params.archive_id)?.name || "Борлуулалт хийх"} extra={[isMobile && <CartButton onClick={() => setVisible(!visible)}><Button type="primary" shape="circle" icon={getCartCount() || <ShoppingCartOutlined />} /> {getTotalAmount()}  </CartButton> ]} />
      <PageContent>
        <Row gutter={20}>
          <Col span={isMobile? (24) : (16)}>
            <PageFilter>
              <Input name="code" size="large" placeholder="Эндээс бүхнийг хайж олох ..."prefix={<SearchOutlined />} onChange={e => setQuery(e.target.value)} allowClear />
            </PageFilter>

            <Spin spinning={fetching}>
              <div className="product-grid">
                {(rows || []).map((item, index) => (
                  <div key={index} className="product-item">
                    <Product product={item} filters={filters} onAddCart={onAddCart} />
                  </div>
                ))}
              </div>
            </Spin>

            {count > 0 && <Pagination style={{ marginTop: 20 }} current={query.page} total={count} onChange={onPagination} />}
          </Col>
          <Col span={8} style={{ display: isMobile && "none" }}>
            <div className="emall-card">
              <Cart getCartCount={getCartCount} />
              <Confirm className="primary" style={{ padding: 5 }}>
                <Button size="large" type="primary" onClick={() => onConfirm()} block>
                  <div className="name">
                  Тооцоо хийх ({getCartCount()})
                    <div>Баталгаажуулах</div>
                  </div>
                  <div className="amount">{getTotalAmount()}</div>
                </Button>
              </Confirm>
            </div>
          </Col>
        </Row>
      </PageContent>

      <Modal
        title="Тооцоо хийх"
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Checkout onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>

      <Drawer
        title={archives.find(a => a.id === params.archive_id)?.name || "Борлуулалт хийх"}
        placement="right"
        visible={visible}
        width={300}
        closeIcon={<CloseCircleOutlined />}
        style={{ padding: 0 }}
        onClose={() => setVisible(!visible)}>
        <div className="emall-card">
          <Cart getCartCount={getCartCount} />
          <Confirm className="primary" style={{ padding: 5 }}>
            <Button size="large" type="primary" onClick={() => onConfirm()} block>
              <div className="name">
                  Тооцоо хийх
                <div>Баталгаажуулах</div>
              </div>
              <div className="amount">{getTotalAmount()}</div>
            </Button>
          </Confirm>
        </div>
      </Drawer>
    </PageContainer>
  );
};

const Drawer = styled(DrawerAnt) `
  .ant-drawer-body {
    padding: 0 !important;
  }
  .emall-card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;
const CartButton = styled.div `
  padding-right: 10px;
  background-color: #e8e8e8;
  font-weight: 600;
  border-radius: 20px;
`;
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 20px;
`;