import React from "react";
import { Button, Input, Empty, Switch, Tag } from "antd";
import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { tugrug } from "../../../utils";
import styled from "styled-components";

export const CartItem = ({ item, action }) => {
  const dispatch = useDispatch();
  const { s3host } = useSelector(state => state.general);
  const { is_sale_price } = useSelector(state => state.checkout);

  const onRemoveCart = () => {
    dispatch({
      type   : "carts.remove",
      payload: {
        key : item.key,
        type: action[1]
      }
    });
  };

  const onQuantity = (item, count) => {
    if (item.qty + count > 0 && (item.qty + count <= item?.box_no?.qty || item.type === "ORDER"))
      dispatch({
        type   : "damage.add",
        payload: {
          ...item,
          qty : count,
          type: action[1]
        }
      });
  };

  return (
    <Item>
      <div className="image">
        <img src={`${s3host}/${item.image}`} />
      </div>
      <div className="product">
        <Tag color="black" style={{ marginBottom: 5 }}>{item.code}</Tag> ({item?.box_no?.box_no})
        <div className="info">
          <div className="price">{tugrug(is_sale_price ? item.sale_price : item.price)}</div>
          <div className="qty">x{item.qty}</div>
          <div className="price">{tugrug((is_sale_price ? item.sale_price : item.price) * item.qty)}</div>
        </div>
        <div className="stock">
          {item.type !== "ORDER" && <>Үлдэгдэл ({item?.box_no?.qty - item.qty || 0}) </>}<a className="remove" onClick={() => onRemoveCart()} href="#">Устгах</a>
        </div>
      </div>
      <div className="quantity">
        <Input
          value={item.qty}
          disabled
          addonAfter={<Button onClick={() => onQuantity(item, -1)} icon={<MinusOutlined />} />}
          addonBefore={<Button onClick={() => onQuantity(item, 1)} icon={<PlusOutlined />} />}
        />
      </div>
    </Item>
  );
};

export default ({ action }) => {
  const dispatch = useDispatch();
  const { carts, damage } = useSelector(state => state.checkout);

  const onClearCart = () => {
    dispatch({
      type   : "carts.clear",
      payload: {
        type: action[1]
      }
    });
  };

  return (
    <Cart>
      <div className="header">
        <h2>
          <div className="card_price">
            <div className="sale_price">
              Буцаах бараа
            </div>
          </div>
          <Button size="small" className="btn-clear-cart" onClick={onClearCart}><DeleteOutlined /></Button>
        </h2>
      </div>
      <div className="content">
        {(action && action[1] === "DAMAGE" ? damage : carts).map((item, index) => (<CartItem key={index} item={item} action={action} />))}
        {(action && action[1] === "DAMAGE" ? damage : carts).length === 0 && <Empty style={{ margin: 10 }} />}
      </div>
    </Cart>
  );
};

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  .image {
    width: 60px;
    min-width: 55px;
    margin-right: 10px;
    img {
      border-radius: 4px;
      width: 100%;
    }
  }
  .product {
    flex: 1;
    h4 {
      font-size: 13px;
      font-weight: 400;
      color: rgb(36, 36, 36);
      display: block;
      line-height: 1.3;
      margin-bottom: 10px;
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      .price {
        font-size: 13px;
        color: rgb(51, 51, 51);
        font-family: arial, serif;
        white-space: nowrap;
        font-weight: 600;
        margin: 0px;
        .sale {
          font-size: 13px;
          text-decoration: line-through;
          color: #717171;
          margin-left: 5px;
        }
      }
      .qty {
        background-color: rgb(243, 243, 243);
        font-size: 12px;
        margin: 0px 10px;
        padding: 2px 4px;
        border-radius: 4px;
        font-weight: 500;
      }
    }
    .stock {
      font-size: 12px;
      color: #333;
      font-weight: 400;
      .remove {
        font-size: 12px;
        color: red;
        margin-left: 10px;
        font-weight: 500;
        &:hover {
          color: #000!important;
          text-decoration: underline;
        }
      }
    }
  }
  .quantity {
    width: 40px;
    border-radius: 6px;
    border: none;
    width: 24px;
    .ant-input-wrapper {
      display: flex;
      flex-direction: column;
    }
    .ant-input-group, .ant-input {
      text-align: center;
      background: transparent;
      cursor: pointer;
      color: #333;
      font-weight: 400;
      font-size: 15px;
      border: none;
      padding: 0;
    }
    .ant-input-group-addon {
      padding: 0;
      border: none!important;
      width: 100%;
      background: transparent;
      width: 24px;
      button {
        border: none;
        line-height: 0;
        border-radius: 6px;
        color: #6c757d;
        width: 24px;
        height: 24px;
        .anticon {
          font-size: 18px;
        }
      }
    }
  }
`;
const Cart = styled.div`
  display: flex;
  flex-direction: column;
  .card_price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .count {
      margin: 0 5px;
    }
  }
  .sale_price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
  .count {
    font-size: 14px;
    background: #d4d4d4;
    border-radius: 10px;
    padding: 0 8px;
    font-weight: 600;
    line-height: 22px;
    color: #3c3c3c;
  }
  .btn-clear-cart {
    font-size: 13px;
    border-radius: 4px;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
`;
