import http from "../utils/request";

export const request = (type, data) => http.post(`/api/request/${type}`, data);
export const confirm = (id) => http.put(`/api/request/${id}/confirm`);
export const update = (id, data) => http.put(`/api/request/${id}/update`, data);
export const cancel = (id) => http.put(`/api/request/${id}/cancel`);
export const clear = () => http.put("/api/request/clear");
export const list = (data) => http.get("/api/inout", data);
export const get = (id) => http.get(`/api/inout/${id}`);
export const init = (data) => http.get("/api/inout/init", data);
export const fileExport = () => http.get("/api/inout/export/file");
export const searchBox = (data) => http.get("/api/inout/box/search", data);
export const inoutBox = (data) => http.post("/api/inout/box", data);
export const importExcel = (data) => http.post(`/api/inout/import/file/${data.id}/${data.supplierId}`, data);
export const exportExcel = (id) => http.get(`/api/inout/${id}/export/excel`);