export default {
  primary     : "",
  primaryDark : "#372C58",
  primaryLight: "#423A6F",
  green       : "#B6D7A8",
  pink        : "#FF788E",
  pinkLight   : "#988CCD",
  pinkDark    : "#423a6f",
  purpleLight : "#7C6EFA",
  red         : "#FF2747",
  white       : "#FFFFFF",
  grey        : "#d9d9d9",
};