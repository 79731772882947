import React from "react";
import { useParams } from "react-router-dom";
import { order as orderApi } from "../../apis";
import { Spin } from "antd";
import Form from "./Form";

export default () => {
  const params = useParams();
  const [action, setAction] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const reload = React.useCallback(async (signal) => {
    let res = await orderApi.get(params.id, { signal });

    const normalize = (data) => {
      return Object.keys(data).reduce((accumulator, iterator) => {
        return {
          ...accumulator,
          [iterator]: (data[iterator] === "" || data[iterator] === null) ? undefined : data[iterator]
        };
      }, {});
    };

    setProducts(res.products);
    setAction(["update", normalize({
      id                     : res.id,
      note                   : res.note || undefined,
      shipping_amount        : res.shipping_amount,
      shipping_additional_fee: res.shipping_additional_fee,
      shipping_phone         : res.shipping_phone,
      shipping_phone_second  : res.shipping_phone_second,
      shipping_address       : res.shipping_address,
      shipping_date          : res.shipping_date,
      customer_id            : res.customer_id,
      cash_amount            : res.cash_amount,
      card_amount            : res.card_amount,
      account_amount         : res.account_amount,
      surcharge_amount       : res.surcharge_amount,
      discount_amount        : res.discount_amount,
      is_sale_price          : res.is_sale_price,
      products               : res.products.map(p => ({
        product_id : p.id,
        price      : p.price,
        sale_price : p.sale_price,
        quantity   : p.quantity,
        is_shipment: !!p.is_shipment
      }))
    })]);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <Spin spinning={!action[1]}>
      {action[1] && <Form action={action} products={products} />}
    </Spin>
  );
};