import React from "react";
import { Menu, Modal, Card } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CalculatorOutlined, FormOutlined, HomeOutlined, ReloadOutlined, PieChartOutlined, SettingOutlined, ShoppingCartOutlined, AppstoreOutlined, TeamOutlined } from "@ant-design/icons";
import usePermission from "../../hooks/usePermission";
import styles from "./styles.module.scss";
import { archive } from "../../apis";

const { SubMenu } = Menu;
const { Meta } = Card;

const NavBar = ({ isMobile }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { checkPermission } = usePermission();
  const { user } = useSelector(state => state.auth);
  const { archives } = useSelector(state => state.general);
  const { shipping } = useSelector(state => state.checkout);
  const [action, setAction] = React.useState([]);
  const [actionBox, setActionBox] = React.useState([]);
  const [visible, setVisible] = React.useState([]);

  let selected = (history.location.pathname.split("/")[1] || "") + "-" + (history.location.pathname.split("/")[2] || "");

  const onCancel = () => {
    setAction();
    setActionBox();
    setVisible();
  };

  const onPos = (key) => {
    if (!user.staff_archive && (checkPermission("FINANCE") || checkPermission("ARCHIVE") || checkPermission("POS"))) {
      setAction([key]);
    } else {
      dispatch({
        type   : "shipping.update",
        payload: {
          archive_id: user?.staff_archive?.id
        }
      });

      history.push(`/pos/${key}/${user?.staff_archive?.id}`);
    }
  };

  const onBox = (key) => {
    if (!user.staff_archive && (checkPermission("FINANCE") || checkPermission("ARCHIVE") || checkPermission("POS"))) {
      setActionBox([key]);
    } else {
      dispatch({
        type   : "shipping.update",
        payload: {
          archive_id: user?.staff_archive?.id
        }
      });

      history.push(`/pos/${key}/${user?.staff_archive?.id}`);
    }
  };

  const onDamage = (key) => {
    if (!user.staff_archive) {
      setVisible([key]);
    } else {
      dispatch({
        type   : "shipping.update",
        payload: {
          archive_id: user?.staff_archive?.id
        }
      });

      history.push(`/${key}/${user?.staff_archive?.id}`);
    }
  };

  return (
    <>
      <Menu
        selectedKeys={[...history.location.pathname.split("/"), selected]}
        className={styles.menu}
        mode="inline"
        theme="light"
        inlineCollapsed={isMobile}>
        {checkPermission("DASHBOARD") && (
          <Menu.Item key="-" icon={<PieChartOutlined />}>
            Дашбоард <NavLink to="/" />
          </Menu.Item>
        )}

        {checkPermission("CUSTOMER") && (
          <Menu.Item key="customer-list" icon={<TeamOutlined />}>
            Харилцагч <NavLink to="/customer/list" />
          </Menu.Item>
        )}

        {checkPermission("POS") && (
          <SubMenu key="pos" title="Борлуулалт" icon={<AppstoreOutlined />}>
            <Menu.Item key="pos-part" onClick={() => onPos("part")}>
                Касс
            </Menu.Item>
            <Menu.Item key="pos-sales">
                Жагсаалт <NavLink to="/pos/sales" />
            </Menu.Item>
          </SubMenu>
        )}

        <SubMenu key="damage" title="Акталсан бараа" icon={<ReloadOutlined />}>
          <Menu.Item key="damage-part" onClick={() => onDamage("damage")}>
            Агуулах
          </Menu.Item>
          <Menu.Item key="damage-sales">
            Жагсаалт <NavLink to="/damage/list" />
          </Menu.Item>
        </SubMenu>

        {checkPermission("ORDER") && (
          <SubMenu key="order" title="Захиалга" icon={<ShoppingCartOutlined />}>
            <Menu.Item key="order-list">
              Захиалга <NavLink to="/order/list" />
            </Menu.Item>
            <Menu.Item key="order-supplier">
              Бэлтгэн нийлүүлэгч <NavLink to="/order/supplier" />
            </Menu.Item>
            <Menu.Item key="order-shipment">
               Татан авалт <NavLink to="/order/shipment" />
            </Menu.Item>
          </SubMenu>
        )}

        {checkPermission("FINANCE") && (
          <SubMenu key="finance" title="Санхүү" icon={<CalculatorOutlined />}>
            <Menu.Item key="calculation">
                Тооцоо хийх <NavLink to="/calculation/init" />
            </Menu.Item>
            <Menu.Item key="report">
                Тооцооны тайлан <NavLink to="/finance/report" />
            </Menu.Item>
            <Menu.Item key="purchase">
                Борлуулалт тайлан <NavLink to="/finance/purchase" />
            </Menu.Item>
            <Menu.Item key="debt">
                Төлбөр тооцоо <NavLink to="/finance/debt" />
            </Menu.Item>
          </SubMenu>
        )}

        {checkPermission("ARCHIVE") && (
          <SubMenu key="archive" title="Агуулах" icon={<HomeOutlined />}>
            {checkPermission("SETTINGS") && (
              <Menu.Item key="archive-list">
                Агуулах жагсаалт <NavLink to="/archive/list" />
              </Menu.Item>
            )}
            <Menu.Item key="archive-inout">
                Агуулахын хөдөлгөөн <NavLink to="/archive/inout/list" />
            </Menu.Item>
            <Menu.Item key="archive-product">
                Бараа бүртгэл <NavLink to="/archive/product" />
            </Menu.Item>
            {checkPermission("FINANCE") && (
              <>
                <Menu.Item key="archive-counting">
                  Тооллого <NavLink to="/archive/counting" />
                </Menu.Item>
                <Menu.Item key="archive-report">
                  Тооллогын тайлан <NavLink to="/archive/report" />
                </Menu.Item>
              </>
            )}
            <Menu.Item key="box-swap" onClick={() => onBox("box")}>
              Хайрцагны хөдөлгөөн
            </Menu.Item>
          </SubMenu>
        )}

        {checkPermission("PRODUCT") && (
          <SubMenu key="product" title="Бараа" icon={<FormOutlined />}>
            <Menu.Item key="item">
                Бараа бүртгэл <NavLink to="/product/item" />
            </Menu.Item>
            <Menu.Item key="part2">
                Авто сэлбэг <NavLink to="/product/part2" />
            </Menu.Item>
            {checkPermission("FINANCE") && (
              <Menu.Item key="income">
                Орлогдох <NavLink to="/product/income" />
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {checkPermission("SETTINGS") && (
          <SubMenu key="settings" title="Тохиргоо" icon={<SettingOutlined />}>
            <Menu.Item key="settings-category">
                Ангилал <NavLink to="/settings/category" />
            </Menu.Item>
            <Menu.Item key="settings-supplier">
                Бэлтгэн нийлүүлэгч <NavLink to="/settings/supplier" />
            </Menu.Item>
            <Menu.Item key="settings-user">
                Хэрэглэгч <NavLink to="/settings/user" />
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>

      <Modal
        title={"Агуулах сонгох"}
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        {archives.map((archive, index) => {
          return (
            <Card key={index} style={{ marginBottom: 20 }} onClick={() => {
              history.push(`/pos/${action[0]}/${archive.id}`);

              setAction();
            }} className={archive?.id === shipping.archive_id && "active"} hoverable>
              <Meta title={archive.name} description={`${archive?.staff_user?.last_name || "-"} ${archive?.staff_user?.first_name || "-"}`} />
            </Card>
          );
        })}
      </Modal>

      <Modal
        title="Агуулах сонгох"
        visible={actionBox && actionBox[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        {archives.map((archive, index) => {
          return (
            <Card key={index} style={{ marginBottom: 20 }} onClick={() => {
              history.push(`/archive/${actionBox[0]}/${archive.id}`);

              setActionBox();
            }} className={archive?.id === shipping.archive_id && "active"} hoverable>
              <Meta title={archive.name} description={`${archive?.staff_user?.last_name || "-"} ${archive?.staff_user?.first_name || "-"}`} />
            </Card>
          );
        })}
      </Modal>
      <Modal
        title="Агуулах сонгох"
        visible={visible && visible[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false} >
        {archives.map((archive, index) => {
          return (
            <Card key={index} style={{ marginBottom: 20 }} onClick={() => {
              history.push(`/pos/${visible[0]}/${archive.id}`);
              setVisible();
            }} className={archive?.id === shipping.archive_id && "active"} hoverable>
              <Meta title={archive.name || "-"} description={`${archive?.staff_user?.first_name || "-"}${archive?.staff_user?.last_name || "-"}`} />
            </Card>
          );
        })}
      </Modal>
    </>
  );
};

export default NavBar;