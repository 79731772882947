import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined, MinusOutlined, CalendarOutlined } from "@ant-design/icons";
import { Button, DatePicker, Space, Input, Radio, Select, Tag, Modal, Form, message } from "antd";
import { CustomTable, DrawerRangePicker, RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { inout, inout as inoutApi } from "../../apis";
import { tugrug, formItemLayout, tailFormItemLayout } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import usePermission from "../../hooks/usePermission";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default () => {
  const myTableRef = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { checkPermission } = usePermission();
  const [action, setAction] = React.useState();
  const { archives, staffs, isMobile } = useSelector(state => state.general);
  const { user } = useSelector(state => state.auth);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    code: "",
    type: ""
  });

  const onAction = async (type, item) => {
    switch (type) {
      case "confirm": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Та баталгаажуулахдаа итгэлтэй байна уу!",
          onOk   : async () => {
            await inout.confirm(item.request_id);
            message.success("Таны хүсэлт амжилттай!");
            await myTableRef.current.reload();
          }
        });
        break;
      }
      case "cancel": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Та цуцлахдаа итгэлтэй байна уу!",
          okType : "danger",
          onOk   : async () => {
            await inout.cancel(item.request_id);
            message.success("Таны хүсэлт амжилттай!");
            await myTableRef.current.reload();
          }
        });
        break;
      }
      case "detail": {
        history.push(`/archive/inout/detail/${item.id}`);
        break;
      }
      default:
    }
  };

  const onInout = (type) => {
    if (user.role === "ADMIN") {
      setAction(["archive", type]);
    } else if (user?.staff_archive?.id) {
      history.push(`/archive/inout/${type}/${user?.staff_archive?.id}`);
    } else {
      dispatch({
        type: "auth.logout",
      });
    }
  };

  const requestStatus = (item) => {
    switch (item.request.request_status) {
      case "CONFIRMED": {
        return <Tag color='green'>Баталгаажсан</Tag>;
      }
      case "CANCELED": {
        return <Tag color='red'>Цуцлагдсан</Tag>;
      }
      default:
        return <Tag>Хүлээгдэж байна</Tag>;
    }
  };

  const checkConfirm = (item) => {
    if (!(item?.request?.request_status === "NEW"))
      return false;

    if (user.role === "ADMIN")
      return true;

    if (item?.request?.receiver_user_id === user.id && item?.request?.request_status === "NEW" && checkPermission("INOUT_IN", { write: true }))
      return true;

    return false;
  };

  const checkCancel = (item) => {
    if (!(item?.request?.request_status === "NEW"))
      return false;

    if (user.role === "ADMIN")
      return true;

    if (item?.request?.sender_user_id === user.id && item?.request?.request_status === "NEW")
      return true;

    return false;
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });

      setAction();
    }
  };

  const onCancel = () => {
    setAction();
  };

  const onClear = () => {
    setFilters({ code: "", type: "" });
    setAction();
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.code !== query)
      timeout = setTimeout(() => {
        setFilters({
          ...filters,
          code: query
        });
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Барааны хөдөлгөөн"
        extra={[
          checkPermission("ARCHIVE") && <Button type="primary" shape={isMobile && "circle"} onClick={() => onInout("out")}>{!isMobile && "Барааны хөдөлгөөн"}</Button>,
        ]}
      />

      <PageFilter>
        <Space className="wrap">
          <Space className="item">
            <Input className="item" name="code" placeholder="Дугаараар хайх ..." style={{ minWidth: 155 }} prefix={<SearchOutlined />} onChange={e => setQuery(e.target.value)} />
            {isMobile ? (<Select defaultValue="" className="item" style={{ width: 120 }} onChange={value => setFilters({ ...filters, type: value })}>
              <Option className="item" key="all" value="">Бүгд</Option>
              <Option className="item" key="IN" value="IN">Орлого</Option>
              <Option className="item" key="OUT" value="OUT">Зарлага</Option>
            </Select>) : (<Radio.Group className="item" style={{ minWidth: "220px" }} value={filters.type} onChange={e => setFilters({ ...filters, type: e.target.value })}>
              <Radio.Button className="item" value="">Бүгд</Radio.Button>
              <Radio.Button className="item" value="IN">Орлого</Radio.Button>
              <Radio.Button className="item" value="OUT">Зарлага</Radio.Button>
            </Radio.Group>)}
          </Space>
          <Space className="item">
            {user.role === "ADMIN" && (
              <Select className="item" value={filters.archive_id} placeholder="Агуулах" style={{ minWidth: 140 }} onChange={value => setFilters({ ...filters, archive_id: value })} allowClear>
                {archives.map((item, index) => {
                  return <Option key={index} value={item.id}>{item.name}</Option>;
                })}
              </Select>
            )}
            <Select className="item" value={filters.staff_id} placeholder="Ажилтан" style={{ minWidth: 140 }} onChange={value => setFilters({ ...filters, staff_id: value })} allowClear>
              {staffs?.map((item, index) => {
                return <Option key={index} value={item.id}>{`${item.last_name} ${item.first_name}`}</Option>;
              })}
            </Select>
          </Space>
        </Space>
        <Space className="margin-top">
          {isMobile ? (
            <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
          ) : (
            <RangePicker className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear={false} />)}
          <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
        </Space>

      </PageFilter>
      <PageContent>
        <InoutTable
          ref={myTableRef}
          filters={filters}
          loadData={inoutApi.list}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" width={10}>№</th>
                <th className="ant-table-cell">Дугаар</th>
                <th className="ant-table-cell">Тайлбар</th>
                <th className="ant-table-cell">Тоо дүн</th>
                <th className="ant-table-cell">Хүлээн авсан</th>
                <th className="ant-table-cell">Илгээсэн</th>
                <th className="ant-table-cell" width={100}>Үйлдэл</th>
              </tr>
            </thead>
          )} tbody={(row, index) => (
            <tbody key={index} className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                <td className="ant-table-cell" rowSpan={2}><Tag>{row.code}</Tag></td>
                <td className="ant-table-cell" rowSpan={2}>{row?.request?.note || "-"}</td>
                <td className="ant-table-cell" >{row.quantity || 0}</td>
                <td className="ant-table-cell"><Tag>{row?.to_archive?.name || "-"}</Tag></td>
                <td className="ant-table-cell">{row?.supplier?.name || row?.from_archive?.name || "-"} <span style={{ float: "right" }}>{requestStatus(row)}</span></td>
                <td className="ant-table-cell" rowSpan={2}>
                  <RowAction
                    icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                    actions={{
                      confirm: checkConfirm(row) && "Баталгаажуулах",
                      cancel : checkCancel(row) && "Цуцлах",
                      detail : "Дэлгэрэнгүй"
                    }} onClick={(key) => onAction(key, row)} />
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" >{tugrug(row.total_amount)}</td>
                <td className="ant-table-cell"><a>{`${row?.request?.confirmed_user?.first_name || "-"} ${row?.request?.confirmed_user?.last_name || "-"}`}</a></td>
                <td className="ant-table-cell"><a>{`${row?.request?.sender_user?.first_name} ${row?.request?.sender_user?.last_name}`}</a> <span style={{ float: "right" }}>{moment(row.created_at).format("YYYY.MM.DD HH:mm")}</span></td>
              </tr>
            </tbody>
          )} />
      </PageContent>

      {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}

      <Modal
        title={"Агуулах сонгох"}
        visible={action && action[0] === "archive"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form {...formItemLayout}>
          <Form.Item label="Агуулах" required>
            <Select placeholder="Агуулах" onChange={value => history.push(`/archive/inout/${action[1]}/${value}`)} allowClear>
              {archives?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
          {/* <Route path="/archive/inout/:type/:archive_id" component={Inout} exact /> */}
          <Form.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary">Болсон</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </Form.Item>
        </Form>
      </Modal>
    </PageContainer>
  );
};

const InoutTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 

  @media only screen and (max-width: 682px) { 
    .wrap { 
      display: flex;
      flex-direction: column; 
    }
    .ant-space-item {
      width: 100%;
    }
    .item { 
      width: 100% !important; 
    }
  }
  @media only screen and (max-width: 420px) { 
    flex-direction: row; 
    flex-direction: column;  
    .margin-top {
      margin-top: 10px;
    }
    .item { 
      width: 100% !important; 
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
    } 
  }
  
  @media only screen and (max-width: 1162px) {  
    flex-direction: column;   
    .margin-top {
      margin-top: 8px;
    }
  }
`;