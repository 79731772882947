import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Confirm } from "../../components/Design";
import { Row, Col, Input, Pagination, Button, Modal, Spin, message as notify, Drawer as DrawerAnt, message, Select, Radio, Avatar, Tag, Space } from "antd";
import { CloseCircleOutlined, PlusOutlined, SearchOutlined, ShoppingCartOutlined, ToolFilled, ExclamationCircleOutlined, MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import MyTable from "../../components/MyTable";
import { tugrug } from "../../utils";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { order as orderApi, part as partApi } from "../../apis";
import { useRef } from "react";
import Quantity from "./components/Quantity";
import moment from "moment";

const { Option } = Select;

export default () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const myTableRef = useRef();
  const { makers, archives, s3host } = useSelector(state => state.general);
  const [{ markets, models, bodies, years }, setSelection] = React.useState({ markets: [], models: [], bodies: [], years: [] });
  const { carts, orders, is_sale_price } = useSelector(state => state.checkout);
  const [drafts, setDrafts] = React.useState([]);
  const [position, setPosition] = React.useState({});
  const [action, setAction] = React.useState();
  const [order, setOrder] = React.useState();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });
  const [loading, setLoading] = React.useState();
  const [offset, setOffset] = React.useState({
    page : 1,
    limit: 10
  });
  const [{ type, payload }, setResult] = React.useState({ type: "PRODUCT", payload: [] });

  const onAddCart = (type, item, box) => {
    if (type === "PURHCASE" && item.quantity <= 0)
      return message.warning("Барааны үлдэгдэл хүрэлцэхгүй байна!");


    if (type === "PURCHASE") {
      setAction(["cart", item, box, type]);
    } else {
      setAction(["cart", item, null, type]);
    }

  };

  const onCart = ({ qty }) => {
    dispatch({
      type   : "cart.add",
      payload: {
        ...action[1],
        product_id: action[1].id,
        qty       : qty,
        box_no    : action[2],
        type      : action[3]
      }
    });

    setAction([]);
  };

  const columns = useHeader({
    s3host,
    history,
    carts,
    orders,
    onAddCart,
    onClick: (key, item) => {
      switch (key) {
        case "edit" : {
          history.push(`/part/edit/${item.id}`);
          break;
        }
        default:
      }
    },
  });

  const getCartCount = (type) => {
    return (type === "ORDER" ? orders : carts)?.reduce((accumulator, iterator) => {
      return accumulator + iterator.qty;
    }, 0);
  };

  const getTotalAmount = (type) => {
    return tugrug((type === "ORDER" ? orders : carts)?.reduce((accumulator, iterator) => {
      let price = is_sale_price ? iterator.sale_price : iterator.price;

      return accumulator + (iterator.qty * price);
    }, 0));
  };

  const onCancel = () => {
    setAction();
    setOrder(null);
  };

  const onConfirm = (type) => {
    // if (carts.length > 0)
    setAction(["checkout", order, type]);
  };

  const onPagination = (page) => {
    let value = {
      ...offset,
      page
    };

    setOffset(value);
    setFilters(state => ({
      ...state,
      offset: value
    }));
  };

  const onSubmit = async (data) => {
    try {
      await orderApi.purchase({
        type            : action && action[2] || "PURCHASE",
        parent_id       : order?.id,
        archive_id      : params.archive_id,
        customer_id     : data.customer_id,
        card_amount     : data.card_amount,
        cash_amount     : data.cash_amount,
        account_amount  : data.account_amount,
        discount_amount : data.discount_amount,
        surcharge_amount: data.surcharge_amount,
        coupon_code     : data.coupon_code,
        is_sale_price   : is_sale_price,
        order_status    : action && action[2] === "ORDER" ? "NEW" : data.order_status,
        order_date      : moment(data.order_date),
        note            : data.note,
        products        : (action[2] === "ORDER" ? orders : carts).map(cart => ({
          product_id : cart.product_id,
          quantity   : cart.qty,
          coupon_code: cart.coupon_code,
          // box_no     : cart.box_no
          ...(action[2] === "ORDER" ? {
            box_no: cart.box_no
          } : { box_no: cart.box_no.box_no })
        }))
      });

      dispatch({
        type   : "cart.clear",
        payload: {
          type: action[2]
        }
      });

      onCancel();
      loadDrafts();

      notify.success("Таны хүсэлт амжилттай!");

    } catch (error) {
      notify.error(error.payload);
      throw error;
    }
  };

  const onChange = async (type, value) => {
    if (!value) return onClear(type);

    let change = {
      [type]: value
    };

    switch (type) {
      case "maker_id": {
        onClear("market_id");
        break;
      }
      case "market_id": {
        onClear("body_id");
        break;
      }
      case "body_id": {
        break;
      }
      default:
    }

    let res = await partApi.query(type, value, { maker_id: filters.maker_id });

    setSelection(state => ({ ...state, ...res }));

    setFilters(state => ({ ...state, ...change }));
  };

  const onClear = (type) => {
    let change = {
      [type]: null
    };

    switch (type) {
      case "maker_id": {
        change = {
          ...change,
          market_id: null,
          model_id : null,
          body_id  : null,
          year     : null,
        };
        break;
      }
      case "market_id": {
        change = {
          ...change,
          model_id: null,
          body_id : null,
          year    : null,
        };
        break;
      }
      case "model_id": {
        change = {
          ...change,
          body_id: null,
          year   : null,
        };
        break;
      }
      case "body_id": {
        change = {
          ...change,
          year: null,
        };

        onChange("model_id", filters.model_id);
        break;
      }
      case "year": {
        change = {
          ...change,
          body_id: null
        };

        onChange("model_id", filters.model_d);
        break;
      }
      default:
    }

    setFilters(state => ({ ...state, ...change }));
  };

  const onDestroy = async () => {
    Modal.confirm({
      title     : "Баталгаажуулах",
      icon      : <ExclamationCircleOutlined />,
      content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
      okText    : "Устгах",
      cancelText: "Болих",
      className : "btn-custom-class",
      onOk      : async () => {
        try {
          await orderApi.remove(order?.id);

          onCancel();
          loadDrafts();
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const onCheckout = async (order) => {
    let res = await orderApi.get(order.id);

    dispatch({
      type   : "checkout.carts",
      payload: res.products?.map(p => ({
        qty       : p.quantity,
        product_id: p.id,
        name      : p.name,
        image     : p.image,
        price     : p.price,
        sale_price: p.sale_price,
      }))
    });

    setOrder(res);
    setAction(["calculation", "PURCHASE"]);
  };

  const onPosition = (pos) => {
    setPosition(pos);
    // setParts(payload?.parts?.filter(part => part.body_position_id === position.id));
  };

  const loadDrafts = async () => {
    let res = await orderApi.drafts();

    setDrafts(res);
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({
          ...state,
          maker_id : null,
          market_id: null,
          model_id : null,
          query
        }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    if (params.archive_id !== filters?.archive_id) {
      setFilters(state => ({
        ...state,
        archive_id: params.archive_id
      }));
    }
  }, [params]);

  const reload = React.useCallback(
    async (signal) => {
      try {
        setLoading(true);

        let res = await partApi.search({
          filter: filters,
          offset
        }, { signal });

        setResult(res);
        setLoading(false);

        if (res.type === "PART" && res.payload?.body_positions[0]) {
          let position = res.payload?.body_positions[0];

          setPosition(position);
          // setParts(res.payload?.parts?.filter(part => part.body_position_id === position.id));
        }
      } catch (err) {
        setResult({ type: "PRODUCT", payload: [] });
        setLoading(false);

        throw err;
      }
    },
    [filters, offset]
  );

  React.useEffect(() => {
    // console.log(action);
  }, [action]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);
    loadDrafts();

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader
        title={<span>{archives.find(a => a.id === params.archive_id)?.name || "Борлуулалт хийх"}</span>}
        extra={[
          ...drafts.map((order, index) => (
            <CartButton key={index} onClick={() => onCheckout(order)}>
              <Button type="default" shape="circle" icon={order.quantity} /> {tugrug(order.total_amount)}
            </CartButton>
          )),
          <CartButton key="order" onClick={() => setAction(["calculation", "ORDER"])}>
            <Button type="ghost" shape="circle" icon={getCartCount("ORDER") ||<MailOutlined />} /> {getTotalAmount("ORDER")}
          </CartButton>,
          <CartButton key="cart" onClick={() => setAction(["calculation", "PURCHASE"])}>
            <Button type="primary" shape="circle" icon={getCartCount("PURCHASE") || <ShoppingCartOutlined />} /> {getTotalAmount("PURCHASE")}
          </CartButton>
        ]}
      />

      <PageContent>
        <Row gutter={8} style={{ marginBottom: 20 }}>
          <Col xs={24} md={24} lg={4} style={{ marginBottom: 5 }}>
            <Input value={query} placeholder="OEM эсвэл PART" onChange={e => setQuery(e.target.value)} prefix={<SearchOutlined />} />
          </Col>
          <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
            <Select placeholder="Үйлдвэрлэгч" value={filters.maker_id} onChange={e => onChange("maker_id", e)} style={{ width: "100%" }} onClear={() => onClear("maker_id")} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
              {makers.map((maker, index) => <Option key={index} value={maker.id}>{maker.name}</Option>)}
            </Select>
          </Col>
          <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
            <Select placeholder="Маркет" value={filters.market_id} onChange={e => onChange("market_id", e)} style={{ width: "100%" }} disabled={markets.length === 0} onClear={() => onClear("market_id")} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
              {markets.map((market, index) => <Option key={index} value={market.id}>{market.name}</Option>)}
            </Select>
          </Col>
          <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
            <Select placeholder="Модел" value={filters.model_id} onChange={e => onChange("model_id", e)} style={{ width: "100%" }} disabled={models.length === 0} onClear={() => onClear("model_id")} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
              {models.map((model, index) => <Option key={index} value={model.id}>{model.code}</Option>)}
            </Select>
          </Col>
          <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
            <Select placeholder="Араам (body)" value={filters.body_id} onChange={e => onChange("body_id", e)} style={{ width: "100%" }} disabled={bodies.length === 0} onClear={() => onClear("body_id")} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
              {bodies.filter(b => {
                if (!filters.year) return true;

                let start = parseInt(b?.start_date?.split(".")[0], 10);
                let end = parseInt(b?.end_date?.split(".")[0] || `${new Date().getFullYear()}`, 10);
                let year = parseInt(filters.year, 10);

                if (start <= year && year <= end)
                  return true;

                return false;
              })?.sort((a, b) => a.code - b.code)?.map((body, index) => <Option key={index} value={body.id}>{body.code}</Option>)}
            </Select>
          </Col>
          <Col xs={12} lg={4} style={{ marginBottom: 5 }}>
            <Select placeholder="Огноо" value={filters.year} onChange={e => onChange("year", e)} style={{ width: "100%" }} disabled={years.length === 0} onClear={() => onClear("year")} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
              {years?.sort((a, b) => a - b).map((value, index) => <Option key={index} value={value}>{value}</Option>)}
            </Select>
          </Col>
        </Row>

        <Spin spinning={loading}>
          {type === "PART" ? (
            <PartContainer>
              <PageHeader title={`${payload?.maker?.name} ${payload?.model?.name}`} subTitle={payload?.market?.name} />
              <Row gutter={8}>
                <Col className="left schema" xs={24} xl={6}>
                  <label>Дугаар (body number):</label>
                  <h2>{payload?.code}</h2>
                  <label>Үйлдвэрлэсэн огноо:</label>
                  <h2>{payload?.start_date}-{payload?.end_date}</h2>

                  <div className="image">
                    <img src={`${s3host}/${position.image}`} width="100%" />
                  </div>
                </Col>
                <Col className="main" xs={24} xl={18}>
                  <ul className="tabs">
                    {payload?.body_positions?.map((pos, index) => {
                      return (
                        <li id={pos.code} key={index} className={`${position?.id === pos.id && "active"}`}>
                          <a className="tab" onClick={() => onPosition(pos)}>
                            <img src={`${s3host}/${pos.icon}`} alt="" />
                            {pos.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>

                  <MyTable
                    columns={columns}
                    ref={myTableRef}
                    pagination={false}
                    items={payload?.parts?.filter(part => part?.body_position_id === position?.id)?.sort((a, b) => a?.sort_of_position - b?.sort_of_position)}
                    rowKey={(record) => record.id} />
                </Col>
              </Row>
            </PartContainer>
          ) : (
            <>
              <MyTable
                columns={columns}
                ref={myTableRef}
                items={payload?.rows}
                pagination={false}
                rowKey={(record) => record.id} />

              {payload?.count > 0 && <Pagination style={{ marginTop: 20 }} current={offset.page} total={payload?.count} onChange={onPagination} />}
            </>
          )}

        </Spin>
      </PageContent>

      <Modal
        title={`${action?.[2] && action[2] === "ORDER" ? "Захиалга үүсгэх" : "Тооцоо хийх"}`} // </PageContainer>""
        visible={action && action[0] === "checkout"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Checkout onCancel={onCancel} onSubmit={onSubmit}
          action={action} type={action && action[2]} onDestroy={onDestroy} />
      </Modal>

      <Modal
        title={action && `Сэлбэгийн код: ${action[1]?.code}`}
        visible={action && action[0] === "cart"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Quantity onCancel={onCancel} onSubmit={onCart} item={action && action[1]} action={action} />
      </Modal>

      <Drawer
        title={archives.find(a => a.id === params.archive_id)?.name || "Борлуулалт хийх"}
        placement="right"
        visible={action && action[0] === "calculation"}
        width={300}
        closeIcon={<CloseCircleOutlined />}
        style={{ padding: 0 }}
        onClose={() => setAction([])}>
        <div className="emall-card">
          <Cart getCartCount={getCartCount} action={action} />

          {action && action[1] === "PURCHASE" ? (
            <Confirm className="primary" style={{ padding: 5 }}>
              <Button size="large" type="primary" onClick={() => onConfirm("PURCHASE")} block>
                <div className="name">
                  Тооцоо хийх
                  <div>Баталгаажуулах</div>
                </div>
                <div className="amount">{getTotalAmount()}</div>
              </Button>
            </Confirm>
          ) : (
            <Confirm className="primary" style={{ padding: 5 }}>
              <Button size="large" type="primary" onClick={() => onConfirm("ORDER")} block>
                <div className="name">
                  Захиалга хийх
                  <div>Баталгаажуулах</div>
                </div>
                <div className="amount">{getTotalAmount("ORDER")}</div>
              </Button>
            </Confirm>
          )}
        </div>
      </Drawer>
    </PageContainer>
  );
};

const Drawer = styled(DrawerAnt) `
  .ant-drawer-body {
    padding: 0 !important;
  }
  .emall-card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;
const CartButton = styled.div `
  display: inline-block;
  padding-right: 10px;
  background-color: #e8e8e8;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
`;

const useHeader = ({ s3host, carts, onAddCart, orders }) => {
  return [{
    title : "On scheme",
    width : 100,
    render: (record) => {
      if (!record.part)
        return record.sort_of_position;

      return (
        <>
          {record.sort_of_position} <Avatar src={record?.image || `${s3host}/${record?.part?.image}`} size={100} shape="square" />
        </>
      );
    }
  }, {
    title : "PART / Мэдээлэл",
    render: record => {
      if (!record?.part)
        return (
          <>
            <p style={{ margin: 0 }}>
              Not Available
              {record.additional && <div style={{ fontSize: 12 }}>{record.additional}</div>}
            </p>
          </>
        );

      const onCar = (note) => {
        let array = note?.split("on car:")[1]?.split("&&")[0]?.trim()?.split("package:");

        let car = (array && array[0] || "")?.trim();
        let pcs = (array && array[1] || "")?.trim();

        return (
          <>
            <Tag style={{ marginBottom: 10 }} color="#f50">On car: {car} </Tag>
            <Tag style={{ marginBottom: 10 }} color="#2db7f5">Package: {pcs} </Tag>
          </>
        );
      };

      return (
        <>
          <Tag color="black">{record?.part?.code}</Tag>
          <p>
            {record?.part?.name}
            {record?.additional && <div style={{ fontSize: 12 }}>{record?.additional}</div>}
          </p>
          <div>
            <Space>
              {record.note && onCar(record.note)}
              {/* <Tag style={{ marginBottom: 10 }}>Pcs: {record?.part?.pcs}, Weight: {record?.part?.weight} kg</Tag> */}
              <Tag style={{ marginBottom: 10 }} color={record?.part?.product?.quantity > 0 ? "blue" : "red"}>Нийт үлдэгдэл: {record?.part?.product?.quantity}</Tag>
              {record?.qties?.map(q => <Tag style={{ marginBottom: 10 }} color="blue">{q.name}: {q.qty}</Tag>)}
              {record?.part?.product?.order_quantity > 0 && <Tag style={{ marginBottom: 10 }} color="orange">Захиалга: {record?.part?.product?.order_quantity}</Tag>}
              {record?.part?.product?.shipment_quantity > 0 && <Tag style={{ marginBottom: 10 }} color="green">Татан авалт: {record?.part?.product?.shipment_quantity}</Tag>}
              {record?.part?.product?.produce_quantity > 0 && <Tag style={{ marginBottom: 10 }} color="lime">Татан авалт: {record?.part?.product?.produce_quantity}</Tag>}
            </Space>
          </div>
          {(() =>{

            let cartCount = carts?.filter(c => c.product_id === record?.part?.product?.id)?.reduce((acc, iter)=>{
              return parseFloat(acc) + parseFloat(iter.qty);
            }, 0);

            let orderCount = orders?.find(c => c.product_id === record?.part?.product?.id);

            if (cartCount || orderCount) {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Space>
                    {cartCount !== 0 && <Tag color="geekblue">Сагсанд: {cartCount}</Tag>}
                    {orderCount &&<Tag color="purple">Захиалхаар төлөвлөсөн: {orderCount.qty}</Tag>}
                  </Space>
                </div>
              );
            }
          })()}

          {(() => {

            return (
              <>
                {record?.boxes?.filter(b => b.qty > 0).map((box, index) => (
                  <Button type="primary" key={index} style={{ marginRight: 10 }}
                    onClick={() => onAddCart("PURCHASE", record?.part?.product, box)}
                    styles={{ marginBottom: 5, marginRight: 5 }}><PlusOutlined />
                    { `Хайрцаг (${box.box_no}): ${box.qty}`}
                  </Button>
                ))}

                <Button type="default" style={{ marginRight: 10 }} onClick={() => onAddCart("ORDER", record?.part?.product, null, true)} styles={{ marginBottom: 5, marginRight: 5 }}><PlusOutlined /> Захиалга</Button>
              </>
            );
          })()}
        </>
      );
    }
  }, {
    title : "Үнэ",
    width : 100,
    render: record => {
      if (!record?.part) return "-";

      return (
        <>
          {tugrug(record?.part?.product?.price)} <Tag>Б.Үнэ: {tugrug(record?.part?.product?.sale_price)}</Tag>
        </>
      );
    }
  }, {
    title : "Гарын авлага",
    width : 50,
    render: record => {
      if (!record?.part) return "-";

      return (
        <a href={record?.part?.manual_url} target="_blank">
          <Button className='manual' type="default" size="large"><ToolFilled size={44} /></Button>
        </a>
      );
    }
  }];
};

const PartContainer = styled.div`
  position: relative;
  background: #fff;
  .manual {
    &.ant-btn {
      height: 50px;
      font-size: 22px;
    }
  }
  .schema {
    width: 100%;
    .image {
      width: 100%;
    }
  }
  .main {
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        margin-bottom: 5px;
        &.active {
          a.tab {
            background: #f3f3f3;
            color: #feca0b;
            border-bottom: 4px solid #feca0b;
          }
        }
        a.tab {
          display: flex;
          flex-direction: column;
          padding: 10px;
          cursor: pointer;
          /* background: #333; */
          border: 1px solid #ddd;
          border-bottom: 4px solid #ddd;
          color: #333;
          img {
            width: 100px;
            margin: auto;
          }
          &:hover {
            background: #f3f3f3;
            color: #feca0b;
          }
        }
      }
    }
  }
`;
