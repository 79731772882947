import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { CloseOutlined, FileOutlined } from "@ant-design/icons";
import { Form, Input, Button, Tag, message } from "antd";
import { CustomTable, ProductItem } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { order as orderApi, calculation as calculationApi } from "../../apis";
import { tugrug } from "../../utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

const { TextArea } = Input;

const footerLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
};

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const params = useParams();
  const { isMobile, s3host } = useSelector(state => state.general);
  const [loading, setLoading] = React.useState(false);
  const [data, setFormData] = React.useState();
  const [datas, setData] = React.useState();
  const [filters] = React.useState({
    code          : "",
    type          : "",
    calculation_id: params.id,
  });

  const [filterssss] = React.useState({
    filter: {
      query         : "",
      calculation_id: params.id,
      is_download   : false
    },
    offset: {
      page : 1,
      limit: 100
    }
  });

  const orderStatus = (item) => {
    switch (item.order_status) {
      case "COMPLETED": {
        return <Tag color='green'>{"Амжилттай"}</Tag>;
      }
      case "NEW": {
        return <Tag>{"Үүсгэсэн"}</Tag>;
      }

      default:
        return <Tag>"-"</Tag>;
    }
  };

  const orderType = (item) => {
    switch (item.type) {
      case "REFUND": {
        return <Tag>{"Буцаалт"}</Tag>;
      }
      case "PURCHASE": {
        return <Tag>{"Борлуулалт"}</Tag>;
      }

      default:
        return <Tag>"-"</Tag>;
    }
  };

  const reload = React.useCallback(async (signal) => {
    let res = await calculationApi.get(params.id, { signal });

    setFormData(state => ({
      ...state,
      ...res,
      note: res.note || undefined
    }));

  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  const [dataFilters] = React.useState({
    filter: {
      query         : "",
      calculation_id: params.id,
    },
    offset: {
      page : 1,
      limit: 100
    }
  });

  const orderData = async () => {
    let result = await orderApi.lists({ ...dataFilters });
    setData([{ ...result.totals }]);
  };

  React.useEffect(() => {
    orderData();
  }, []);

  const downloadFile = async () => {
    setLoading(true);
    try {
      let res = await orderApi.lists({
        ...filterssss,
        is_download: true
      });
      setLoading(false);
      if (res.url)

        window.open(
          s3host + "/files/" + res.url,
          "_blank"
        );

    } catch (err) {
      // setLoading(false);
      message.error(err);
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Тооцооны дэлгэрэнгүй" extra={[
        <Button
          type="default"
          icon={<FileOutlined />}
          onClick={downloadFile}
          loading={loading}
        >Файл татах</Button>,
        <Button icon={isMobile && <CloseOutlined />} shape={isMobile && "circle"} type="default"
          onClick={() => history.push("/finance/report")}>{!isMobile && "Болих"}</Button>
      ]} />
      <PageContent>
        <SalesTable
          ref={myTableRef}
          filters={filters}
          loadData={orderApi.lists}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                <th className="ant-table-cell" rowSpan={2}>Тайлбар</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" colSpan={4}>Төлбөр</th>
                {filters.type === "ORDER" && (
                  <>
                    <th className="ant-table-cell" colSpan={3}>Хүргэлт</th>
                    <th className="ant-table-cell" colSpan={2}>Захиалга</th>
                  </>
                )}
                <th className="ant-table-cell" rowSpan={2}>Төрөл</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                {filters.type !== "ORDER" && <th className="ant-table-cell" rowSpan={2}>Огноо</th>}
              </tr>
              <tr>
                <th className="ant-table-cell">Нийт дүн</th>
                <th className="ant-table-cell">Картаар</th>
                <th className="ant-table-cell">Бэлнээр</th>
                <th className="ant-table-cell">Дансаар</th>
                {filters.type === "ORDER" && (
                  <>
                    <th className="ant-table-cell">Хаяг байршил</th>
                    <th className="ant-table-cell">Нэмэлт төлбөр</th>
                    <th className="ant-table-cell">Огноо</th>
                    <th className="ant-table-cell">Ажилтан</th>
                    <th className="ant-table-cell">Хувиарласан</th>
                  </>
                )}
              </tr>
            </thead>
          )} tbody={(row, index) => {
            let distributed = row.order_histories.find(history => history.order_status === "PENDING");
            let underpayment = (row) => {
              return row.pay_amount - row.paid_amount > 0 && <Tag style={{ padding: "0 2px", lineHeight: "15px" }} color={row.order_status === "COMPLETED" ? "green" : "red"}>{tugrug(row.pay_amount - row.paid_amount)} <span>{row.order_status === "COMPLETED" ? "төлөгдсөн" : "үлдэгдэл"}</span></Tag>;
            };

            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell" rowSpan={2}><Tag color={row?.type === "PURCHASE" ? "blue" : "warning"}>{row.code}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>{row?.note || "-"}</td>
                  <td className="ant-table-cell"><Tag>{row?.archive?.name || "-"}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>

                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.card_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.cash_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.account_amount)}</td>

                  {filters.type === "ORDER" ? (
                    <>
                      <td className="ant-table-cell" rowSpan={2}>{row.shipping_address || "-"}</td>
                      <td className="ant-table-cell" rowSpan={2}>{tugrug(row.shipping_additional_fee)}</td>
                      <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.shipping_date).format("YYYY.MM.DD HH:mm")}</td>
                      <td className="ant-table-cell">{`${row.order_user?.last_name || "-"} ${row.order_user?.first_name || "-"}`}</td>
                      <td className="ant-table-cell">{`${row.distribute_user?.last_name || "-"} ${row.distribute_user?.first_name || "-"}`}</td>
                      <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderType(row)}</td>
                      <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderStatus(row)}</td>
                    </>
                  ) : (
                    <>
                      <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderType(row)}</td>
                      <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderStatus(row)}</td>
                      <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.order_date).format("YYYY.MM.DD HH:mm")}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.user?.first_name || "-"} ${row?.user?.last_name || "-"}`}</a></td>
                  {filters.type === "ORDER" && (
                    <>
                      <td className="ant-table-cell" width={140}>{moment(row.order_date).format("YYYY.MM.DD HH:mm")}</td>
                      <td className="ant-table-cell" width={140}>{distributed?.order_status_date ? moment(distributed?.order_status_date).format("YYYY.MM.DD HH:mm") : "-"}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td></td>
                  <td className="ant-table-cell" colSpan={15}>
                    {row.products.map((item, index) => <ProductItem key={index} image={item?.product?.image} name={item?.product?.name} quantity={item.quantity} code={item?.product?.code} boxNo={item?.box_no}/>)}
                  </td>
                </tr>
              </tbody>
            );
          }} />

        <SalesTable
          loadData={() => {}}
          ref={myTableRef}
          filters={() => {}}
          rows={datas}
          pagination={false}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Захиалгын тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Барааны тоо</th>
                <th className="ant-table-cell" rowSpan={2}>Дансаар</th>
                <th className="ant-table-cell" rowSpan={2}>Картаар</th>
                <th className="ant-table-cell" rowSpan={2}>Бэлнээр</th>
                <th className="ant-table-cell" rowSpan={2}>Нийт</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2}>{row.order_quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.account_amount || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.card_amount || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.cash_amount || 0)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.total_amount || 0)}</td>
                </tr>
              </tbody>
            );
          }} />

      </PageContent>

      {(data && datas) && (
        <CalculationLines>
          {(() => {
            return (
              <Form layout="horizontal">
                <PageHeader title="Орлого & Зарлага" />
                <PageContent>
                  <CalculationFooter {...footerLayout}>
                    <Form.Item label="Борлуулалтын тоо">
                      <Input type="text" value={data.order_quantity} disabled />
                    </Form.Item>
                    <Form.Item label="Барааны тоо">
                      <Input type="text" value={datas[0].quantity} disabled />
                    </Form.Item>
                    <Form.Item label="Нийт дүн">
                      <Input type="text" value={tugrug(data.total_amount)} disabled />
                    </Form.Item>
                    <Form.Item label="Агуулах" required>
                      <Input type="text" value={data.archive?.name} disabled />
                    </Form.Item>
                    <Form.Item label="Ажилтан" required>
                      <Input type="text" value={`${data.staff_user?.last_name || "-"} ${data.staff_user?.first_name || "-"}`} disabled />
                    </Form.Item>
                    <Form.Item label="Тэмдэглэл" required>
                      <TextArea name="note" value={data.note} placeholder="Тэмдэглэл"/>
                    </Form.Item>
                  </CalculationFooter>
                </PageContent>
              </Form>
            );
          })()}
        </CalculationLines>
      )}
    </PageContainer>
  );
};
const CalculationLines = styled.div``;
const CalculationFooter = styled(Form)`
  margin-top: 20px;
  .ant-input[disabled] {
    background: #fff;
    color: #333;
  }
  .ant-form-item {
    margin-bottom: 10px!important;
  }
`;
const SalesTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;