import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { ExclamationCircleOutlined, SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import { Modal, Space, Radio, Button, DatePicker, Tag, Select, Input, Drawer } from "antd";
import { CustomTable, RowAction, DrawerRangePicker } from "../../components";
import { useHistory } from "react-router-dom";
import { calculation as calculationApi } from "../../apis";
import styled from "styled-components";
import moment from "moment";
import { tugrug } from "../../utils";

export default () => {
  const myTableRef = React.useRef();
  const history = useHistory();
  const [action, setAction] = React.useState();
  const [filters, setFilters] = React.useState({
    type: ""
  });

  const onAction = (key, item) => {
    switch (key) {
      case "start" : {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Тооцоо эхлүүлэхдээ итгэлтэй байна уу!",
          onOk   : async () => {
            try {
              let res = await calculationApi.start(item.id);
              history.push(`/calculation/${res.id}`);
            } catch (error) {
              console.log(error);
            }
          }
        });
        break;
      }
      case "detail": {
        history.push(`/calculation/${item?.new?.id}`);
        break;
      }
      case "cancel": {
        Modal.confirm({
          title  : "Баталгаажуулах",
          icon   : <ExclamationCircleOutlined />,
          content: "Тооцоог цуцлахдаа итгэлтэй байна уу?",
          onOk   : async () => {
            try {
              await calculationApi.cancel(item?.new?.id);

              myTableRef.current.reload();
            } catch (error) {
              console.log(error);
            }
          }
        });
        break;
      }
      default:
    }
  };

  const checkStart = (item) => {
    if (!item?.new)
      return "Тооцоо эхлүүлэх";

    return false;
  };

  const checkCancel = (item) => {
    if (item?.new?.calculation_status === "NEW" || item?.new?.calculation_status === "DRAFT")
      return "Цуцлах";

    return false;
  };

  const checkDetail = (item) => {
    if (item?.new?.calculation_status === "NEW" || item?.new?.calculation_status === "DRAFT")
      return "Дэлгэрэнгүй";

    return false;
  };

  const calculationStatus = (item) => {
    switch (item?.new?.calculation_status) {
      case "DRAFT":
      case "NEW":
        return <Tag color="red">Тооцоо хийгдэж байна</Tag>;
      default:
        return <Tag>Хүлээгдэж байна</Tag>;
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onClear = () => {
    setFilters({ code: "", type: "" });
    setAction();
  };

  const onCancel = () => {
    setAction();
  };

  return (
    <PageContainer>
      <PageHeader title="Тооцоо хийх" />
      {/* <PageFilter>
        <Space >
          <Input className="item" name="code" placeholder="Хайлт хийх" style={{ minWidth: 155 }} prefix={<SearchOutlined/>} onChange="" />
          {isMobile ? (<Select className="item" value={filters.type} onChange={e => setFilters({ ...filters, type: e })}>
            <Option value="">Бүгд</Option>
            <Option value="ARCHIVE">Агуулах</Option>
            <Option value="DELIVERY">Хүргэлт</Option>
          </Select>) : (<Radio.Group value={filters.type} onChange={e => setFilters({ ...filters, type: e.target.value })} style={{ minWidth: "218px" }} >
            <Radio.Button value="">Бүгд</Radio.Button>
            <Radio.Button value="ARCHIVE">Агуулах</Radio.Button>
            <Radio.Button value="DELIVERY">Хүргэлт</Radio.Button>
          </Radio.Group>)}
        </Space>
        <Space className="wrap margin-top">
          <div className="item gap" style={{ paddingLeft: "8px" }} >
            {isMobile ? (
              <Button className="item gap" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
            ) : (
              <RangePicker className="item" value={filters.start_date && filters.end_date && [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate} allowClear={false} />)}
          </div>
          <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
        </Space>
      </PageFilter> */}
      <PageContent>
        <CalculationTable
          ref={myTableRef}
          filters={filters}
          loadData={calculationApi.init}
          rowKey={(record) => record.id}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" colSpan={3}>Тооцоо хийсэн</th>
                <th className="ant-table-cell" rowSpan={2}>Нийт дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell" rowSpan={2}>Ажилтан</th>
                <th className="ant-table-cell" rowSpan={2}>Дүн</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell">{row?.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>{`${row.calculation?.finance_user?.last_name || "-"} ${row.calculation?.finance_user?.first_name || "-"}`}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.calculation ? tugrug(row.calculation?.total_amount) : "-"}</td>
                  <td className="ant-table-cell" rowSpan={2} width={140}>{row.calculation ? moment(row.calculation?.calculation_status_date).format("YYYY.MM.DD HH:mm") : "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}><Tag color="green">Одоо: {tugrug(row.totals?.total_amount)}</Tag></td>
                  <td className="ant-table-cell text-center" rowSpan={2} width={140}>{calculationStatus(row)}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        start : checkStart(row),
                        detail: checkDetail(row),
                        cancel: checkCancel(row),
                      }} onClick={key => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.staff_user?.first_name || "-"} ${row?.staff_user?.last_name || "-"}`}</a></td>
                </tr>
              </tbody>
            );
          }} />
      </PageContent>

      {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}
    </PageContainer>
  );
};
const CalculationTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;
const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 
  flex-direction: row;  
  @media only screen and (max-width: 533px) {
    flex-direction: column;  
    .ant-space-item {
      width: 100% !important;
    }
    .item {  
      width: 100% !important; 
    } 
    .margin-top {
      margin-top: 8px;
      padding-left: 0px !important; 
    }
    .gap { 
      padding: 0 !important;
    }  
  }
  @media only screen and (max-width: 411px) {  
    flex-direction: column;  
    .wrap {
      display: flex;
      flex-direction: column;
    }
    .ant-space-item {
      width: 100% !important;
    }
    .item {  
      width: 100% !important; 
    } 
    .margin-top {
      margin-top: 8px;
      padding-left: 0px !important; 
    }
    .gap { 
      padding: 0 !important;
    }  
  }  
`;
