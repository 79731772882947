import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import Swiper from "react-id-swiper";
import ReactImageMagnify from "react-image-magnify";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

export default ({ images = [] }) => {
  const [index, setIndex] = React.useState(0);
  const { isMobile } = useSelector(state => state.general);
  const onChange = (value) => {
    if (index + value >= images.length || index + value < 0)
      return 0;

    setIndex(index + value);
  };

  if (!images.length)
    return (
      <LoadingSwiper>
        <div className="image">
          <Skeleton height={400} />
        </div>
        <div className="thumbs">
          {[1, 2, 3, 4, 5].map(key => (<Skeleton className="img" key={key} height={60} width={60} />))}
        </div>
      </LoadingSwiper>
    );

  return (
    <Product>
      <div className="picture">
        <ReactImageMagnify
          {...{
            enlargedImageContainerClassName: "enlarged-image-container",
            enlargedImageClassName         : "enlarged-image",
            smallImage                     : {
              isFluidWidth: true,
              src         : images[index] && images[index].url,
              sizes       : "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px"
            },
            largeImage: {
              src   : images[index] && images[index].url,
              width : 2500,
              height: 2500,
              sizes : "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px"
            }
          }}
        />
      </div>
      <div className="thumbs">
        <Button className="swiper-btn-prev" onClick={() => onChange(-1)} icon={<LeftOutlined />} />
        <div className="swiper-thumbs">

          <Swiper
            slidesPerView="false"
            slideToClickedSlide
            touchRatio={0.2}
            centeredSlides
            navigation={false}>
            {images.map((image, i) => (
              <div key={i} className={`image ${i === index && "active"}`} onClick={() => setIndex(i)}>
                <img width="100%" src={image.url} />
              </div>
            ))}
          </Swiper>
        </div>
        <Button className="swiper-btn-next" onClick={() => onChange(1)} icon={<RightOutlined />} />
      </div>
    </Product>
  );
};
const LoadingSwiper = styled.div`
  padding: 15px;
  .image {
    margin-bottom: 15px;
  }
  .thumbs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .img {
      margin-right: 5px;
    }
  }
`;
const Product = styled.div`
  .swiper-pagination {
    .swiper-pagination-bullet-active {
      background: red;
    }
  }
  .picture {
    // max-height: 600px;
    // height: 650px;
    // border-right: 1px solid #eee;
    .showcase {
      width: auto !important;
      margin: auto;
      // height: 480px !important;
      // width: 480px !important;
      // border: 1px solid rgba(79, 63, 104, 0.3);
    }
    .enlarged-image-container {
      z-index: 2;
      background-color: #fff;
      margin-left: 30px!important;
      .enlarged-image {
        // height: auto !important;
      }
    }
  }
  .thumbs {
    display: flex;
    align-items: center;
    margin: 20px 15px;
    .swiper-btn-prev, .swiper-btn-next {
      line-height: 0!important;
    }
    .swiper-thumbs {
      display: flex;
      flex: 1 1 calc(100% - 80px);
      flex-flow: row nowrap;
      overflow: hidden;
      .image {
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        flex: 0 0 calc(18% - 8px);
        padding: 4px;
        cursor: pointer;
        &.swiper-slide-active {
          border-color: $goyhar;
        }
        &.active {
          border: 1px solid #4a4b57;
        }
      }
      .image:not(:last-of-type) {
        margin-right: 4px;
      }
      .swiper-wrapper {
        margin: 0 3rem;
        width: auto;
      }
    }
  }
`;