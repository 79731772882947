import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import dragImage from './drag-reorder.png';
import styles from './style.module.scss';

const DragHandle = () => (
  <div className={styles.handle}>
    <img src={dragImage} width='20' alt='' />
  </div>
);

export default SortableHandle(DragHandle);
