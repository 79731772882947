import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { order as orderApi, calculation as calculationApi } from "../../apis";
import { Button, DatePicker, Space, Input, Select, Tag, Modal, Form as AntForm } from "antd";
import { CalendarOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { CustomTable, RowAction, DrawerRangePicker, ProductItem } from "../../components";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { tugrug, formItemLayout, tailFormItemLayout } from "../../utils";
import { Form, FormItem, Input as FormikInput } from "formik-antd";
import moment from "moment";
import styled from "styled-components";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = FormikInput;

const FormSchema = (type) => () => Yup.object().shape({
  // note: Yup.string().required("Заавал бөглөнө!"),
  ...(type === "canceled" && {})
});

export default () => {
  const myTableRef = React.useRef(null);
  const history = useHistory();
  const [action, setAction] = React.useState();
  const [actionModal, setActionModal] = React.useState();
  const { archives, staffs, isMobile } = useSelector(state => state.general);
  const { user } = useSelector(state => state.auth);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query     : "",
    type      : "",
    archive_id: undefined
  });
  const [totals, setTotals] = React.useState({
    count         : 0,
    total_amount  : 0,
    card_amount   : 0,
    cash_amount   : 0,
    account_amount: 0,
    calculation   : true
  });

  const reload = () => {
    myTableRef.current.reload();
    setActionModal(false);
  };

  const onAction = async (type, item) => {
    switch (type) {
      case "detail": {
        history.push(`/pos/detail/${item.id}`);
        break;
      }
      case "edit": {
        history.push(`/pos/edit/${item.id}`);
        break;
      }
      case "cancel": {
        setActionModal(["canceled", item]);
        break;
      }
      case "canceled": {
        await orderApi.cancel(actionModal[1].id, item);
        setActionModal();
        reload();
        break;
      }

      default:
    }
  };

  const onChangeRangeDate = (values) => {
    if (values && values[0] && values[1]) {
      setFilters({
        ...filters,
        start_date: moment(new Date(values[0])).startOf("day").toDate(),
        end_date  : moment(new Date(values[1])).endOf("day").toDate()
      });
    }
  };

  const onCancel = () => {
    setAction();
  };

  const onClear = () => {
    setQuery(null);
    setFilters({ query: "", type: "", archive_id: undefined });
    setAction();
  };

  const orderType = (value) => {
    if (value === "REFUND") {
      return <Tag>Буцаалт</Tag>;
    }
    if (value === "PURCHASE") {
      return <Tag>Борлуулалт</Tag>;
    }
  };

  const orderStatus = (value) => {
    if (value === "NEW") {
      return <Tag>Үүсгэсэн</Tag>;
    }
    if (value === "COMPLETED") {
      return <Tag color="green">Амжилттай</Tag>;
    }
  };

  const onCancelModal = () => {
    setActionModal(false);
  };

  const checkCancel = (item) => {
    if (item.order_status === "CANCELED")
      return false;

    return "Цуцлах";
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters({
          ...filters,
          query: query
        });
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="Борлуулалт" />
      <PageFilter>
        <Space className="wrap item">
          <Space className="item" >
            <Input className="item" name="code" placeholder="Дугаараар хайх ..."
              style={{ minWidth: 180 }} prefix={<SearchOutlined />}
              onChange={e => setQuery(e.target.value)} />
          </Space>
          <Space className="item">
            {user.role === "ADMIN" && (
              <Select className="item" value={filters.archive_id} placeholder="Агуулах" style={{ width: 200 }} onChange={value => setFilters({ ...filters, archive_id: value })} allowClear>
                {archives.map((item, index) => {
                  return <Option key={index} value={item.id}>{item.name}</Option>;
                })}
              </Select>
            )}
            {user.role === "ADMIN" && (
              <Select className="item" placeholder="Ажилтан" style={{ minWidth: 130 }} onChange={value => setFilters({ ...filters, staff_id: value })} allowClear>
                {staffs?.map((item, index) => {
                  return <Option className="item" key={index} value={item.id}>{`${item.last_name} ${item.first_name}`}</Option>;
                })}
              </Select>
            )}
          </Space>
        </Space>
        <Space className="margin-top" style={{ paddingLeft: "10px" }}>
          {isMobile ? (
            <Button className="item" onClick={() => setAction(["daterange"])} icon={<CalendarOutlined />} >Хайх</Button>
          ) : (
            <RangePicker className="item" value={filters.start_date && filters.end_date &&
            [moment(filters.start_date), moment(filters.end_date)]} onChange={onChangeRangeDate}
            allowClear={false} />)}
          <Button className="item" type="default" onClick={() => onClear()}>Цэвэрлэх</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <SalesTable
          ref={myTableRef}
          filters={filters}
          loadData={orderApi.list("sales")}
          rowKey={(record) => record.id}
          onResult={(data) => {
            setTotals({
              ...data,
              calculation: !!data.calculation
            });
          }}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" rowSpan={2}>№</th>
                <th className="ant-table-cell" rowSpan={2}>Дугаар</th>
                <th className="ant-table-cell" rowSpan={2}>Борлуулалт</th>
                <th className="ant-table-cell" rowSpan={2}>Агуулах</th>
                <th className="ant-table-cell" rowSpan={2}>Тоо ширхэг</th>
                <th className="ant-table-cell" colSpan={4}>Төлбөр</th>
                <th className="ant-table-cell" rowSpan={2}>Төрөл</th>
                <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
              </tr>
              <tr>
                <th className="ant-table-cell">Нийт дүн</th>
                <th className="ant-table-cell">Картаар</th>
                <th className="ant-table-cell">Бэлнээр</th>
                <th className="ant-table-cell">Дансаар</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            return (
              <tbody key={index} className="ant-table-tbody">
                <tr>
                  <td className="ant-table-cell" rowSpan={2} width={10}>{row.rowIndex}</td>
                  <td className="ant-table-cell" rowSpan={2}><Tag color={row?.type === "PURCHASE" ? "blue" : "warning"}>{row.code}</Tag></td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <a>{row?.customer?.type === "CITIZEN" ? `${row?.customer?.last_name || "-"} ${row?.customer?.first_name || "-"}` : `${row?.customer?.name || "Зочин"}`}</a>
                    <p>{row?.note || "-"}</p>
                  </td>
                  <td className="ant-table-cell">{row?.archive?.name || "-"}</td>
                  <td className="ant-table-cell" rowSpan={2}>{row.quantity || 0}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <div>{tugrug(row.total_amount)}</div>
                    {row.is_sale_price && <Tag color="blue" style={{ marginBottom: 5 }}>Бөөний үнээр тооцов</Tag>}
                    {row.discount_amount > 0 && <Tag color="red" style={{ marginBottom: 5 }}>-{tugrug(row.discount_amount)} Хямдрал</Tag>}
                    {row.surcharge_amount > 0 && <Tag color="yellow">{tugrug(row.surcharge_amount)} Нэмэлт төлбөр</Tag>}
                  </td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.card_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.cash_amount)}</td>
                  <td className="ant-table-cell" rowSpan={2}>{tugrug(row.account_amount)}</td>
                  <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderType(row.type)}</td>
                  <td className="ant-table-cell text-center" rowSpan={2} width={100}>{orderStatus(row.order_status)}</td>
                  <td className="ant-table-cell" rowSpan={2} width={140}>{moment(row.order_date).format("YYYY.MM.DD HH:mm")}</td>
                  <td className="ant-table-cell" rowSpan={2}>
                    <RowAction
                      icon={row?.request?.request_status === "NEW" && <ExclamationCircleOutlined style={{ color: "red" }} />}
                      actions={{
                        edit  : row?.type !== "REFUND" && "Засварлах",
                        detail: "Дэлгэрэнгүй",
                        cancel: row?.type !== "REFUND" && checkCancel(row)
                      }} onClick={(key) => onAction(key, row)} />
                  </td>
                </tr>
                <tr>
                  <td className="ant-table-cell"><a>{`${row?.user?.first_name || "-"} ${row?.user?.last_name || "-"}`}</a></td>
                </tr>
                <tr>
                  <td></td>
                  <td className="ant-table-cell" colSpan={15}>
                    {row.products.map((item, index) => <ProductItem key={index} image={item?.product?.image} name={item?.product?.name} quantity={item.quantity} code={item?.product?.code} boxNo={item.box_no} />)}
                  </td>
                </tr>
              </tbody>
            );
          }} />
      </PageContent>

      {action && action[0] === "daterange" && <DrawerRangePicker filters={filters} onChange={onChangeRangeDate} onCancel={onCancel} />}

      <Modal
        title={"Агуулах сонгох"}
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form {...formItemLayout}>
          <Form.Item label="Агуулах" required>
            <Select placeholder="Агуулах" onChange={value => history.push(`/archive/inout/${action[1]}/${value}`)} allowClear>
              {archives?.map((item, index) => {
                return <Option key={index} value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary">Болсон</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Баталгаажуулах"
        visible={actionModal && actionModal[0] === "canceled"}
        onCancel={onCancelModal}
        destroyOnClose
        footer={false}>
        <Formik
          enableReinitialize
          initialValues={{
            note: undefined
          }}
          validationSchema={FormSchema("canceled")}
          onSubmit={(data) => onAction("canceled", data)}>
          {({ isSubmitting, values, errors }) => {
            return (
              <Form {...formItemLayout}>
                <Canceled>
                  <div className="alert">
                    <ExclamationCircleOutlined /> Та борлуулалтыг цуцлахдаа итгэлтэй байна уу?
                  </div>
                </Canceled>
                {/* <FormItem name="note" value={values.node} label="Нэмэлт мэдээлэл" required>
                  <TextArea name="note" placeholder="Нэмэлт мэдээлэл" />
                </FormItem> */}
                <AntForm.Item {...tailFormItemLayout}>
                  <Button className="button" style={{ marginRight: 10 }} loading={isSubmitting} htmlType="submit" type="primary">Хадгалах</Button>
                  <Button className="button" type="default" onClick={onCancelModal}>Болих</Button>
                </AntForm.Item>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </PageContainer>
  );
};
const SalesTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 4px 8px;
  }
  .text-center {
    text-align: center;
  }
`;

const PageFilter = styled.div` 
  display: flex;
  padding: 20px 0; 
  justify-content: space-between; 
  flex-direction: row;  

  @media only screen and (max-width: 735px) {  
    flex-direction: column;  
    .wrap {
      display: flex;
      flex-direction: column;
    }
    .ant-space-item {
      width: 100% !important;
    }
    .item {  
      width: 100% !important; 
    } 
  }
  @media only screen and (max-width: 472px) {   
    .ant-space-align-center {
      align-items: center; 
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
    } 
    
  }
  @media only screen and (max-width: 1292px) {  
    flex-direction: column;   
    .margin-top {
      margin-top: 8px;
      padding-left: 0px !important; 
    }  
  } 
`;

const Canceled = styled.div`
.alert {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    svg {
      display: flex;
      font-size: 22px;
      color: #faad14;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
`;