import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";

const { TextArea } = Input;

const FormSchema = () => Yup.object().shape({
  name        : Yup.string().required("Заавал бөглөнө!"),
  staff_name  : Yup.string().required("Заавал бөглөнө!"),
  register_no : Yup.string().required("Заавал бөглөнө!"),
  phone       : Yup.string().required("Заавал бөглөнө!"),
  phone_second: Yup.string().optional("Заавал бөглөнө!").nullable(),
  address     : Yup.string().required("Заавал бөглөнө!")
});

export default ({ action, onSubmit, onCancel }) => {
  const [data] = React.useState({
    name        : undefined,
    staff_name  : undefined,
    register_no : undefined,
    phone       : undefined,
    phone_second: undefined,
    address     : undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form {...formItemLayout}>
          <FormItem name="register_no" label="Регистрийн дугаар" required>
            <Input name="register_no" placeholder="Регистрийн дугаар" />
          </FormItem>
          <FormItem name="name" label="Нэр" required>
            <Input name="name" placeholder="Нэр" />
          </FormItem>
          <FormItem name="phone" label="Утас 1" required>
            <Input name="phone" placeholder="Утас 1" />
          </FormItem>
          <FormItem name="phone_second" label="Утас 2">
            <Input name="phone_second" placeholder="Утас 2" />
          </FormItem>
          <FormItem name="address" label="Хаяг" required>
            <TextArea name="address" placeholder="Хаяг" />
          </FormItem>
          <FormItem name="staff_name" label="Харилцах ажилтан" required>
            <Input name="staff_name" placeholder="Харилцах ажилтан" />
          </FormItem>
          <AntForm.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};