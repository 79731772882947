import React from "react";
import { Form, FormItem, Input, Select, Checkbox } from "formik-antd";
import { Button, Form as AntForm, Row, Col } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import { CloseOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/useFetch";
import { ImageUpload } from "../../components";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

const FormSchema = () => Yup.object({
  is_active   : Yup.boolean().required(),
  email       : Yup.string().email("Имэйл хаяг!").required("Заавал бөглөх!"),
  password    : Yup.string().optional("Заавал бөглөх!"),
  last_name   : Yup.string().required("Заавал бөглөх!"),
  first_name  : Yup.string().required("Заавал бөглөх!"),
  address     : Yup.string().required("Заавал бөглөх!"),
  phone       : Yup.string().required("Заавал бөглөх!"),
  phone_second: Yup.string().optional().nullable(),
  roles       : Yup.array().of(Yup.string()).min(1, "Заавал бөглөх!").required()
});

export default ({ action, onSubmit, onCancel }) => {
  const [password, setPassword] = React.useState(false);
  const { roles } = useSelector(state => state.general);
  const [data] = React.useState({
    is_active   : true,
    email       : undefined,
    password    : undefined,
    last_name   : undefined,
    first_name  : undefined,
    address     : undefined,
    phone       : undefined,
    phone_second: undefined,
    avatar      : undefined,
    roles       : [],
    ...(action && action[0] === "update" ? action[1]: {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {

        return (
          <Form {...formItemLayout}>
            {action && action[0] === "update" && (
              <FormItem name="username" label="Нэвтрэх нэр" required>
                <Input name="username" placeholder="Нэвтрэх нэр" disabled="disable" />
              </FormItem>
            )}
            <FormItem name="email" label="Цахим хаяг" required>
              <Input name="email" placeholder="Цахим хаяг" />
            </FormItem>
            <FormItem name="last_name" label="Овог" required>
              <Input name="last_name" placeholder="Овог" />
            </FormItem>
            <FormItem name="first_name" label="Нэр" required>
              <Input name="first_name" placeholder="Нэр"/>
            </FormItem>
            <FormItem name="address" label="Хаяг" required>
              <TextArea name="address" placeholder="Хаяг"/>
            </FormItem>
            <FormItem name="phone" label="Утасны дугаар 1" required>
              <Input name="phone" placeholder="Утасны дугаар 1"/>
            </FormItem>
            <FormItem name="phone_second" label="Утасны дугаар 2">
              <Input name="phone_second" placeholder="Утасны дугаар 2"/>
            </FormItem>
            <FormItem name="roles" label="Хандах эрх" required>
              <Select mode="tags" name="roles" placeholder="Хандах эрх" required>
                {roles.map((code, index) => {
                  return <Option key={index} value={code}>{code}</Option>;
                })}
              </Select>
            </FormItem>
            <FormItem name="is_active" label="Идэвхтэй эсэх" required>
              <Checkbox name="is_active" />
            </FormItem>
            <FormItem label="Зураг" name="avatar">
              <ImageUpload action="/api/general/upload" name="avatar" />
            </FormItem>
            {password && (
              <Form.Item label="Нууц үг" name="password">
                <Row gutter={12}>
                  <Col>
                    <Input name="password" placeholder="Нууц үг" />
                  </Col>
                  <Col>
                    <Button type="default" shape="circle" onClick={() => setPassword(!password)}><CloseOutlined /></Button>
                  </Col>
                </Row>
              </Form.Item>
            )}
            {!password && (
              <AntForm.Item {...tailFormItemLayout}>
                <Button type="default" onClick={() => setPassword(!password)} block>Нууц үг солих</Button>
              </AntForm.Item>
            )}
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
              <Button className="button" type="default" onClick={onCancel}>Болих</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};